// stylelint-disable declaration-no-important

// Common values

// Sass list not in variables since it's not intended for customization.
$positions: static, relative, absolute, fixed, sticky;

@each $position in $positions {
  .position-#{$position} { position: $position !important; }
}

// Shorthand

.fixed {
  &-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $zindex-fixed;
  }

  &-action {
    position: fixed;
    z-index: 2;
    margin-top: -42px;
    font-size: 13px;
  }
}

.abs {
  &-action {
    position: absolute;
    z-index: 10;
    top: 0px;
    left: 0px;
    
    font-size: 0;

    background-color: #fff;
    border-left: 1px solid #eaeaea;
    box-shadow: 10px 0px 10px 0px #fff;

    &.pim {
      top: 5px;
      border-left: 0;
      font-size: 12px;
    }

    .rtl & {
      left: auto;

      right: 6px;
      border-left-width: 0;
    }
  }
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-fixed;
}

.sticky-top {
  @supports (position: sticky) {
    position: sticky;
    top: 0;
    z-index: $zindex-sticky;
  }
}
