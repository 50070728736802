.icon {
  &-actions {
    .product-item-card & {
      display: flex;

      position: absolute;

      top: 5px;
      left: 5px;

      z-index: 1;
    }

    & > * {
      & + * {
        .product-item-card & {
          margin-left: 3px;
        }
      }
    }
  }

  &-xs {
    &.MuiFab-root {
      width: 24px;
      height: 24px;

      min-height: 10px;
      min-width: 10px;
    }
  }

  &-abs {
    .show-more-popover & {
      position: absolute;
    }

    &.top {
      top: 0;
    }

    &.right { 
      right: 0;
    }

    &.left { 
      left: 0;
    }

    &.bottom { 
      bottom: 0;
    }

    .product-attr-list & {
      position: absolute;
      left: -21px;
      bottom: 8px;
      font-size: 16px;
    }
  }
  .product-item-card-list & {
    position: relative;
    padding: 8px;
    font-size: 12px;
    color: #fff;

    left: 0px;
    top: 0px;

    background-color: $primary;
  }

  &-more {
    position: absolute;
    top: 15px;
    right: 15px;
    color: #5D92F4;
    z-index: 12;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 100%;
    padding: 5px;
    margin-top: -5px;

    .product-grid-item & {
      top: 10px;
      right: 5px;

      padding: 10px;
      cursor: pointer;
    }
  }

  &-settings {
    .product-item-card & {
      position: absolute;
      padding: 0;
      overflow: visible;
      font-size: 14px;
      color: $primary;
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 100%;
      z-index: 10;
    }
  }

  &-pin {
    .product-item-card-list & {
      position: absolute;
      padding: 12px;
      overflow: visible;
      font-size: 18px;
      top: 15px;
      left: 15px;
      color: $primary;
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 100%;
      z-index: 10;
    }
    .product-item-card & {
      padding: 0;
      z-index: 10;
    }
  
    &.icon-xs {
      .product-item-card-list & {
        margin: 0;
        font-size: 11px;
        width: 20px;
        height: 20px;
        background-color: #e5eefe;
        color:  $primary;
        border-radius: 4px;
  
        left: 50px;
      }

      .edit-icon-wrapper & {
        margin-left: 3px;

        left: 0;
        top: 0;

        position: relative;

        .rtl & {
          margin-left: 0;
          margin-right: 3px;
        }
      }
    }
  
    .product-item-card-list & {
      padding: 8px;
      font-size: 12px;
      color: #fff;
  
      left: 5px;
      top: 5px;
  
      background-color: $primary;
    }

    &.red {
      color: #dc3545 !important;
      background-color: #fbe7e9 !important;
    }
  }
}

.icon {
  &-premium {
    margin: 0;
    font-size: 11px;
    background-color: #e5eefe;
    color:  $primary;
    border-radius: 4px !important;

    &.bw {
      opacity: 0.4;
    }
  
    .product-item-card-list &,
    .product-item-card & {
      color: $primary;
      background-color: #e5eefe;
    }

    &.no-bg {
      background-color: transparent;
      margin: auto;
    }
  }

  &-xs {
    width: 20px;
    height: 20px;

    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.edit-icon-wrapper {
  z-index: 1;

  & > * {
    & + * {
      margin-left: 3px;

      .rtl & {
        margin-left: 0;
        margin-right: 3px;
      }
    } 
  }
}

.position-info {
  padding: 0 7px;
  width: auto;

  &.list {
    margin-right: 1px;
  }

  &.with-margin {
    margin-right: 3px;
  }
}

.lock-icon {
  z-index: 1;

  .MuiButton-label {
    white-space: nowrap;
  }

  .edit-icon-wrapper & {
    background-color: #fff;
  }
}

.ti {
  &-comment {
    &-alt {
      z-index: 1;
    }
  }
  &-files {
    font-size: 12px;
  }
}