.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;

  &:focus {
    border-color: $blue;
  }

  &-content {
    padding: 15px 0;

    ul {
      span {
        color: $gray-600;
      }
    }

    .ti-close {
      font-size: 14px;

      &.red {
        color: #dc3545;
      }
    }
  }

  .import-submit {
    .ti-check {
      font-size: 14px;

      &.green {
        color: #28a745;
      }
    }
  }

  &-selected {
    &-item {
      background-color: $gray-100;
      padding: 10px;
      margin: 5px 0;
    }
  }

  &.disabled {
    border-color: transparent;
  }
}

.step-loader {
  .progress-item {
    span {
      color: #adb5bd;

      &.text-diff {
        color: #6c757d;
      }
    }
  }
}