.MuiFormLabel-root,
.MuiFormHelperText-root {
  .has-errors & {
    color: $danger;
  }

  .category-filter-wrapper & {
    white-space: normal;
  }
  
  .text-right & {
    text-align: right;
  }
}

.text {
  &-error {
    font-size: 0.75rem;
  }
}

.MuiFormHelperText-root {
  .drag-list-item &,
  .form-group &,
  .has-errors & {
    color: $danger;
  }
}

.MuiExpansionPanel {
  &-root {
    &.collapse-secondary {
      &:before {
        content: none;
      }

      box-shadow: 0 0 0 0 transparent;
  
      margin: 17px -5px 0 !important;

      &-margin {
        &-auto {
          margin: 0 0 15px !important;
        }
      }
    }
  }
}

.MuiButtonBase-root {
  .collapse-secondary & {
    padding: 0;
  }
}
.MuiExpansionPanelDetails-root {
  .collapse-secondary & {
    padding: 0 5px;
  }
}

.MuiExpansionPanelSummary {
  &-root {
    & + .MuiExpansionPanelSummary-expanded {
      min-height: 48px;
    }

    .collapse-secondary & {
      min-height: 0;
      background-color: #505050;
    }

    &.Mui-expanded {
      .collapse-secondary & {
        min-height: 0;
      }
    }
  }

  &-content {
    .collapse-secondary & {
      font-size: 12px;
      margin-bottom: 0px;
      margin-top: 0;
      letter-spacing: 1px;
      font-weight: 500;
      color: #ffffff;
      text-transform: uppercase;
      padding: 3px 10px;
    }

    &.Mui-expanded {
      .collapse-secondary & {
        margin: 0;
      }
    }
  }

  &-expandIcon {
    .collapse-secondary & {
      right: auto;
      left: 0;
      color: #fff;
      border-radius: 0;
      padding: 2px 5px;
    }

    &.MuiExpansionPanelSummary-expanded {
      .collapse-secondary & {
        transform: translateY(-70%) rotate(180deg);
      }
    }

    .MuiSvgIcon-root {
      .collapse-secondary & {
        font-size: 20px;
      }
    }
  }
}
