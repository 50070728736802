// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.buttons-row {
  display: flex;
  & > * {
    margin: 0 3px !important;

    &:first-child {
      margin-left: 0 !important;
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }
}


.btn {
  display: inline-block;
  font-weight: $btn-font-weight;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: $btn-border-width solid transparent;
  @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);

  &-abs {
    position: absolute !important;
    
    &.btn {
      &-close {
        top: 5px;
        right: 5px;
        z-index: 1;
        padding: 10px;
      }
    }
  }

  &-close {
    .show-more-popover & {
      background-color: rgba(#fff, 0.7);
    }

    &:hover {
      will-change: opacity, background-color;

      opacity: 1;

      .show-more-popover & {
        background-color: rgba(#fff, 0.9);
      }
    }
  }

  &-s {
    padding: 0px 5px !important;
    font-size: 10px !important;
    min-height: 25px !important;
  }

  &-search {
    &.MuiIconButton-root {
      padding: 8px;
      margin-left: 5px;
    }
  }

  &-icon {
    padding: 0 !important;

    .product-header & {
      margin-right: -10px;
    }
  
    &-sm {
      width: 35px;
      height: 35px;
    }
  }

  &-w {
    &-md {
      min-width: 90px !important;
      font-size: 14px !important;

      box-shadow: 0 0 0 0 transparent !important;
    }
  }

  &-primary {
    &.btn-outlined {
      background: none;
      background-color: rgba($primary, 0) !important;
      border-color: rgba($primary, 0.4) !important;
      transition: all 0.2s ease;
      
      .product-dialog & {
        border-color: rgba($primary, 0.4) !important;
      }
      
      &.text-white {
        span {
          color: $primary !important;
        }
      }
    }

    &:hover {
      &.btn-outlined {
        background: none;
        background-color: rgba($primary, 0.1);
        border-color: rgba($primary, 0.8);
        
        &.text-white {
          span {
            color: $primary !important;
          }
        }
      }
    }
  }

  &-xs {
    min-width: 20px;
    padding: 0 !important;

    .edit-icon-wrapper & {
      box-shadow: 0px 0px 0px 2px #fff;
    }

    &.extra {
      padding: 0px 12px !important;
      font-size: 10px !important;
      min-height: 20px !important;
      min-width: 30px !important;
      background-color: #e5eefe !important;
      transition: all .2s ease;

      &:hover {
        cursor: pointer;
        background-color: #f3f8ff !important;
      }
    }
  }

  &-w {
    &-lg {
      max-width: 70%; 

      .MuiButton-label {
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
      }
    }
  }

  &-link {
    &.focus,
    &:focus {
      border-color: inherit !important;
    }

    &.Mui-disabled {
      opacity: 0.6 !important;
    }
  }

  &:empty {
    span & {
      display: none;
    }
  }

  // Share hover and focus styles
  @include hover-focus {
    text-decoration: none;
  }

  &-top {
    &-right {
      top: 0;
      right: 0;
      position: absolute !important;
    }
  }

  &-clear {
    background-color: transparent !important;
    box-shadow: 0 0 0 0 transparent !important;
    padding: 10px !important;
    min-width: 0px !important;

    [class^='ti-'],
    [class*='ti-'] {
      color: $default;
    }

    &:hover {
      [class^='ti-'],
      [class*='ti-'] {
        color: $primary;
      }
    }
  }

  &-ico {
    &-sm {
      padding: 6px !important;
      font-size: 9px !important;
    }
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }

  // Opinionated: add "hand" cursor to non-disabled .btn elements
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-image: none;
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }

  &-mui {
    &-sm {
      font-size: 14px !important;
      height: 38px !important;
      width: 38px !important;
      padding: 0 !important;
      align-items: center;
      justify-content: center;
    }

    &-badge {
      margin: -2px -12px 0px 4px !important;

      .ti-close {
        color: #fff !important;
      }
    }

    &-xs {
      padding: 5px !important;
      font-size: 10px !important;
      border-radius: 100% !important;
    }
  }
}

.MuiButton-textPrimary {
  color: $primary !important;
}

.MuiIconButton-label {
  i {
    &:before {
      transition: color 0.2s ease;
    }
  }
}

.MuiIconButton-root {
  &:hover {
    &.text-danger {
      background-color: rgba($danger, 0.1);
      
      .MuiIconButton-label {
        i {
          &:before {
            color: $danger !important;
          }
        }
      }
    }
  
  }
  
  .search-input-lg & {
    padding: 6px;
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.MuiButtonBase-root {
  .popper-actions & {
    background-color: #f4f4f4;
  }

  .card-collapse-btn & {
    height: 30px;
    padding: 0;
    width: 30px;
    position: relative;
    left: -5px;
  }

  .MuiButton-label {
    .transfer-actions & {
      display: flex;
      justify-content: space-around;
    }
  }

  & + .MuiButtonBase-root {
    .transfer-actions & {
      margin-top: 10px;
    }
  }

  &.menu-item-search {
    pointer-events: none;

    .form-control {
      pointer-events: all;
    }
  }

  &.MuiButtonBase-disabled {
    opacity: 0.4;
  
    .popper-actions & {
      opacity: 0.8;
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}


//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  color: $link-color;
  background-color: transparent;

  @include hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
    border-color: transparent;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    border-color: transparent;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
  }

  // No need for an active state here
}


//
// Button Sizes
//

.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
}

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
}

[class^='MuiCheckbox-root'],
[class*='MuiCheckbox-root'] {
  .sidebar-tree-checkbox-hide & {
    display: none;
  }
}

[class^='MuiListItem-gutters'],
[class*='MuiListItem-gutters'] {
  .tree-wrapper-secondary &,
  .sidebar-tree-checkbox-hide & {
    padding: 0;
  }
}

[class^='MuiButtonBase-root'],
[class*='MuiButtonBase-root'] {
  &:not(.MuiButton-sizeSmall) {
    .tree-wrapper-secondary &,
    .sidebar-tree-checkbox-hide & {
      border-top: 1px dashed #dadada;
      padding: 10px 0;
    }
  }

  .rct-sidebar:not(.sidebar-scroll-secondary) & {
    border-top: 1px dashed #dadada;
    padding: 10px !important;
  }

  .sidebar-ranking-mer.rct-sidebar:not(.sidebar-scroll-secondary) & {
    padding: 10px 20px !important;
  }

  .sidebar-group-fixed & {
    .sidebar-tree-checkbox-hide & {
      border-top-width: 0;
    }
  }
}

[class^='MuiListItemIcon-root'],
[class*='MuiListItemIcon-root'] {
  .tree-wrapper-secondary &,
  .sidebar-tree-checkbox-hide & {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    margin-right: 6px !important;
  }
}

[class^='MuiTypography-body1'],
[class*='MuiTypography-body1'] {
  p {
    .tree-wrapper-secondary &,
    .sidebar-tree-checkbox-hide & {
      margin: 0;
    }

    .tree-wrapper-secondary & {
      margin-left: 10px;
    }
  }

  i {
    .tree-wrapper-secondary &,
    .sidebar-tree-checkbox-hide & {
      display: block;
      width: 100%;

      font-size: 12px;
      opacity: 0.4;
    }
  }
}

//
// Block button
//

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}

button {
  .full-width & {
    width: 100%;
  }
}

.full-width {
  width: 100%;
}

.full-height {
  min-height: 100%;
}

.clear {
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  z-index: 2;
}

.ti-file {
  font-size: 30px;
}

.MuiButtonBase-disabled {
  &.MuiButton-outlined {
    background-color: transparent;
    color: #5c92f4 !important;
  }
}