.btn {
  &-xs {
    
    padding: 6px !important;
    
    line-height: 1 !important;
    
    .facet-group & {
      font-size: 10/16 + rem !important;
      min-height: 0 !important;
    }
  }

  &-danger {
    &.btn-sec {
      color: $danger;
      font-weight: $bold;

      border: none;
      
      background: none;
      background-color: $danger-sec;
      
      &:active {
        will-change: background-color, color;
        
        color: $danger !important;

        background-color: $danger-sec !important;
      }
    }
  }

  &-uppercase {
    text-transform: uppercase !important;

    span {
      line-height: 1;
    }
  }
}