
.productPriceContainer {
    display: flex;
    align-items: center;
    width: 100%;
}
.children {
    width: 100%;
    cursor: pointer;
}
.productPrice {
    padding: 0 5px 8px; 
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-items: flex-start;

    button {
        white-space: nowrap;
    }
}

.button {
    white-space: nowrap;
    span {
        white-space: nowrap;
    }
}

.closeButton {
    margin-left: auto;
}

.section {
    padding-top: 10px;
    
    span.title {
        padding-bottom: 5px;
    }
}

.row {
    display: flex;
    margin-left: 10px;
    padding: 5px 0 0;
    flex-wrap: nowrap;
    border-bottom: 1px solid white;
}