/*======== App Global (Main Structure) Mention Here =======*/
html {
	@media (max-width: 1280px) {
		font-size: 14px;
	}
}

#root,
.app {
	height: 100%;
	width: 100%;

	&-content {
		&-wrapper {
			height: 100%;

			.rct-app-content {
				height: 100%;
				width: 100vw;

				.rct-page {
					height: 100%;

					.full-height {
						display: flex;
						flex-direction: column;
						height: 100%;
					}
				}
			}
		}
	}
}

.main {
	&-content {
		max-width: 100%;
		padding-left: 245px;
		width: 100%;
	}
}

.cursor {
	&-progress {
		cursor: progress !important;
	}
}

.white {
	background-color: #fff;
}

.pointer {
	&:hover {
		will-change: cursor;

		cursor: pointer;
	}
}

.first-letter-up {
	text-transform: lowercase;
	display: block;

	&:first-letter {
		text-transform: uppercase;
	}
}

.three-dots-abs {
	position: absolute;
	bottom: -3px;
	right: 0;
	z-index: 1;
}

.cell {
	&-sm {
		min-width: 90px;

		.product-list-table-preview & {
			min-width: 93px;
		}
	}

	&-locked_attributes {
		.table-header-static-pim & {
			min-width: 190px !important;
		}
	}

	&-lg{
		min-width: 130px !important;

		&:last-child {
			padding-right: 15px;
		}

		&.cell-locked_attributes {
			.table-header-static-pim & {
				min-width: 190px !important;
			}
		}

		.table-header-static-pim & {
			min-width: 160px !important;
		}
	}

	&-xl {
		min-width: 180px !important;

		.table-header-static-pim & {
			min-width: 140px !important;
		}
	}

	&-id {
		min-width: 130px !important;
		max-width: 130px !important;

		.table-header-static-pim & {
			min-width: 130px !important;
			max-width: 130px !important;
		}
	}

	&-pub_aopi,
	&-condition,
	&-status,
	&-content_source,
	&-visibility_status {
		min-width: 120px !important;
		max-width: 120px !important;

		.table-header-static-pim & {
			min-width: 120px !important;
			max-width: 120px !important;
		}
	}

	&-categories,
	&-attribute_set {
		min-width: 140px !important;
		max-width: 140px !important;

		.table-header-static-pim & {
			min-width: 140px !important;
			max-width: 140px !important;
		}
	}
}

[class^="cell-scores.perfomance"],
[class*="cell-scores.perfomance"] {
	min-width: 130px !important;
	max-width: 130px !important;

	.table-header-static-pim & {
		min-width: 130px !important;
		max-width: 130px !important;
	}
}

.table-header-static-pim,
.product-item {
	[class^="cell-scores.perfomance.month"],
	[class*="cell-scores.perfomance.month"],
	[class^="cell-scores.data_quality"],
	[class*="cell-scores.data_quality"] {
		min-width: 130px !important;
		max-width: 130px !important;

		.table-header-static-pim & {
			min-width: 130px !important;
			max-width: 130px !important;
		}
	}

	[class^="cell-scores.total"],
	[class*="cell-scores.total"],
	[class^="cell-scores.pricing"],
	[class*="cell-scores.pricing"],
	[class^="cell-title.en"],
	[class*="cell-title.en"] {
		min-width: 100px !important;
		max-width: 100px !important;

		.table-header-static-pim & {
			min-width: 100px !important;
			max-width: 100px !important;
		}
	}

	[class^="cell-description.en"],
	[class*="cell-description.en"] {
		max-width: 150px !important;
	}

	[class^="data_quality"],
	[class*="data_quality"] {
		max-width: 95px;
	}

	[class^="ao_brand_name"],
	[class*="ao_brand_name"] {
		max-width: 95px !important;
		min-width: 95px !important;
	}
}


.app-main-container {
	display: flex;
	flex-wrap: nowrap;
}
.rct-page-content {
	padding: $grid-gutter-width; //24px;
	padding-bottom: 60px;

	@media (max-width: 1280px) {
		padding: 14px;
		background-color: #f5f6f7;
	}

	&.grid-wide {
		padding: 0;
		background-color: #fff;
	}
}

.dt {
	display: table;
}

.dtc {
	display: table-cell;
}


.dtr {
	display: table-row;
}

.disabled {
	opacity: 0.6;
	pointer-events: none;

	&.menu-item-attr {
		opacity: 0.3;
	}

	&-list {
		opacity: 0.3;
		pointer-events: none;
	}
}

.grid-wide {
	.product-item {
		padding: 0 !important;
	}

	.rtl {
		.product-item-card {
			border-left-width: 0;
			border-right-width: 0;
		}
	}

	.list {
		.product-item,
		.MuiGrid-item {
			padding: 0;

			&-image {
				margin-bottom: 0px;
			}
		}
	}

	.product-item,
	.MuiGrid-item {
		padding: 0;

		&-image {
			margin-bottom: 15px;
		}
	}

	.product-item-card {
		border-radius: 0;
		box-shadow: 0 0 0 0 transparent !important;
		border: 1px solid #eaeaea;
		border-top-width: 0;
		border-left-width: 0;
		padding: 30px 10px;
		min-height: 100%;
		width: 100%;



		&-list {
			border-radius: 0;
			box-shadow: 0 0 0 0 transparent !important;
			border-top-width: 0;
			border-left-width: 0;
			padding: 0 10px;
			min-height: 100%;
			width: 100%;
		}

		.product-item-data {
			& + .product-item-data {
				padding-top: 2px;
			}
		}
	}

	.MuiCardContent-root {
		padding: 0 0 10px !important;
	}
}

.visibility {
	&-hidden {
		visibility: hidden;
	}
}

.actions-bar {
	background-color: #eaeaea;
	position: fixed;
	bottom: 0;
	z-index: 11;
	left: 245px;
	right: 0;
	padding: 10px;

	.MuiSelect-icon {
		top: 0;
	}

	.input-text {
		.MuiInputLabel-root {
			left: 15px;
		}
	}

	.search-wrapper {
		& + .search-wrapper {
			margin-left: 10px;
		}
	}

	.action-buttons {
		white-space: nowrap;

		button {
			& + button {
				margin-left: 5px;
			}
		}
	}
}

.search-wrapper {
	.rct-sidebar-wrap & {
		width: 100%;
	}

	.error {
		position: absolute;
		top: -9px;
		left: 3px;

		& > * {
			color: red !important;
			white-space: nowrap;
		}
	}
}

.min {
	&-w {
		&-md {
			min-width: 300px;
		}
	}

	&-h {
		&-xs {
			min-height: 32px;
		}
		&-md {
			min-height: 45px;
		}
		&-full {
			min-height: 100%;
		}
	}

	&-w {
		&-s {
			min-width: 150px;

			&.MuiButtonBase-root {
				min-width: 140px;
			}
		}

		&-sm {
			min-width: 200px;
		}

		&-md {
			min-width: 250px;
		}

		&-xl {
			min-width: 350px;
		}
	}
}

.h {
	&-full {
		height: 100%;
	}
}

.width {
	&-full {
		max-width: 100%;
	}
}

.w {
	&-md {
		width: 55px !important;
	}

	&-p {
		&-20 {
			width: 20%;
		}

		&-25 {
			width: 25%;
		}

		&-50 {
			width: 50%;

			&-md {
				&-down {
					@media (max-width: 1280px) {
						width: 50%;
					}
				}
			}
		}

		&-75 {
			width: 75%;
		}
	}

	&-xxl {
		max-width: 600px;
		width: 100% !important;
	}
}

.t {
	&-1 {
		top: 1px;
	}
}

.hidden {
	display: none !important;
}

.overflow {
	&-y {
		&-auto {
			overflow-y: auto;
		}

		&-hidden {
			overflow-y: hidden;
		}
	}

	&-x {
		&-auto {
			overflow-x: auto;
		}

		&-hidden {
			overflow-x: hidden;
		}
	}
}

.max {
	&-h {
		&-md {
			height: 100vh;
			max-height: 550px;

			.MuiDialog-paperWidthXl & {
				height: 100%;
				max-height: none;
			}
		}

		&-lg {
			height: 100vh;
			max-height: 650px;
		}

		&-full {
			max-width: 100%;
		}
	}
}

.details-row {
	display: flex;
	width: 100%;
	word-break: break-word;

	b {
		font-weight: 600;
		margin-right: 5px;
		min-width: 88px;
		text-align: right;
	}
}

.mt {
	&-5 {
		margin-top: 5px;

		&-rev {
			margin-top: -5px;
		}
	}

	&-17 {
		margin-top: 17px;

		&-rev {
			margin-top: -17px;
		}
	}

	&-15 {
		margin-top: 15px;

		&-rev {
			margin-top: -15px;
		}
	}
}

.mb {
	&-15 {
		&-rev {
			margin-bottom: -15px;
		}
	}
}

.m {
	&-lg,
	&-15 {
		margin: 15px;

		&-auto {
			margin: 15px 0;
		}

		&-rev {
			margin: -15px;
		}
	}
}

.mr {
	&-10 {
		&-rev {
			margin-right: -10px;
		}
	}
}

.ml {
	&-a {
		margin-left: auto !important;
	}

	&-10 {
		margin-left: 10px;

		&-rev {
			margin-right: -10px;
		}
	}
}

.field {
	&-disabled {
		pointer-events: none;
		opacity: 0.3;
	}
}

.select-autocomplete-wrapper.has-errors {
	box-shadow: inset 0px -2px 0px 0px $danger !important;

	[class^="-placeholder"],
	[class*="-placeholder"] {
		color: $danger;
	}
}

.has-error .MuiInputLabel-root {
	color: $danger !important;
}

.actions-abs {
	bottom: 15px;
	left: 0;
	margin-left: 72px;

	& > * {
		& + * {
			margin-left: 10px;
		}
	}
}

.MuiSnackbarContent {
	&-root {
		position: relative;
		max-width: 900px !important;
	}

	&-message {
		max-height: 80vh;
		overflow-y: auto;
	}
}

.search-icon .search-wrapper .search-input-lg {
	width: 100%;

	&:focus {
		width: 100%;
	}
}

.w-px {
	&-50 {
		width: 50px;
	}
}

.no {
	&-event {
		pointer-events: none;
	}
}

.MuiGrid-grid-xs-3 {
	&:nth-child(4n+4) {
		.paper-wrapper {
			.grid-wrapper-3 & {
				right: 0;
				left: auto;
			}
		}
	}
}

.ti {
	&-drag {
		position: relative;
		font-family: 'Arial';
		transform: rotate(90deg);
		display: block;
		color: #818181;
		font-weight: 600;

		&:after {
			content: ':';
		}

		&:before {
			content: '::';
		}
	}
}

.MuiInputLabel-formControl {
	white-space: nowrap;
	pointer-events: none;

	z-index: 1;
}

.no-wrap {
	white-space: nowrap;
}

[class^='-menu'],
[class*='-menu'] {
	.select-autocomplete-wrapper & {
		.main-category-mer & {
			box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1) !important;
		}
	}

	.option-component {
		font-size: 12px;
		align-items: center;
		margin: 0;
		flex-wrap: wrap;

		.option-abbreviature {
			font-size: 11px;
			margin-left: 5px;
			color: #a7a7a7;
		}
	}

	.drop-up & {
		top: auto !important;
		bottom: 100% !important;
	}
}

.select-autocomplete-wrapper {
	&.disabled * {
		color: rgba(0, 0, 0, 0.54);
	}
}

.select-autocomplete-wrapper {
	padding: 10px 0;

	& > div {
		flex-wrap: inherit;
	}
}

.rct-page .auto-select-wrap .react-single-wrap > div[class*='-menu'],
.rct-page .auto-select-wrap .react-single-wrap > div[class*='-menu']:focus,
.rct-page .auto-select-wrap .react-single-wrap > div[class*='-menu']:hover {
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1) !important;
}

.main-category-mer .auto-select-wrap .react-single-wrap > div[class^='-control'],
.main-category-mer .auto-select-wrap .react-single-wrap > div[class*='-control'] {
	&:hover {
		box-shadow: none !important;
	}
}

.auto-select-wrap .react-single-wrap > div:hover {
	.main-category-mer & {
		box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1) !important;
	}
}

.snackbar {
	&-main-wrapper {
		top: 50%;
		transform: translateY(-50%);

		& > * {
			width: 100vw;
			display: flex;
			flex-direction: row;
			flex-flow: nowrap !important;
			padding-bottom: 60px !important;
		}

		.MuiSnackbarContent-action {
			align-self: flex-start;
			padding-left: 0;
		}
	}

	&-right > * {
		word-break: break-word;
	}
}

.rct-footer {
	box-shadow: $footer-box-shadow;
	background-color: $footer-bg;
	padding: 1.25rem; //20px;
	z-index: 9;
	h5 {
		margin-bottom: 0;
		color: $gray-600;
		font-size: 0.875rem; //14px;
	}
}

.menu-item-search {
	position: absolute !important;
	z-index: 1;
	top: 0px;
	right: 0;
	left: 0;
	background-color: #ededed !important;
	padding: 15px 30px 15px 15px !important;
}

.min-w-lg {
	min-width: 120px !important;
}

.container-loading .rct-sidebar {
	opacity: 0.6;
	pointer-events: none;
}

.MuiMenu-list {
	#menu-attributeValue & {
		padding-top: 30px;
	}
}

.MuiPopover-paper {
	#menu-value &,
	#menu-attributeValue & {
		max-height: 500px !important;
		min-height: 300px !important;
		min-width: 250px !important;
	}

	#menu-value &,
	#menu-attributeName & {
		max-height: 500px !important;
		min-height: 300px !important;
	}

	#menu-fieldOption &,
	#menu-field & {
		max-height: 300px !important;
		min-height: 200px !important;
	}

	.MuiMenuItem-gutters {
		#menu-fieldOption &,
		#menu-field & {
			padding: 8px;
			font-size: 15px;
		}
	}
	.menu-item-search {
		#menu-fieldOption,
		#menu-field & {
			padding: 10px 18px 14px 8px !important;
		}
	}

	.popper-md & {
		min-width: 420px;
	}
}

.pos {
	&-rel {
		position: relative !important;
	}

	&-abs {
		position: absolute;
	}

	&-fixed {
		position: fixed !important;
	}
}

.input-placeholder {
	position: absolute;
	top: -6px;
	left: 12px;
	font-size: 11px;
	color: #5d92f4;
	background-color: #edf4ff;
	padding: 0px 7px;
	border-radius: 5px;
	z-index: 9;

	&.warning {
		color: #f57c00;
		background-color: #fff4e4;
		padding: 0px 6px;
		border-radius: 3px;
		z-index: 1;
		letter-spacing: 0.1px;
	}
}

.rct-page {
	.sidebar-fixed & {
		margin-left: 245px;
	}

	&-grid {
		display: flex;
	}
}

.crm-layout {
	&.sidebar-fixed {
		& > [role="navigation"] {
			& > * {
				&:first-child {
					@media (min-width: 1200px) {
						top: 67px !important;
						position: fixed !important;
						transform: translateX(0%) !important;
					}
				}
			}
		}
	}

	&.sidebar-preview {
		& > [role="navigation"] {
			& > * {
				&:first-child {
					overflow-x: hidden;

					@media (min-width: 1200px) {
						top: 68px !important;
						position: fixed !important;
						transform: translateX(0%) !important;
					}
				}
			}
		}
	}

	& > [role="navigation"] {
		& > * {
			&:first-child {
				box-shadow: 0 0 0 0 transparent !important;

				@media (max-width: 1199px) {
					z-index: 20 !important;
					position: fixed !important;
					top: 61px !important;
					background-color: #f5f6f7;
					width: 245px;
				}
			}

			&:last-child {
				transition: none !important;
			}

			&:first-child {
				@media (min-width: 1200px) {
					transition: none !important;
				}
			}
		}
	}
}

.show-md {
	&-max {
		@media (min-width: 1200px) {
			display: none;
		}
	}
}

.rct {
	&-page {
		background-color: #fff;

		.crm-layout & {
			position: relative;

			z-index: 5;
		}
	}

	&-node {
		&.rct-disabled {
			& > .rct-text {
				& > label {
					opacity: 0.4;
				}
			}
		}
	}

	&-block {
		background-color: $block-bg;
		border-radius: $block-border-radius;
		box-shadow: $block-shadow;
		border: $block-border;
		transition: $block-transition;
		margin-bottom: $block-margin-bottom;
		position: relative;

		&-header {
			padding: $block-spacing 0;
		}

		&:focus-within {
			z-index: 6;
		}

		&-flat {
			box-shadow: 0 0 0 0 transparent;
			background-color: #f8f9fa;

			&:hover {
				box-shadow: 0 0 0 0 transparent !important;
			}
		}

		.rct-block-content {
			padding: $block-spacing;
		}
		&:hover {
			box-shadow: $block-hover-shadow;
		}

		&-title {
			padding: $block-title-spacing;
			position: relative;

			.badge {
				vertical-align: middle;
			}
			h4 {
				margin-bottom: 0;
				color: $block-title-color;
				font-size: $block-title-font-size;
				text-transform: $block-title-transform;
				font-weight: $block-title-font-weight;
				line-height: 30px;
			}
			.contextual-link {
				position: absolute;
				top: 20px;
				right: 20px;
				a {
					color: $block-contextual-color;
					font-size: $block-contextual-font-size;
					margin-right: 15px;
					&:hover {
						color: $blue;
					}
					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}
}

.rct-block {
	&-content {
		.preview-drawer & {
			padding: 0;
		}
	}

	&-content {
		.p-0 & {
			padding: 0;
		}
	}

	.preview-drawer & {
		background-color: transparent;
	}

	&-footer {
		background-color: $block-bg;
		padding: $block-title-spacing;
		border-top: $block-footer-border-width solid $block-footer-border-color;

	}
}

.card-footer {
	background-color: $block-bg;
}
.sub-title {
	padding: $block-title-spacing;
	margin-bottom: 1.25rem; //20px;
	h4 {
		margin-bottom: 0;
		color: $block-title-color;
		font-size: $block-title-font-size;
		text-transform: $block-title-transform;
		font-weight: $block-title-font-weight;
	}
}
[class*="gradient-"], [class^="gradient-"] {
	h4 {
		color: $white;
		border-color: $white;
	}
}
/*========== Page Breadcrumb ============*/
.page-title  {
	margin-bottom: 1.875rem; //30px;
	.breadcrumb{
		margin-bottom: 0;
	}
	.page-title-wrap > i {
		color: $breadcrumb-font-color;
		vertical-align: 3px;
		margin-right: 10px;
	}
	h2 {
		font-size: $breadcrumb-font-size;
		color: $breadcrumb-font-color;
		display:  inline-block;
		text-transform:capitalize;
		margin: 0;
	}
	.rct-creadcrumb {
		color: $breadcrumb-font-color;
		font-size: 14px;
		i {
			font-size: 12px;
			margin: 0 5px;
		}
	}
}

.capitalize {
	text-transform: capitalize;
}

.relative {
	position: relative;
}

.product-item {
	.MuiCardContent-root {
		padding: 0 !important;
	}
}

.title-tooltip-scroll::-webkit-scrollbar-track{
	background-color: #616161;
}

.snackbar-main-wrapper .MuiSnackbarContent-message::-webkit-scrollbar-track {
	background-color: #313131;
}

.snackbar-main-wrapper .MuiSnackbarContent-message::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	background-color: #313131;
}

.title-tooltip-scroll::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	background-color: #616161;
}

.title-tooltip-scroll::-webkit-scrollbar-thumb {
	background-color: #fff;
	border-radius: 20px;
	border: 3px solid #616161;
}

.snackbar-main-wrapper .MuiSnackbarContent-message::-webkit-scrollbar-thumb {
	background-color: #fff;
	border-radius: 20px;
	border: 3px solid #313131;
}

.MuiCollapse-wrapper::-webkit-scrollbar-track,
.scroll-light::-webkit-scrollbar-track,
.scroll-light .MuiDrawer-paper::-webkit-scrollbar-track,
.pim-list::-webkit-scrollbar-track,
.sortable-image-list::-webkit-scrollbar-track,
.product-list .MuiGrid-container::-webkit-scrollbar-track,
.MuiDialogContent-root::-webkit-scrollbar-track,
.product-description textarea::-webkit-scrollbar-track,
.MuiCardContent-root::-webkit-scrollbar-track,
.product-list::-webkit-scrollbar-track,
.table-header-staticr::-webkit-scrollbar-track,
.MuiGrid-container::-webkit-scrollbar-track,
.dnd-wrapper::-webkit-scrollbar-track {
	background-color: #fff;
}

.MuiCollapse-wrapper::-webkit-scrollbar,
.pim-list::-webkit-scrollbar,
.sortable-image-list::-webkit-scrollbar,
.product-list .MuiGrid-container::-webkit-scrollbar,
.MuiDialogContent-root::-webkit-scrollbar,
.product-description textarea::-webkit-scrollbar,
.product-list::-webkit-scrollbar,
.table-header-static::-webkit-scrollbar,
.MuiGrid-container::-webkit-scrollbar,
.scroll-light .MuiDrawer-paper::-webkit-scrollbar-thumb,
.dnd-wrapper::-webkit-scrollbar {
	width: 16px;
	height: 16px;
	background-color: #F5F5F5;
}

.product-list .MuiGrid-container::-webkit-scrollbar-thumb,
.MuiDialogContent-root::-webkit-scrollbar-thumb,
.product-description textarea::-webkit-scrollbar-thumb,
.product-list::-webkit-scrollbar-thumb,
.table-header-static::-webkit-scrollbar-thumb,
.MuiGrid-container::-webkit-scrollbar-thumb,
.dnd-wrapper::-webkit-scrollbar-thumb {
	background-color: #b8b8b8;
	border-radius: 20px;
	border: 5px solid #fff;
}


.MuiCollapse-wrapper::-webkit-scrollbar,
.scroll-light::-webkit-scrollbar,
.scroll-light .MuiDrawer-paper::-webkit-scrollbar,
.pim-list::-webkit-scrollbar,
.sortable-image-list::-webkit-scrollbar {
	width: 6px;
	height: 6px;
	background-color: #F5F5F5;
}

.MuiCollapse-wrapper::-webkit-scrollbar-thumb,
.scroll-light::-webkit-scrollbar-thumb,
.scroll-light .MuiDrawer-paper::-webkit-scrollbar-thumb,
.pim-list::-webkit-scrollbar-thumb,
.sortable-image-list::-webkit-scrollbar-thumb {
	background-color: #b8b8b8;
	border-radius: 20px;
	border: 1px solid #fff;
}

.scroll-light .MuiDrawer-paper::-webkit-scrollbar-thumb,
.sidebar-preview .MuiCollapse-wrapper::-webkit-scrollbar-thumb {
	background-color: #dfdfdf;

	&:hover {
		background-color: #c5c5c5;
	}
}

.MuiCardContent-root::-webkit-scrollbar-thumb {
	background-color: #b8b8b8;
}

.MuiCollapse-wrapper::-webkit-scrollbar-track {
	background-color: #f9f9f9;
}

.MuiCardContent-root::-webkit-scrollbar {
	width: 1px;
	height: 1px;
	background-color: #F5F5F5;
}

.float-left {
	float: left !important;
}
.float-right {
	float: right !important;
}
.clear-both {
	clear: both;
}
.logo-color {
	background-color: #A9203E !important;
}
.green-color {
	background-color: #00A000 !important;
}
.w-200 {
	width: 200px !important;
}
.w-300 {
	width: 300px !important;
}

.mapper {
	width: 100%;
	td {
		padding: 5px;
		border: 1px solid gray;
		text-align: center;
	}
}
.mapper-search-wrapper {
	width: 100%; padding-right: 20px;
}
.mapper-block {
	display: block;
}
.mapper-none {
	display: none;
}
.mapper-cursor-pointer {
	cursor: pointer;
}
.mapper-cursor-default {
	cursor: default;
}
.mapper-categories-selected {
	cursor: default;
	span {
		color: #007ADF !important;
	}
}
.mapper-categories-unselected {
	cursor: pointer;
	text-decoration: underline;
}
.mapper-categories-unselected:hover {
	text-decoration: none;
}
.mapper-navigation {
	width: 100%;
	padding-top: 20px;
}
.mapper-navigation-per-page {
	top: 10px;
	position: relative;
	font-size: 15px;
	float: right;
	padding-right: 10px;
	padding-bottom: 20px;
}
.mapper-total {
	font-size: 15px;
	float: right;
	padding-right: 20px;
}
.mapper-no-wrap {
	white-space: nowrap;
	font-size: 14px;
	font-weight: bold;
	padding: 5px;
}
.mapper-sticky {
	position: sticky;
}
.mapper-select {
	background-color: #F8F8F9;
	border: 1px solid #C5C5C5;
	border-radius: 5px;
	padding: 5px;
	width: 100%;
}
.mapper-select-marketplace {
	background-color: #F8F8F9;
	border: 1px solid #C5C5C5;
	border-radius: 5px;
	padding: 10px;
	width: 100%;
	position: relative;
	top: -12px;
	font-weight: 500;
}
.mapper-checkbox {
	width: 40px;
	border: 1px solid #C5C5C5;
	border-radius: 5px;
	padding: 10px;
}
.mapper-radio {
	padding: 0 20px;
}
.mapper-input {
	width: 100%;
	border: 1px solid #C5C5C5;
	border-radius: 5px;
	padding: 10px;
}
.mapper-search-input {
	width: 100%;
}
.mapper-search-input-price {
	width: 47%;
}
.mapper-no-results {
	padding: 20px;
	text-align: center;
	width: 100%;
	font-size: 14px;
	white-space: nowrap !important;
}
.mapper-editable {
	cursor: pointer;
}
.mapper-no-wrap {
	white-space: nowrap !important;
}
.mapper-w-350 {
	width: 350px !important;
}
.mapper-w-250 {
	width: 250px !important;
}
.mapper-w-200 {
	width: 200px !important;
}
.mapper-w-150 {
	width: 150px !important;
}
.mapper-w-155 {
	width: 155px !important;
}
.mapper-w-100 {
	width: 100px !important;
}
.mapper-w-75 {
	width: 75px !important;
}
.mapper-w-50 {
	width: 50px !important;
}
.mapper-sortable {
	cursor: pointer;
	text-decoration: underline;
}
.mapper-sortable:hover {
	text-decoration: none;
}
.mapper-sort-arrow {
	font-size: 20px;
	font-weight: 900;
}
.mapper-padd-5 {
	width: 100%;
	padding-bottom: 5px;
}
.mapper-padd-bott-5 {
	width: 100%;
	padding-top: 5px;
}
.mapper-marg-top-5 {
	margin-top: 5px;
}
.mapper-h-40 {
  height: 40px;
}
.mapper-h-50 {
	height: 50px;
}
.mapper-h-140 {
	height: 140px;
}
.spinning {
	width: 100%;
	position: absolute;
	height: 100%;
	left: 0;
	right: 0;
	top: -200px;
	background: #fff;
	padding: 5px;
	opacity: 0.8;
	z-index: 99;
}
.spinning-text {
	width: 100%;
	position: absolute;
	padding: 150px;
	text-align: center;
	font-size: 15px;
	z-index: 100;
}
.mapper-categories {
	padding: 5px;
	cursor: pointer;
}
.mapper-selected-marketplace-categories {
	color: #ff9800 !important;
}
.mapper-level-0 {
	padding-left: 0;
	font-size: 13px;
}
.mapper-level-0:hover {
	text-decoration: none;
}
.mapper-level-1 {
	padding-left: 0;
	font-weight: bold;
	font-size: 14px;
	text-decoration: underline;
}
.mapper-level-1:hover {
	text-decoration: none;
}
.mapper-level-2 {
	padding-left: 20px;
	font-size: 13px;
	text-decoration: underline;
}
.mapper-level-2:hover {
	text-decoration: none;
}
.mapper-is-no-children {
	color: #464D69 !important;
	cursor: default !important;
	text-decoration: none !important;
}
.mapper-level-3 {
	padding-left: 40px;
	font-size: 12px;
}
.mapper-sidebar-parent {
	height: 850px;
	padding-bottom: 40px;
	overflow-y: auto;
}
.mapper-sidebar {
	overflow: hidden;
}
.mapper-error {
	color: #A9203E;
	font-size: 12px;
}
.mapper-new-record {
	position: absolute;
	top: 0px;
	z-index: 100;
	padding: 50px;
	border: 1px solid #b8b8b8;
	border-radius: 10px;
	background-color: #fff;
	width: 96%;
}
.mapper-new-record-close {
	position: absolute;
	font-size: 20px;
	cursor: pointer;
	top: 10px;
	right:20px;
	font-family: system-ui;
}
.mapper-copy {
	cursor: pointer;
	text-decoration: underline !important;
}
.mapper-copy:hover {
	text-decoration: none !important;
}
.mapper-filter-disabled {
  width: 100%;
  font-size: 15px;
  text-align: center;
  padding-top: 10px;
}
.mapper-search-error {
  color: #A9203E;
  font-size: 12px;
  position: absolute;
}
.mapper-search-form {
  width: 100%;
}
.mapper-copy-url input {
	border: none !important;
	cursor: pointer;
	margin: 0;
	padding: 0 !important;
}
.mapper-table-scroll {
	position: fixed;
	height: 20px;
	bottom: 75px;
}
.suggest-product {
	text-decoration: underline;
	color: #00b3ee;
	cursor: pointer;
}
.suggest-product:hover {
	text-decoration: none;
}
.suggest-container {
	width: 100%;
	height: 200px;
	overflow-y: auto;

}
.suggest-parent {
	width: 100%;
	height: 100%;
}
.suggest-image {
	padding: 5px;
	float: left;
}
.suggest-info {
	padding-top: 10px;
	padding-left: 10px;
	float: left;
}
.date-filter-link {
	padding: 5px 0 5px 0;
	text-decoration: underline;
	cursor: pointer;
}
.date-filter-link:hover {
	padding: 5px 0 5px 0;
	text-decoration: none;
}
.zap-link {
	text-decoration: underline;
	cursor: pointer;
}
.zap-link:hover {
	text-decoration: none;
}
.zap-position {
	font-size: 18px;
    float: left;
}
.zap-publish-price {
  font-size: 18px;
  float: right;
}
.zap-table {
	width: 100%;
	td, th {
		padding: 5px;
		margin: 2px;
		border: 1px solid #b8b8b8;
		border-radius: 2px;
		font-size: 16px;
	}
}
.zap-green {
	color: #00A000 !important;
}
.zap-red {
	color: #A9203E !important;
}
.zap-link {
	padding-top: 10px;
	text-align: right;
}
.zap-box {
	padding: 10px;
	border: 1px solid #b8b8b8;
	border-radius: 5px;
}
.zap-statuses {
	font-size: 12px;
}
.zap-url {
	font-size: 12px;
	color: #464D69;
}
.zap-statuses-title {
	color: #464D69;
	padding: 0;
	font-size: 11px;
	font-family: Roboto,Helvetica,Arial,Heebo,sans-serif;
	font-weight: 400;
	line-height: 1;
}
.zap-statuses-sync_0 {
	font-size: 12px;
	color: #007ADF !important;
}
.zap-statuses-sync_1 {
	font-size: 12px;
	color: #ff9800 !important;
}
.zap-statuses-sync_2 {
	font-size: 12px;
	color: #00A000 !important;
}
.zap-statuses-sync_3 {
	font-size: 12px;
	color: #A9203E !important;
}
.list-navigation-left {
	float: left !important;
	width: 100px !important;
}
.list-navigation-right {
	float: right !important;
	width: 100px !important;
	text-align: right !important;
}
.pagination-link {
	color: #007ADF;
	span {
		color: #007ADF !important;
	}
}
.rules-new-record {
	position: absolute;
	top: 10px;
	z-index: 100;
	padding: 10px;
	border: 1px solid #b8b8b8;
	border-radius: 10px;
	background-color: #fff;
	width: 98%;
}
.buttons-area {
	background-color: #F8F8F9;
	border: 1px solid #C5C5C5;
	padding: 10px;
	text-align: right;
}
.pr20 {
	padding-right: 20px;
}
.rules-new-record-header {
	padding: 20px 0 0 0;
	border-bottom: 1px solid #b8b8b8;
}
.rules-new-record-header-no-border {
	padding: 20px 0 0 0;
}
.rules-new-record-header-left {
	width: 90%;
	float: left;
	font-size: 16px;
	font-weight: bold;
	cursor: pointer;
	padding: 10px;
}
.rules-new-record-header-right {
	width: 40px;
	float: right;
	font-size: 16px;
	font-weight: bold;
	text-align: right;
	cursor: pointer;
	padding: 10px;
}
.round {
	border: 1px solid #b8b8b8;
	background-color: #F8F8F9;
	font-size: 20px;
	border-radius: 100%;
	width: 32px;
	text-align: center;
	font-weight: 900;
}
.rules-form {
	padding: 10px;
}
.rules-right {
	text-align: right;
	white-space: nowrap;
	display: block;
	padding-top: 7px;
}
.rules-left {
	text-align: left;
	white-space: nowrap;
	display: block;
}
.rules-full-width {
	display: grid !important;
}
.rules-error {
	font-size: 12px;
	color: #A9203E;
	display: block;
	text-align: left;
	padding-bottom: 5px;
	padding-top: 2px;
}
.rules-conditions-operator {
	color: #00A000;
	font-size: 14px;
	text-decoration: none;
	cursor: pointer;
	text-transform: uppercase;
}
.rules-conditions-operator-no-edit {
	color: #00A000;
	font-size: 14px;
	text-transform: uppercase;
}
.rules-conditions-operator:hover {
	font-size: 14px;
	text-decoration: underline;
	cursor: pointer;
}
.rules-conditions-value {
	font-size: 14px;
	text-decoration: none;
	cursor: pointer;
}
.rules-conditions-value:hover {
	font-size: 14px;
	text-decoration: underline;
	cursor: pointer;
}
.rules-conditions-table {
	width: auto !important;
}
.rules-conditions-table td {
	padding-right: 0;
	padding-bottom: 10px;
	border-bottom: 0;
}
.rules-condition-delete {
	color: #A9203E;
	cursor: pointer;
	font-size: 20px;
}
.rules-gray {
	color: #b8b8b8;
}
.rules-w-100 {
	width: 100px !important;
}
.rules-w-150 {
	width: 150px !important;
}
.rules-multiselect-add {
	color: #ff9800;
	font-weight: bold;
	font-size: 20px;
	cursor: pointer;
}
.rules-remove-multiselect-value {
	color: #A9203E;
	font-weight: bold;
	font-size: 16px;
	cursor: pointer;
}
.rules-multiselect-table td {
	padding: 0 !important;
}
.rules-autocomplete-parent {
	position: absolute;
	background-color: #ffffff;
	border: 1px solid #b8b8b8;
	border-radius: 5px;
	padding-top: 5px;
	z-index: 1000;
	min-width: 300px;
	margin-top: 5px;
}
.rules-autocomplete {
	padding: 0 5px 5px 10px;
	cursor: pointer;
}
.rules-autocomplete:hover {
	text-decoration: underline;
}
.rules-autocomplete-search {
	color: #ff9800 !important;
}
.rules-no-border {
	border-bottom: none !important;
}
.rules-edit {
	cursor: pointer;
	color: #ff9800;
	font-size: 18px;
	float: left;
}
.rules-delete {
	cursor: pointer;
	color: #A9203E;
	font-size: 18px;
	float: left;
}
.rules-search-form {
	height: 750px;
}
.rules-textarea {
	border: 1px solid #b8b8b8;
	min-height: 100px;
	border-radius: 5px;
	padding: 5px;
}
.rules-search-input {
	padding: 2px;
}
.rules-textarea:hover {
	border: 1px solid #464D69;
}
.rules-textarea:focus {
	border: 1px solid #007ADF;
}
.rules-search-input {
	width: 100%;
	background-color: #FFFFFF;
}
.locked-attribute {
	cursor: pointer;
	position: relative;
	right: -15px;
	top: 28px;
	float: right;
	z-index: 1000;
}
.locked-attribute-images {
	top: 22px !important;
}
.locked-attribute-videos {
	top: -30px !important;
}
.locked-attribute-status {
	top: -50px !important;
}
.locked-attribute-fields {
	top: 29px !important;
}
.locked-attribute-dropdown {
	top: 25px !important;
}
.locked-attribute-title {
	top: 10px !important;
}
.locked-attribute-merchant {
	cursor: pointer;
	position: relative;
	top: 7px;
	left: -5px;
}
.product-info-merchant {
	cursor: pointer;
	position: relative;
	top: -30px;
	left: -15px;
}
.product-info-merchant-top {
	top: -10px !important;
}
.merchant-w-15 {
	width: 15px !important;
}
.merchant-left-7 {
	left: 7px;
}
.merchant-left-visibility-status {
	top: -35px;
	left: -20px;
}
.display-none {
	display: none;
}
.pim-status-label {
	position: absolute !important;
	z-index: 1000;
	cursor: pointer;
}
.cams-show-popup {
	color: #007ADF;
	cursor: pointer;
	text-decoration: none;
}
.cams-show-popup:hover {
	text-decoration: underline;
}
.cams-action-success {
	width: 20px;
	height: 20px;
	border: 1px solid #FFFFFF;
	float: left;
	margin: 1px;
	cursor: pointer;
	background-color: #00A000 !important;
}
.cams-action-error {
	width: 20px;
	height: 20px;
	border: 1px solid #FFFFFF;
	float: left;
	margin: 1px;
	cursor: pointer;
	background-color: #A9203E !important;
}
.cams-action-not-available {
	width: 20px;
	height: 20px;
	border: 1px solid #FFFFFF;
	float: left;
	margin: 1px;
	cursor: pointer;
	background-color: #b8b8b8 !important;
}
.cams-info-wrapper {
	position: absolute;
	z-index: 1000;
}
.cams-info {
	position: relative;
	padding: 5px;
	border: 1px solid #464D69;
	border-radius: 5px;
	cursor: pointer;
	font-size: 14px !important;
	background-color: #FFFFFF;
	color: #464D69 !important;
	z-index: 1000;
}
.cams-no-wrap {
	white-space: nowrap;
}
.cams-status-error {
	font-size: 12px !important;
}
.cams-search-input {
	background-color: #FFFFFF !important;
}
.cams-errors-td {
	vertical-align: top !important;
}
.cams-arr-div {
	white-space: nowrap !important;
	padding-bottom: 2px;
}

.header-hidden {
	display: none !important;
}
.rules-padd-5 {
	width: 100%;
	padding-top: 2px;
	padding-bottom: 3px;
}
.rules-padd-2 {
	width: 100%;
	padding-top: 2px;
}
.preview-select {
	cursor: pointer !important;
	position: absolute !important;
	z-index: 1000 !important;
	.div {
		.div {
			pointer-events: auto !important;
		}
	}
}
.select-prefix {
	pointer-events: auto !important;
}

.merchant-info {
	position: absolute;
	left: 0;
	top: 0;
	padding: 10px;
	border-radius: 10px;
	border: 1px solid #464D69;
	width: 100%;
	background-color: #EBF8F2;
	z-index: 100;
}
.merchant-info-button {
	position: absolute !important;
	bottom: 10px !important;
	right: 10px !important;
}
.merchant-info-link {
	color: #007ADF;
	cursor: pointer;
}
.merchant-info-link:hover {
	text-decoration: underline;
}
.export-description {
	font-size: 11px;
	padding: 10px;
	border-radius: 10px;
	width: 100%;
	z-index: 100;
}
.preview-export-description {
	font-size: 12px;
	padding: 0 20px 0 20px;
}
.preview-export-error {
	font-size: 12px;
	padding: 0 20px 15px 20px;
	color: #A9203E;
}
.rules-padd-5 {
	width: 100%;
	padding-top: 2px;
	padding-bottom: 3px;
}
.rules-padd-2 {
	width: 100%;
	padding-top: 2px;
}
.export-products-input {
	border: 1px solid #C5C5C5;
	width: 100%;
	border-radius: 50px;
	padding: 10px;
	background-color: #EFEFEF;
}
.preview-product-star {
	font-size: 24px;
	right: 0;
	position: relative;
	top: -8px;
	color: #5D92F4;
	cursor: pointer;
}
.preview-product-remove-stars {
	position: absolute !important;
	color: #5D92F4;
	cursor: pointer;
	padding: 5px;
	z-index: 100 !important;
	top: 11px;
}
.preview-product-remove-stars:hover {
	text-decoration: underline;
}
.favorite-avatar:before {
	content: '★';
	color: #5D92F4;
	top: 0;
	right: 0;
	font-size: 25px;
	font-weight: bolder;
	position: absolute;
}
.preview-export-products-input {
	border: 1px solid #C5C5C5;
	width: 100%;
	padding: 10px;
	border-radius: 5px;
}
.valign-top {
	vertical-align: top !important;
}

input[type=number].no-spinner::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.repricer-table {
	border-left: 1px solid #eaeaea;
}
.repricer-table th {
	padding: 0 10px 0 10px;
	hight: 44px;
	border: 0px;
}
.repricer-pagination {
	padding-left: 20px;
	padding-right: 20px;
}
.repricer-model-name{
	padding-top: 10px;
}
.repricer-popup-container {
	position: relative;
	left: -50px;
	top: -50px;
}
.repricer-popup {
	position: absolute;
	cursor: default;
	background: #FFFFFF;
	border: 1px solid #C5C5C5;
	border-radius: 5px;
	width: 200px;
	padding: 10px;
}
.sticky-search {
	position: sticky;
	top: 0px;
	width: 100%;
	background-color: #ffffff;
}
.repricer-clear-filter {
	color: #A9203E;
	position: absolute;
	top: 8px;
	cursor: pointer;
	right: 35px;
	z-index: 1000;
	background-color: #ffe2e2;
	width: 20px;
	text-align: center;
	border-radius: 100%;
}
.repricer-clear-filter.text {
	top: 10px !important;
	right: 45px !important;
}
.search-container {
	padding: 0 5px 0 10px;
}