// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge {
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  font-size: $badge-font-size;
  font-weight: $badge-font-weight;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  @include border-radius($badge-border-radius);

  &-body {
    font-size: $fz-13;
    white-space: nowrap;

    min-width: 65px;
  }

  &-title {
    font-size: $fz-11;
    line-height: 1;
    opacity: 0.6;
  }

  &-pill {
    position: relative;
  
    display: flex;
    align-items: baseline;
  
    width: 100%;
  }
  
  &-label {
    .badge-pill & {
      width: 100%;

      text-overflow: ellipsis;
      overflow: hidden;
    }

    .badge-outline & {
      font-size: 13px;

      .sidebar-scroll-secondary & {
        font-size: 12px;
      }
    }
  }

  &-outline {
    align-items: center;
    background-color: transparent !important;
    
    &.badge-primary {
      color: #5D92F4 !important;
      box-shadow: 0 0 0 2px $primary;

      .sidebar-scroll-secondary & {
        padding: 0px 10px 0px;
      }

      .MuiIconButton-root {
        &:hover {
          background-color: rgba(#5D92F4, 0.15);
        }
      }

      .btn-mui-badge {
        .sidebar-scroll-secondary & {
          margin: 0px -10px 0px 0px !important;
        }
      }
    }

    .MuiSvgIcon-fontSizeSmall {
      color: $primary !important;

      font-size: 14px;
    }
  }

  &-rel {
    position: relative !important;
  }

  &-text {
    display: flex;
    align-items: center;
    justify-content: center;

    width: auto !important;

    border-radius: 3px !important;

    padding: 0px 12px !important;
    font-size: 10px !important;
    min-height: 20px !important;
  }

  &-success {
    box-shadow: 0px 0px 0px 2px #fff;
    
    &.secondary {
      background-color: #dcf3e2;
      color: #3fad5a;
    }
  }

  &-tr {
    padding: 0px 4px !important;
    min-height: 14px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: 8px;
  }

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }

  h4 {
    margin: 0;
  }
}

// Quick fix for badges in buttons
.btn .badge {
  position: relative;
  top: -1px;
}

// Pill badges
//
// Make them extra rounded with a modifier to replace v3's badges.

.badge-pill {
  padding-right: $badge-pill-padding-x;
  padding-left: $badge-pill-padding-x;
  @include border-radius($badge-pill-border-radius);
}

.MuiBadge-badge {
  .badge-s & {
    font-size: 9px;
    color: #fff !important;
    min-width: 15px;
    height: 15px;
    top: 3px;
    right: 4px;
  }
}

.MuiButtonBase-root + .MuiBadge-dot {
  box-shadow: 0px 0px 0px 2px #fff;
}

// Colors
//
// Contextual variations (linked badges get darker on :hover).

@each $color, $value in $theme-colors {
  .badge-#{$color} {
    @include badge-variant($value);
  }
}
