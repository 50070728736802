/*============ Sidebar Layout ===========*/

.rct-page-wrapper {
   [direction="right"] {
      border-right: none;
      z-index: 999 !important;
   }
}

.sidebar-toggle {
  display: none;

  .humburger {
    border-color: transparent !important;
  }

  @media (max-width: 1199px) {
    display: block;

    .app-header .rct-header & {
      position: absolute;
    }
  }

  svg {
     g {
      .app-header .rct-header & {
         fill: #d31242;
       }
     }
  }
}

.sidebar {
   &-sticky-block {
      position: sticky;
      top: 0;
      background-color: #f9f9f9;
      z-index: 2;

      padding-top: 10px;

      .sidebar-inner-wrapper-lg & {
        padding-top: 0;
        margin: 0px -10px;
        padding: 7px 10px 0;
      }

      & + .expansion-panel {
         padding-top: 0 !important;
      }
   }

   &-wrapper {
    min-width: 245px;

    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 40;
    
    background-color: #f5f6f7;

    .MuiDrawer-paper {
      top: 65px;
      max-width: 245px;
      padding: 0 10px;

      background-color: #f9f9f9;
    }
   }

   &-influencer {
      .MuiDrawer-paper {
         width: 245px;
         background-color: #f9f9f9;
         top: 65px;

         .MuiListItemText-root {
            .MuiTypography-root {
               font-size: 13px;
            }
         }
      }
   }

   &-sticky-item {
      & > div {
         display: none;

         &:first-child {
            display: block;

            overflow: visible !important;
            margin-right: 0 !important;

            .preview-sidebar {
               height: 100%;
               
               overflow-y: auto;
               
               padding-bottom: 40px;
               padding-top: 0;
              //  padding-right: 10px !important;
               
               @media (max-width: 1280px) {
                  height: 101%;
                }

               &::-webkit-scrollbar {
                  width: 10px;
                  height: 10px;
                  background-color: #f9f9f9;

               }

               &::-webkit-scrollbar-track {
                  background-color: #f9f9f9;
               }

               &::-webkit-scrollbar-thumb {
                  background-color: #e4e4e4;
                  border-radius: 20px;
                  border: 3px solid #f9f9f9;
                  &:hover {
                     background-color: #c5c5c5;
                  }
               }

               .tree-wrapper {
                  min-height: auto;
               }

               .sticky-label {
                  position: sticky;
                  top: 0px;
                  bottom: 10px;
                  background-color: #f9f9f9;
                  z-index: 10;
                  padding: 10px 0 5px !important;
                  margin: 0px 0 5px;
               }
            }
         }
      }
   }

   &-link {
      &-item {
         padding: 0 !important;
      }

      &-child {
         padding-left: 30px;
         width: 100%;

         .sidebar-link-item & {
            padding: 0px 0px 0px 15px;
         }
      }
   }

   &-group {
      & + .sidebar-group {
         margin-top: 15px;
      }

      .sidebar-ranking-mer &,
      .sidebar-category-mer & {
         @media (max-width: 1280px) {
            margin-top: 5px;
         }
      }

      &-fixed {
         position: fixed;
         bottom: 10px;
         width: 200px;
         width: calc(100% - 20px);

         .sidebar-scroll-secondary & {
            left: 10px;
         }
      }

      & + .rct-scroll {
         margin-top: 10px;

         .sidebar-navigation-mer & {
            margin-top: 0;
         }
      }

      .MuiDivider-root {
         height: 0;
         background-color: transparent;
         border-bottom: 1px dashed rgba(0, 0, 0, 0.12);
      }

      .MuiListItemText-root {
         font-size: 15px;

         color: #000;
      }
   }

   &-title {
      font-size: 12px;
      margin-bottom: 0;
      margin-top: 10px;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 500;

      opacity: 1;
      position: sticky;
      top: -10px;
      background-color: #f9f9f9;
      z-index: 1;
      padding: 10px 0px;

      .sidebar-inner-wrapper-lg & {
        top: 42px;
        padding: 10px !important;
        margin: 0px -10px;
      }

      &.influencer {
         padding: 16px;
         margin-top: 0;
      }

      .sidebar-ranking-mer &,
      .sidebar-category-mer & {
         padding: 0;

         @media (max-width: 1280px) {
            margin-top: 5px;
         }
      }

      span {
         color: #8d92a3 !important;
      }
   }

   &-list {
      &-item {
         padding: 10px 0;

         &:first-letter {
            text-transform: uppercase;
         }

         &:hover {
            color: $primary !important;
            cursor: pointer;
         }

         .sidebar-ranking-mer & ,
         .sidebar-category-mer & {
            @media (max-width: 1280px) {
               padding: 0px;
            }
         }

         & + .sidebar-list-item {
            border-top: 1px solid #e4e4e4;
         }
      }
   }

   &-influencer {
      .search-wrapper {
         padding: 0 16px 16px 16px;
      }
   }
}

.MuiDrawer-paper {
  .sidebar-inner-wrapper-lg & {
    top: 0;
  }
}

.rct-sidebar {
   background-repeat: no-repeat;
   background-size: cover;
   width: $sidebar-width;
   background-position: top center;
   overflow: hidden;
   transition: all 200ms ease 0s;
   height: 100%;

   max-width: 245px;

   @media (max-width: 1280px) {
      width: 100vw;;
   }

   .rc-tree-node-content-wrapper .rc-tree-title:hover,
   .rc-tree-treenode-switcher-close .rc-tree-title:hover {
      color: inherit !important;
   }
  
   .rct-sidebar-content {
      position: relative;
      z-index: 0;
      height: 100%;
   
      &:before {
         position: absolute;
         top: 0;
         left: 0;
         right: 0;
         bottom: 0;
         content: "";
         z-index: -1;
         opacity: 0.9;
      }
      &.sidebar-overlay-dark:before {
         background: $sidebar-dark-gradient;
      }
      &.sidebar-overlay-light:before {
         background-color: $overlay-light;
      }
      &.sidebar-overlay {
        &-gray {
          &:before {
            background-color: $gray;
          }
        }
        &-success {
          &:before {
            background-color: $success;
          }
        }
      }
   }
   &.background-none .rct-sidebar-content:before {
      opacity: 1 !important;
   }
   .site-logo {
      padding: 0.89rem 1.5rem; //15px 30px;
      align-items: center;
      display: flex;
   }
   .rct-sidebar-wrap {
     padding: 10px;

     .rc-tree {
        padding: 5px 0px 0px 0px;

        li {
           overflow: hidden;

           border-top: 1px dashed rgba(0, 0, 0, 0.12);
           background-color: transparent;
           transition: background-color 0.2s ease;

           &:hover {
            text-decoration: none;
            box-shadow: -1px 0 0 0 $primary;
         }
        }
     }

     .preview-sidebar  {
         overflow-x: hidden;
         padding: 10px 0;
     }
    
      .top-sidebar {
         .sidebar-user-block {
            padding: 1.25rem; //20px;
            .rct-dropdown {
               cursor: pointer;
               .dropdown-menu {
                  background-color: $base-light;
                  width: 200px;
                  ul {
                     background-color: transparent;
                     li:not(.user-profile-top) {
                        box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.02);
                        margin: 0.3125rem;
                        a {
                           background-color: $white;
                           padding: 0.75rem 1rem;
                           display: block;
                           i {
                              font-size: 1rem;
                           }
                           span:not(.badge) {
                              color: $body-color;
                              font-size: $font-size-sm;
                              transition: color 0.3s ease;
                           }
                           &:hover {
                              span:not(.badge) {
                                 color: $primary;
                              }
                           }
                        }
                     }
                  }
               }
            }
         }
      }
   }
   .user-profile {
      >img {
         @include border (3px solid, $white, top bottom left right);
         box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.5);
      }
   }
   .rct-sidebar-nav {
      .rct-mainMenu {
         li {
            &.side-title {
               font-size: 0.75rem;
               line-height: 1.25rem;
               position: static;
               padding: 0.5rem 1.5rem !important;
            }
            &.list-item {
               position: relative;
               .menu-icon {
                  font-size: 1.25rem;
               }
               span.menu {
                  font-size: 0.875rem;
               }
               &:after {
                  position: absolute;
                  content: "\f2fb";
                  font-family: Material-Design-Iconic-Font;
                  right: 1.5rem;
                  top: 0;
                  bottom: 0;
                  display: flex;
                  align-items: center;
                  transform: rotate(0);
                  transition: all 0.2s ease-in-out;
               }
               &.item-active {
                  background-color: rgba(0, 0, 0, 0.2);
                  &:after {
                     transform: rotate(90deg);
                     color: #000;
                  }
                  span.menu {
                     font-weight: $fw-semi-bold;
                  }
               }
            }
         }
         >li:not(.side-title) {
            padding: 1rem 1.5rem;
         }
         >li:not(.list-item) {
            padding: 0;
            display: block;
            a {
               display: block;
               padding: 1rem 1.5rem;
            }
         }
         .sub-menu {
            ul {
               li {
                  padding: 0;
                  display: block;
                  a {
                     display: block;
                     padding: 0.75rem 1.5rem 0.75rem 3.4rem;
                     text-transform: capitalize;
                     &.item-active {
                        font-weight: $fw-semi-bold;
                     }
                  }
                  &.list-item {
                     padding: 0.75rem 1.5rem 0.75rem 3.4rem;
                  }
               }
            }
         }
      }
   }
}

.dropdown {
   .dropdown-menu {
      transform: translate3d(0px, 50px, 0px);
      padding: 0;
   }
}

.rct-sidebar {
   .sidebar-overlay-dark {
      * {
         color: $white;
      }
   }
   .sidebar-overlay-light {
      *:not(button) {
         color: $sidebar-link-font-color;
      }
      .site-logo{
         background-color: $primary;
      }
   }

   .sidebar-overlay-gray {

      .preview-sidebar {
         *:not(button) {
            color: inherit;
         }
      }

      *:not(button) {
         color: $default;
      }
      .site-logo{
         background-color: $gray;
         min-height: 66px;
         position: relative;

         &:after {
            position: absolute;
            bottom: 0;
            height: 1px;
            background-color: #dcddde;
            content: '';
            width: calc(100% - 30px);
            right: 0;
            left: 0;
            margin: auto;
         }
      }
   }
}

.collapsed-sidebar {
   .rct-header {
      left: 0;
   }
   .rct-sidebar {
      width: 0;
   }
   .rct-app-content {
      width: 100%;
   }
}

.preview-sidebar  {
   .facet-wrapper & {
      padding: 0 !important;
   }
}

.facet-wrapper {
   .slider-wrapper {
      padding: 10px 0;
   }
}

/*========= Customizer ==========*/

.rct-customizer {
   width: $sidebar-width;
   overflow-x: hidden;
   header {
      background: $gray-900;
      color: $white;
   }
   .chat-area {
      .chat-head {
         @include border (1px solid, $border-color, top);
      }
      .chat-head,
      .chat-body {
         padding: 0.625rem; //10px;
      }
      .chat-body {
         .media-body {
            padding: 0.625rem !important;
            margin: 3px;
         }
      }
      .attachment {
         align-items: center;
         display: flex;
      }
      h3 {
         font-size: 14px;
         margin: 0;
      }
      .send-icons {
         ul {
            >li {
               &:nth-last-of-type(-n+2) {
                  display: none;
               }
            }
         }
      }
      .chat-footer {
         padding: 0;
      }
   }
   .chat-sidebar {
      .badge-xs {
         padding: 5px;
      }
      .media-body {
         h5 {
            font-size: 14px;
         }
      }
   }
   .panel-title {
      font-size: 14px;
      font-weight: bold;
      margin: 10px;
   }
}

.panel {
   &-title {
      .expansion-panel & {
         display: flex;
         align-items: baseline;
      }
   }

   &-badge {
      .expansion-panel & {
         margin-left: 5px;
      }
      
      .MuiBadge-badge {
         .expansion-panel & {
            position: relative;
            color: #fff;
            font-size: $fz-10;
            padding: 0px 5px;

            transform: none;
         }
      }
   }
}

.rct {
   &-scroll {
      .sidebar-ranking-mer &,
      .sidebar-category-mer & {
         @media (max-width: 1280px) {
            margin-top: 0px;
         }
      }

     &-gray {
       background-color: #fcfcfc;
       border-radius: 4px;
     }

     &.customized {
        @media (max-width: 1280px) {
           height: calc(100vh - 180px) !important;
        }
     }
   }

   &-expansion {
      position: relative;
   }


   &-sidebar {
      &:not(.sidebar-scroll-secondary) {
         .rct-scroll {
            margin-right: -10px;
            margin-left: -10px;
            width: auto !important;
         }
      }
      .rct-scroll {
         .pane-title {
            .sidebar-title {
               padding-left: 0 !important;
            }
         }

         .sidebar-title {
            padding-left: 15px;

            & + .list-wrapper {
               .MuiListItem-button {
                  padding-left: 25px !important;
              }
            }
        }

         .tree-wrapper {
            margin: 0;
         }
      }
   
      &-wrap {
         position: relative;

         .sidebar-scroll-secondary & {
            padding: 0;
         }

         .sidebar-scroll-secondary.sidebar-navigation-mer &,
         .sidebar-scroll-secondary.sidebar-ranking-mer &,
         .sidebar-scroll-secondary.sidebar-category-mer & {
            padding: 10px;
         }

         .preview-sidebar {
            .sidebar-scroll-secondary & {
               padding: 10px;
            }
         }

         .rct-scroll {
            &:before,
            &:after {
               content: '';
               pointer-events: none;
               height: 10px;
               width: 100%;
               position: absolute;
               left: 0;
               right: 0;
               z-index: 10;
            }
   
            &:before {
               top: 0px;
               /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f9f9f9+0,f9f9f9+100&1+0,0+100 */
               background: -moz-linear-gradient(top,  rgba(249,249,249,1) 0%, rgba(249,249,249,0) 100%); /* FF3.6-15 */
               background: -webkit-linear-gradient(top,  rgba(249,249,249,1) 0%,rgba(249,249,249,0) 100%); /* Chrome10-25,Safari5.1-6 */
               background: linear-gradient(to bottom,  rgba(249,249,249,1) 0%,rgba(249,249,249,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
               filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9', endColorstr='#00f9f9f9',GradientType=0 ); /* IE6-9 */
            }
    
            &:after {
               bottom: 0px;
               /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f9f9f9+0,f9f9f9+100&0+0,1+100 */
               background: -moz-linear-gradient(top,  rgba(249,249,249,0) 0%, rgba(249,249,249,1) 100%); /* FF3.6-15 */
               background: -webkit-linear-gradient(top,  rgba(249,249,249,0) 0%,rgba(249,249,249,1) 100%); /* Chrome10-25,Safari5.1-6 */
               background: linear-gradient(to bottom,  rgba(249,249,249,0) 0%,rgba(249,249,249,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
               filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9', endColorstr='#f9f9f9',GradientType=0 ); /* IE6-9 */
               
            }

            &.rct-scroll-expansion {
               &:after {
                  bottom: 0px;
               }
            }
         }
      }
   }
 }

 .pane-title {
   display: flex;
   align-items: baseline;
   justify-content: space-between;
   width: 100%;
 }

.preview-sidebar {
   .sidebar-sticky-item & {
      padding-right: 0 !important;
   }
}
@media(max-width:1199px){
   .rct-sidebar-wrap,.rct-page{
      .rct-scroll{
         >div:first-child{
            margin-bottom: 0 !important;
            padding-bottom: 65px !important;
         }
      }
   }
   .app-boxed{
      .rct-page{
         .rct-scroll{
            >div:first-child{
               padding-bottom: 0px !important;
            }
         }
      }
   }
}
.new-item{
   height:20px !important;
   background-color:#e53935 !important;
   position: absolute;   
   right:40px;
   span{
      padding: 0 9px !important;
      color:#fff !important;
      text-transform: capitalize;
   }
   &:hover{
      span{
         color: #fff !important;
      }
   }
}

.list-vertical-wrapper {
   width: 100%;
   div {
      white-space: initial;
      span {
         line-height: 15px !important;
      }
   }
}
