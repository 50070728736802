.checkbox {
  &-wrapper {
    label {
      margin: 0;
      justify-content: flex-start;
    }

    [class^="MuiButtonBase-root-"],
    [class*="MuiButtonBase-root-"] {
      margin-top: 0;
      padding: 6px 0 7px;
    }
  }

  &-sm {
    .list-item-secondary & {
      margin-right: 8px;
    }
  }
}

.MuiCheckbox-root {
  .checkbox-sm & {
    padding: 0 !important;
  }
}

.MuiTypography-root {
  .checkbox-sm & {
    font-size: 12px;
    margin-left: 5px;
  }

  .checkbox-sm & {
    .facet-wrapper & {
      font-size: 12px;
      margin-left: 5px;
    }
  }
}

.MuiSvgIcon-root {
  .checkbox-sm & {
    font-size: 20px;

    .list-item-secondary & {
      font-size: 24px;
    }
  }
}
