.paper {
  &-wrapper {
    pointer-events: all;
    min-width: 250px;

    .checkbox-wrapper {
      margin-left: -4px;
    }

    .MuiFormControlLabel-label {
      margin-left: 5px;
    }

    .product-item-card-actions & {
      position: absolute;

      z-index: 1;

      min-width: 250px;
      cursor: default;
      
      .popper-right & {
        right: 0;
        left: auto;
      }
    }

    .MuiDialogActions-root {
      margin: 0;
      padding: 8px 4px;
    }
  }

  &-title {
    & > * {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .popper-md &,
    .popper-xs & {
      padding-bottom: 0;
    }
  }

  &-content {
    .popper-md &,
    .popper-xs & {
      padding-bottom: 0;
    }
  }

  &-actions {
    margin: 0 !important;
    padding: 8px 4px;
    background-color: #edf3ff;
  }

  &-action {
    &-close {
      position: absolute !important;

      top: 5px;
      right: 5px;
    }
  }
}

.popper {
  &-block {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &-actions {
     &-footer {
      position: relative;
      padding-left: 22px;
      width: 100%;

      .search-wrapper,
      .MuiInputBase-root,
      .MuiInputBase-input {
        max-width: 165px;
      }

      & + .MuiCircularProgress-root {
        width: 15px !important;
        height: 15px !important;
        margin-left: -20px;
      }
    }
  }
}