.divider_dahsed {
  background-color: transparent;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.12);
}

.gap_md {
  margin-top: 15px;
  margin-bottom: 15px;
}

.divider {
  &-dashed {
    @extend .divider_dahsed;
    
    &.MuiDivider-root {
      @extend .divider_dahsed;
    }
  }

  &-gap {
    &-md {
      @extend .gap_md;
      
      &.MuiDivider-root {
        @extend .gap_md;
      }
    }
  }
}