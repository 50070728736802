// stylelint-disable selector-no-qualifying-type

//
// Textual form controls
//

.MuiFormLabel-root {
  .last-group & {
    pointer-events: none;
  }
}

.last-group {
  position: relative;

  max-width: 40%;
  min-width: 300px;
  width: 100%;

  .MuiFormHelperText-root {
    right: 0;
    left: auto !important;
    
    span {
      white-space: nowrap;
    }
  }
}


[class*='MuiFormLabel-root'],
[class^='MuiFormLabel-root'] {
  .inner-label-hide & {
    display: none;
  }
}

.MuiFormControl {
  &-root {
    .form-group & {
      z-index: 3;
    }

    .pim-sidebar & {
      width: 100%;
    }

    .autocomplete-xs & {
      overflow: visible;
    }
  }
}

.checkbox-wrapper {
  &.inner-label-hide {
    .MuiFormControlLabel-label {
      display: none;
    }
  }

  .container-preview & {
    .MuiCheckbox-root {
      margin-top: 0;
    }
  }
}

[class*='MuiSelect-select'],
[class^='MuiSelect-select'] {
  &:focus {
    background-color: transparent !important;
  }
}

.title {
  &-wrapper {
    display: flex;
    align-items: baseline;

    .title-secondary {
      margin-left: 5px;
      font-size: 11px;
      color: #a2a2a2;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      flex: 0 1 100%;

      &:before {
        content: '(';
      }

      &:after {
        content: ')';
      }

      &:empty {
        pointer-events: none;
        display: none;
      }
    }
  }
}

.search-system {
  width: 100vw;
  max-width: 300px;
  
  @media (max-width: 1280px) {
    max-width: 250px;
  }
}

.users-info-message {
  @media (max-width: 1280px) {
    font-size: 10px !important;
  }
}

.form {
  &-dashed {
    padding-bottom: 20px;
    border-bottom: 1px dashed #e2e2e2;
  }

  &-label {
    min-width: 70px;
    margin-right: 10px;
    text-align: left;
    justify-content: flex-start !important;
  }

 
  &-group {
    &:nth-child(2) {
      .form-group-m & {
        max-width: 20%;

        min-width: 200px;
      }
    }

    &.min-w-s {
      .form-group-m & {
        width: 100%;
        max-width: 300px;
      }

      .block {
        .form-group-m & {
          width: auto !important;

          min-width: 160px;
          justify-content: center;
          min-width: 140px;
        }
      }

      & > * {
        .form-group-m & {
          display: flex;
          margin-left: auto;
          justify-content: flex-end;
        }
      }
    }

    .form-group-m & {
      margin: 0 20px !important;

      @media (max-width: 1280px) {
        margin: 0 10px !important;
      }
    }

    .form-inline & {
      margin-left: 10px;

      .actions-bar & {
        @media (max-width:1500px) {
          min-width: 150px;

          min-height: 24px;
        }
      }
    }

    &-inline {
      display: flex;
    }

    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      
      border-bottom: 1px dashed rgb(199, 199, 199);
      
      padding-bottom: 0 !important;

      & + .list-wrapper {
        .sidebar-scroll-secondary & {
          padding-top: 0;

          border-top: 1px dashed rgba(0, 0, 0, 0.12);
        }

        .MuiList-root {
          .sidebar-scroll-secondary & {
            padding-top: 0;
          }
        }
      }

      .sidebar-scroll-secondary & {
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 500;

        opacity: 0.6;

        & > * {
          color: #464D69 !important;
        }

        .title {
          font-size: 12px;

          color: #979aa9 !important;

          span {
            color: #979aa9;
          }
        }
      }

      &.no-border-bottom {
        border-bottom: 0;
      }

      .title {
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
      }
    }

    &-row {
      position: relative;
      margin-bottom: 4px;
      margin-top: 4px;

      & + .form-group-row {
        position: relative;

        &:before {
          position: absolute;
          content: '';

          height: 1px;
          width: 100%;

          top: -4px;

          background-color: #eaeaea;
        }

        .quick-filters & {
          margin-top: 4px;
        }
      }
    }

    &-card {
      position: relative;
      background-color: #f6f6f6;
      padding: 5px;
      border-radius: 5px;
      margin-top: 5px;
      margin-bottom: 5px;

      &-has-error {
        background-color: #ffebeb;
      }

      &-label {
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 5px;
        display: block;
      }
    }

    &.ml-0.mr-0 {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    &-disabled {
      pointer-events: none;
      opacity: 0.4;
    }

    & + .form-group {
      .form-group-cell & {
        margin-top: 15px !important;
      }
    }

    [class^='MuiInput-formControl-'],
    [class*='MuiInput-formControl-'] {
      .attribute-row & {
        margin-top: 0;
      }
    }

    &.ml-0 {
      margin-left: 0 !important;
    }

    &-m {
      margin: 0 -20px;
    }

    &-label {
      .attributes-row & {
        color: rgba(0, 0, 0, 0.54);
        padding: 0 0 20px;
        font-size: 12px;
      }
    }
  }

  &-inline {
    .popper-actions & {
      margin: 0px 10px 0px -10px;
    }
  
    &-divide {
      & + .form-inline-divide {
        position: relative;
        
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: auto;

          width: calc(100% - 40px);
          height: 1px;

          border-top: 1px dashed #e2e2e2;

        }
        margin-top: 20px;
        padding-top: 20px;
      }
    }
  }
  &-wrapper {
    display: flex;

  }
}

.quick {
  &-filter {
    &-item {
      .MuiButton-label {
        color: #333 !important;
      }

      &-active {
        background-color: rgb(229, 238, 255);
      }
    } 
  }
}

.filter {
  &-actions {
    margin: -8px -3px;
  }

  &-attributes-row {
    position: relative;
    z-index: 1;
    margin-top: 35px;

    @media (max-width: 1280px) {
     margin-top: 15px;
    }

    &:focus-within {
      z-index: 2;
    }
  }
}

.form-control {
  display: block;
  width: 100%;
  padding: $input-padding-y $input-padding-x;
  font-size: $font-size-base;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;

  &.has-error {
    border-color: $danger; 
    box-shadow: 0px 0px 0px 3px #ffdddd;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $danger; 
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $danger; 
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: $danger; 
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $danger; 
    }
  }

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @if $enable-rounded {
    // Manually use the if/else instead of the mixin to account for iOS override
    border-radius: $input-border-radius;
  } @else {
    // Otherwise undo the iOS default
    border-radius: 0;
  }

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus();

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }
}

select.form-control {
  &:not([size]):not([multiple]) {
    height: $input-height;
  }

  &:focus::-ms-value {
    // Suppress the nested default white text on blue background highlight given to
    // the selected option text when the (still closed) <select> receives focus
    // in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
    // match the appearance of the native widget.
    // See https://github.com/twbs/bootstrap/issues/19398.
    color: $input-color;
    background-color: $input-bg;
  }
}

// Make file inputs better match text inputs by forcing them to new lines.
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}


//
// Labels
//

// For use with horizontal and inline forms, when you need the label (or legend)
// text to align with the form controls.
.col-form-label {
  padding-top: calc(#{$input-padding-y} + #{$input-border-width});
  padding-bottom: calc(#{$input-padding-y} + #{$input-border-width});
  margin-bottom: 0; // Override the `<label>/<legend>` default
  font-size: inherit; // Override the `<legend>` default
  line-height: $input-line-height;
}

.col-form-label-lg {
  padding-top: calc(#{$input-padding-y-lg} + #{$input-border-width});
  padding-bottom: calc(#{$input-padding-y-lg} + #{$input-border-width});
  font-size: $font-size-lg;
  line-height: $input-line-height-lg;
}

.col-form-label-sm {
  padding-top: calc(#{$input-padding-y-sm} + #{$input-border-width});
  padding-bottom: calc(#{$input-padding-y-sm} + #{$input-border-width});
  font-size: $font-size-sm;
  line-height: $input-line-height-sm;
}


// Readonly controls as plain text
//
// Apply class to a readonly input to make it appear like regular plain
// text (without any border, background color, focus indicator)

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: $input-padding-y;
  padding-bottom: $input-padding-y;
  margin-bottom: 0; // match inputs if this class comes on inputs with default margins
  line-height: $input-line-height;
  background-color: transparent;
  border: solid transparent;
  border-width: $input-border-width 0;

  &.form-control-sm,
  &.form-control-lg {
    padding-right: 0;
    padding-left: 0;
  }
}


// Form control sizing
//
// Build on `.form-control` with modifier classes to decrease or increase the
// height and font-size of form controls.
//
// The `.form-group-* form-control` variations are sadly duplicated to avoid the
// issue documented in https://github.com/twbs/bootstrap/issues/15074.

.form-control-sm {
  padding: $input-padding-y-sm $input-padding-x-sm;
  font-size: $font-size-sm;
  line-height: $input-line-height-sm;
  @include border-radius($input-border-radius-sm);
}

select.form-control-sm {
  &:not([size]):not([multiple]) {
    height: $input-height-sm;
  }
}

.form-control-lg {
  padding: $input-padding-y-lg $input-padding-x-lg;
  font-size: $font-size-lg;
  line-height: $input-line-height-lg;
  @include border-radius($input-border-radius-lg);
}

select.form-control-lg {
  &:not([size]):not([multiple]) {
    height: $input-height-lg;
  }
}


// Form groups
//
// Designed to help with the organization and spacing of vertical forms. For
// horizontal forms, use the predefined grid classes.

.attributes-row {
  [class^="MuiButtonBase-root"],
  [class*="MuiButtonBase-root"] {
    margin-top: 35px;
  }

  .form-compact {
    margin: 0 -10px; 
    padding: 15px 0;

    @media (max-width: 1280px) {
      padding: 5px 0;
    }

    &.no-margin {
      margin: 0;
    }
  }

  & + .attributes-row {
    border-top: 1px dashed #e2e2e2;

    .form-group-label {
      display: none;
    }

    [class^="MuiButtonBase-root"],
    [class*="MuiButtonBase-root"] {
      margin-top: 0px;
    }
  }
}
body {
  & > .attributes-row {
    border-top: 1px dashed #e2e2e2 !important;
    border-bottom: 1px dashed #e2e2e2 !important;
    background-color: #fff !important;
    box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.22);

    .MuiButtonBase-root {
      margin-top: 0px !important;
    }
  }
}

.form-group {
  margin-bottom: $form-group-margin-bottom;
  & > * {
    .attributes-row & {
      width: 100%;
    }
  }

  &.sufix {
    & + .form-group {
      .popper-actions & {
        margin-bottom: 10px;
      }
    }
  }

  [class^="MuiInput-root"],
  [class*="MuiInput-root"] {
    .attributes-row & {
      width: 100%;
    }
  }

  .attributes-row & {
    flex: 1 !important;
    margin: 0 10px;
  }

  .with-margin & {
    margin: 10px;
  }
}

.form-text {
  display: block;
  margin-top: $form-text-margin-top;
}


// Form grid
//
// Special replacement for our grid system's `.row` for tighter form layouts.

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;

  > .col,
  > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
}


// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.

.form-check {
  position: relative;
  display: block;
  padding-left: $form-check-input-gutter;
}

[class^='MuiInput-underline'],
[class*='MuiInput-underline'] {
  &:before {
    .input-underline-hide &,
    .attributes-row & {
      content: none;
    }
    .with-errors & {
      border-color: $red;
    }
  }
}

[data-value*="options"],
[data-value^="options"] {
  justify-content: center !important;
  opacity: 0.4;
  pointer-events: none;
}

.select, .input {
  &-bordered {
    position: relative;
    padding: 0px 0px 0px 8px;

    &:after {
      content: '';
      height: 38px !important;
      display: block;
      bottom: 0;
      left: 0px;
      right: 0px;
      z-index: 2;
      position: absolute;
      border: 1px solid #c8c8c8;
      border-radius: 5px;
      pointer-events: none;

      .actions-bar & {
        height: 28px !important;
        top: -3px;
      }
    }

    label {
      padding: 0;
      transition: all 0.2s ease;
      transform: translate(0, 14.5px) scale(1);
      
      .actions-bar & {
        font-size: 13px;
        transform: translate(0, 4.5px) scale(1);
      }

      & + .MuiInput-formControl {
        margin-top: 10px;

        .actions-bar & {
          margin-top: 0;
        }
      }
    }

    &.field {
      &-secondary {
        &:after {
          top: 0;
        }
      }
    
      &-white {
        .MuiInputLabel-shrink {
          transform: translate(-3px, -4.5px) scale(0.75);
  
          background-color: #fff;
        }
      }
    }

    &.field-secondary {
      label {
        transform: translate(0, 11.5px) scale(1);

        & + .MuiInput-formControl {
          margin-top: 5px;
        }
      }
    }

    &.input-wrapper {
      label {
        transform: translate(10px, 11.5px) scale(1);
      }

      .MuiInputLabel-shrink {
        transform: translate(10px, -5px) scale(0.75);
      }
    }

    .MuiInput-input {
      padding-left: 10px;

      .actions-bar & {
        font-size: 12px;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-left: 1px;
      }
    }

    .MuiInput-underline:before,
    .MuiInput-underline:after {
      display: none !important;
    }

    .MuiInputLabel-shrink {
      transform: translate(-2px, -0.5px) scale(0.75);
      transform-origin: top left;
      z-index: 12;
      background-color: #eaeaea;
      padding: 0px 5px;
      color: grey !important;

      .actions-bar & {
        transform: translate(-2px, -6.5px) scale(0.65);
      }

    }
    
    &.input-text  {
      .MuiInputLabel-shrink {
        .actions-bar & {
          transform: translate(-7.5px, -6.5px) scale(0.65);
        }

      }

      .MuiInputBase-root {
        .actions-bar & {
          padding-left: 10px;
        }
      }
    }

    &.has-error {
      padding-right: 20px;

      &:after {
        border-color: red !important;
      }

      & + .select-suffix {
        margin-left: -12px;

        .dialog-footer & {
          margin-left: -22px;
        }
      }
    }
  }

  &-wrapper {
    .flex-child-auto & {
      width: 100%;
    }

    &-suffix {
      & > * {
        .paper-content & {
          padding-right: 60px;
        }

        .form-group-inline & {
          padding-right: 0;
        }
      }
    }

    &-prefix {
      .select-container {
        width: calc(100% - 90px) !important;
        align-self: flex-end;
      }

      &.select-wrapper-suffix {
        .select-container {
          // width: 55% !important;
          margin-right: 40px;
          margin-left: 20px;
        }
      }

      label {
        & + .select-prefix {
          margin-top: 16px;

          & + [class^='MuiInputBase-root'],
          & + [class*='MuiInputBase-root'] {
            margin-top: 16px;
          }
        }
      }
    }
  }

  &-suffix,
  &-prefix {
    position: absolute;
    left: 0;
    width: calc(100% - 55% - 45px);
    
    [class^='MuiInputBase-root'],
    [class*='MuiInputBase-root'] {
      width: 100% !important;
    }
  }

  &-prefix {
    pointer-events: none;
  }
  
  &-suffix {
    top: 0;
    left: auto;
    right: 0;
    width: 38px;

    .search-header-input & {
      bottom: 0;
      margin: auto;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

[class^='MuiInput-input'],
[class*='MuiInputBase-input'] {
  .select-container & {
    min-height: 20px;
  }
}

[class^='MuiSelect-selectMenu'],
[class*='MuiInput-selectMenu'] {
  .select-prefix & {
    padding-right: 20px;
  }
}

[class^='MuiInput-underline'],
[class*='MuiInput-underline'],
[class^='MuiInput-underline'],
[class*='MuiInput-underline'] {
  &:before {
    .select-underline-hide &,
    .attributes-row & {
      content: none !important;
    }
  }
}
label {
  & + .MuiInput-formControl {
    .product-dialog & {
      margin-top: 10px;
    }
  }
}


[class^='MuiInputBase-root'],
[class*='MuiInputBase-root'] {
  table & {
    width: 100%;
  }

  .flex-child-auto & {
    width: 100%;
  }
}

[class^='MuiSelect-select'],
[class*='MuiSelect-select'],
[class^='MuiInput-input'],
[class*='MuiInput-input'] {
  .attributes-row &,
  .table-responsive & {
    font-size: 14px;
  }
  
  em {
    .attributes-row &,
    .table-responsive & {
      opacity: 0.6;
    }
  }
}

.MuiOutlinedInput-input {
  .field-small & {
    padding: 10px;
  }

  .search-input-lg & {
    padding: 10px;
  }
}

.MuiInputLabel-outlined {
  .field-small & {
    transform: translate(14px, 11px) scale(1);
  }
}

.form {
  &-footer {
    padding-top: 20px;
    border-top: 1px dashed #e2e2e2;
  }

  &-group {
    &-filter {
      .MuiInputLabel-disabled,
      .MuiSelect-disabled {
        opacity: 0.3 !important;
      }
    }

    &-last {
      flex: 0 1 0%;

      display: flex;
      justify-content: flex-end;
    }
  }

  &-compact {
    .table-xs & {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.system-preview-attributes-label {
  .table-xs & {
    padding-bottom: 10px;
  }
}

.form-check-input {
  position: absolute;
  margin-top: $form-check-input-margin-y;
  margin-left: -$form-check-input-gutter;

  &:disabled ~ .form-check-label {
    color: $text-muted;
  }
}

.form-check-label {
  margin-bottom: 0; // Override default `<label>` bottom margin
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0; // Override base .form-check
  margin-right: $form-check-inline-margin-x;

  // Undo .form-check-input defaults and add some `margin-right`.
  .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: $form-check-inline-input-margin-x;
    margin-left: 0;
  }
}


// Form validation
//
// Provide feedback to users when form field values are valid or invalid. Works
// primarily for client-side validation via scoped `:invalid` and `:valid`
// pseudo-classes but also includes `.is-invalid` and `.is-valid` classes for
// server side validation.

@include form-validation-state("valid", $form-feedback-valid-color);
@include form-validation-state("invalid", $form-feedback-invalid-color);

// Inline forms
//
// Make forms appear inline(-block) by adding the `.form-inline` class. Inline
// forms begin stacked on extra small (mobile) devices and then go inline when
// viewports reach <768px.
//
// Requires wrapping inputs and labels with `.form-group` for proper display of
// default HTML form controls and our custom form controls (e.g., input groups).

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; // Prevent shorter elements from growing to same height as others (e.g., small buttons growing to normal sized button height)

  // Because we use flex, the initial sizing of checkboxes is collapsed and
  // doesn't occupy the full-width (which is what we want for xs grid tier),
  // so we force that here.
  .form-check {
    width: 100%;
  }

  // Kick in the inline
  @include media-breakpoint-up(sm) {
    label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
    }

    // Inline-block all the things for "inline"
    .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0;

      .popper-actions-footer & {
        flex: 1 0 0%;
      }
    }

    // Allow folks to *not* use `.form-group`
    .form-control {
      display: inline-block;
      width: auto; // Prevent labels from stacking above inputs in `.form-group`
      vertical-align: middle;
    }

    // Make static controls behave like regular ones
    .form-control-plaintext {
      display: inline-block;
    }

    .input-group {
      width: auto;
    }

    // Remove default margin on radios/checkboxes that were used for stacking, and
    // then undo the floating of radios and checkboxes to match.
    .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0;
    }
    .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: $form-check-input-margin-x;
      margin-left: 0;
    }

    .custom-control {
      align-items: center;
      justify-content: center;
    }
    .custom-control-label {
      margin-bottom: 0;
    }
  }
}

.system-preview-attributes-label {
  .form-group-label {
    width: 50%;
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
  }
}

.text-input-lg {
  &.select-wrapper-suffix {
    margin-right: 40px;

    &.input-padding-clear {
      margin-right: 0;
    }
  }
  &.select-wrapper-prefix {
    margin-left: 20px;
    align-self: flex-end;
    width: calc(100% - 90px) !important;
    margin-left: auto;
    padding-left: 25px;

    .group-sm & {
      width: 100% !important;
    }

    .product-dialog & {
      padding-left: 0;
    }
  }
}

.group {
  &-sm {
    & + .group-sm {
      margin-top: 15px;
    }
  }
}
