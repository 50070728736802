  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    .form-group-card  & {
      border: 1px solid #ffffff00;
      -webkit-text-fill-color: #464d69;
      -webkit-box-shadow: 0 0 0px 1000px #f6f6f6 inset;
      transition: background-color 5000s ease-in-out 0s;
      border: 0;
    }
}

.text-input-lg {
  width: 100%;
}

[class^="MuiSelect-disabled"],
[class*="MuiSelect-disabled"],
[class^="MuiInputBase-formControl"],
[class*="MuiInputBase-formControl"],
[class^="MuiInputBase-selectMenu"],
[class*="MuiInputBase-selectMenu"] {
  .select-label & {
    padding-right: 0;
    pointer-events: none;
  }
}

[class^="MuiInputBase-formControl"],
[class*="MuiInputBase-formControl"],
[class^="MuiInputBase-selectMenu"],
[class*="MuiInputBase-selectMenu"],
[class^="MuiInputBase-disabled"],
[class*="MuiInputBase-disabled"] {
  &:before {
    .select-clear & {
      border-bottom-width: 0;
    }
  }

  .select-label & {
    svg {
      display: none;
    }
  }
}

hr {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

[class^="-disabled"],
[class*="-disabled"] {
  &:not(.transfer-list-item-disabled) {
    pointer-events: none;
  }
}

.MuiSelect-select {
  .select-multiple-wrapper & {
    white-space: normal;
    max-width: 300px;
  }
}

.select {
  &-suffix {
    .actions-bar & {
      display: flex;
      align-items: center;
      justify-content: center;

      left: auto;
      right: 0;

      font-size: 12px;

      min-height: 20px;
      min-width: 20px;
      width: auto;

      margin-left: 0 !important;

      z-index: 5;
    }

    .product-edit-wrapper & {
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: $fz-16;
    }
  }

  &-products {
    .flex-grid-calc-160 {
      width: 100%;

      position: absolute;
      left: 0;
      right: 0;
      padding-left: 150px;
    }
  }

  &-prefix {
    &.prefix-abs {
      position: absolute;
      left: -16px;
      margin: 0 !important;
      bottom: 15px;
    }

    .group-sm & {
      pointer-events: all !important;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 20px;
      height: 20px;

      left: -25px;
      bottom: 0;
      top: 0;
      
      margin: auto;
    }
  }

  &-btn {
    padding: 5px 5px 5px 15px;
    border-radius: 58px;

    &.select {
      &-xs {
        padding: 0;
  
        .MuiSelect-selectMenu {
          font-size: 11px;
  
          padding-right: 15px;
          top: calc(50% - 10px);
        }
  
        .MuiSelect-icon {
          font-size: 16px;
  
          top: calc(50% - 10px);
        }
  
        .MuiInputBase-input {
          font-size: 11px;
          padding-top: 14px;
          padding-bottom: 0;

          line-height: 1.1;
        }
      }

      &-sm {
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }
    
    &-default {
      background-color: #ebebeb;
      
      &.has-error {
        background-color: #feebeb;
      }
    }

    &-primary {
      background-color: $primary;
    }

    label {
      & + .MuiInput-formControl {
        margin-top: 0;
      }
    }
    .MuiInput-underline {
      &:after,
      &:before {
        display: none;
        content: none;
      }
    }

    .MuiInputLabel-formControl {
      margin: 0;
      font-size: 12px;

      transform: translate(0, 7px) scale(1);
    }

    .MuiInputLabel-shrink,
    .MuiFormLabel-root.MuiFormLabel-focused {
      opacity: 0.6;
      transform: translate(0px, 0px) scale(0.8);
    }

    .MuiSelect-selectMenu {
      font-size: 13px;

      padding-bottom: 0;
      padding-top: 10px;
    }
  }

  &-label {
    &-up {
      transform: translate(0, -0.5px) scale(0.75) !important;
      transform-origin: top left !important;

      .autocomplete-xs & {
        transform: translate(0, 0px) scale(0.75) !important;
      }
    }

    &-down {
      top: 0;
      left: 0;
      transform: translate(0, 10px) scale(1);
      justify-content: flex-start;
    }
  }

  &-autocomplete {
    &-wrapper {
      .form-group & {
        padding: 0;
      }

      [class^="-singleValue"],
      [class*="-singleValue"] {
        margin: 0;
      }

      & > * {
        background-color: transparent !important;
      }

      [class^="-menu"]:focus,
      [class*="-menu"]:hover,
      [class^="-menu"],
      [class*="-menu"] {
        z-index: 10;
        background-color: #fff !important;
        border-radius: 5px;
        box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.3) !important;

        .form-group & {
          z-index: 10;
          background-color: #fff !important;
          border-radius: 5px;
          box-shadow: 0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.3) !important;
        }
      }
    }
  }
}

.MuiInput-underline {
  &:before {
    .has-error & {
      border-color: $danger;
    }
  }
}

.MuiSelect {
  &-select {
    .select-sm & {
      padding-top: 10.5px;
      padding-bottom: 10.5px;
    }
  }

  &-selectMenu {
    .select-wrap & {
      white-space: normal;
    }
  
    .actions-bar & {
      min-height: 24px;
    }
  }
}

label {
  & + .MuiInput-formControl {
    &.select-xs {
      margin-top: 8px;
      margin-bottom: -12px;
    }

    .autocomplete-sm & {
      margin-top: 10px;
    }

    .autocomplete-xs & {
      margin-top: 0px;

      &:after {
        content: none;
      }
    }
  }
}

[class^="-menu"],
[class*="-menu"] {
  .pim-sidebar & {
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 2px 2px 15px -2px rgba(0, 0, 0, 0.3);
  }

  .facet-group-secondary:not(:first-child) & {
    top: auto;
    bottom: 100%;

    z-index: 10;
  }

  .rel-right & {
    right: 0;
    left: auto;
  }

  .dropdown & {
    min-width: 200px;

    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    box-shadow: 2px 2px 15px -2px rgba(0, 0, 0, 0.3) !important;

    z-index: 2;
  }

  .dropup & {
    top: auto;
    bottom: 20px;
    min-width: 200px;

    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
    box-shadow: 2px 2px 15px -2px rgba(0, 0, 0, 0.3) !important;

    z-index: 2;
  }
  
  [class^="-Group"],
  [class*="-Group"] {
    .custom-group & {
      padding-top: 0;
      padding-bottom: 0;
    }

    &:nth-child(2) {
      .custom-group & {
        opacity: 0.6;
      }

      [class^="-option"],
      [class*="-option"] {
        .custom-group & {
          font-size: 12px;
        }
      }
    }
  }

  [id^="-heading"],
  [id*="-heading"] {
    .custom-group & {
      margin: 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}

[class^="-option"],
[class*="-option"] {     
  &:empty {
    display: none;
  }
}

.Mui-disabled {
  opacity: 0.5;
}

.react-single-wrap {
  .autocomplete-outlined & {
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.23);

    border-radius: 4px;
  }

  .autocomplete-select-wrapper.autocomplete-outlined & {
    padding: 4.5px 0px 4.5px 13px !important;
  }
}

.MuiFormLabel-root {
  .autocomplete-outlined & {
    transform: translate(10px, -4.5px) scale(0.75) !important;
    transform-origin: top left !important;
    background-color: #fff;
    padding: 0px 5px !important;

    opacity: 1;
  }
}

.autocomplete {
  &-single {
    .last-group & {
      margin-top: -11px;
    }
  }

  &-helper {
    &-text {
      .autocomplete-error & {
        color: $danger !important;
      }
    }
  }

  &-xs {
    padding: 0;

    .MuiInputLabel {
      &-formControl {
        font-size: 12px;
        opacity: 0.7;
        transform: translate(0, 7px) scale(1);
      }

      &-shrink {
        transform: translate(0, 0px) scale(0.75);
      }
    }

    #react-select-react-select-single-input {
      top: 7px;
      font-size: 11px !important;
      position: absolute;
      left: 0;
    }

    .btn-ico-sm {
      padding: 0px !important;

      min-width: 20px;
      min-height: 20px;
    }

    .auto-select-wrap {
      &:before {
        content: none;
      }
    }

    .react-single-wrap {
      padding: 0;
    }

    [class^='-control'],
    [class*='-control'] {
      min-height: 20px !important;
      background-color: transparent;

      border: 0 !important;
      box-shadow: none !important;

      [class^='-singleValue'],
      [class*='-singleValue'] {
        margin: 0;
        font-size: 11px;
        margin: 3px 0 0;

        max-width: calc(100% - 5px);
      }

      [class^='-indicatorContainer'],
      [class*='-indicatorContainer'] {
        padding: 0px;
        transform: scale(0.75);
      }
    }
  }

  &-sm {
    padding: 0;

    .MuiInputLabel {
      &-formControl {
        transform: translate(0, 18px) scale(1);
      }

      &-shrink {
        transform: translate(0, 1.5px) scale(0.75);
      }
    }

    .btn-ico-sm {
      padding: 3px !important;

      min-width: 22px;
      min-height: 22px;
    }

    .react-single-wrap {
      padding: 0;
    }

    [class^='-control'],
    [class*='-control'] {
      min-height: 20px;

      [class^='-singleValue'],
      [class*='-singleValue'] {
        margin: 0;
      }

      [class^='-indicatorContainer'],
      [class*='-indicatorContainer'] {
        padding: 4px;
      }
    }
  }

  &-select {
    &-wrapper {
      position: relative;
      z-index: 1;
      
      &:focus-within {
        z-index: 2;
      }

      & + .autocomplete-select-wrapper {
        margin-top: 10px;

        .react-single-wrap {
          .form-compact & {
            padding-top: 0 !important;
          }
        }

        .form-compact & {
          margin-top: 0;
        }

        .drag-list-item &,
        .autocomplete-inline & {
          margin-top: 0;
        }
      }

      &.autocomplete-sm {
        .suffix,
        .prefix {
          min-height: 25px;
        }
      }
    
      .suffix {
        padding-left: 15px;

        .product-dialog & {
          min-width: 38px;
        }

        &-abs {
          position: absolute;
          right: 30px;
          top: 7px;
          margin: auto;
          background-color: #fff;
          z-index: 10;
          padding: 4px !important;
          line-height: 0;
        }
      }
      
      .prefix {
        padding-right: 15px;
        min-height: 36px;

        .form-compact & {
          padding-right: 0;
        }

        &-abs {
          position: absolute;

          left: -25px;
        }
      }

      [class*="-singleValue"] {
        .option-abbreviature {
          display: none;
        }
        .option-component {
          &.disabled {
            opacity: 1;
            pointer-events: all;
          }
        }
      }

      .option-component {
        flex-wrap: wrap;
        font-family: "Heebo", sans-serif;
        font-weight: 400;
        color: #464D69;

        & > div {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .option-abbreviature {
          margin-left: 5px;
          color: #adb5bd;
          white-space: normal;
          text-transform: lowercase;
        }
      }
    }

    &-multiple {
      &-wrapper {
        position: relative;
    
        display: flex;
        flex-direction: column-reverse;
    
        [class^="-multiValue"],
        [class*="-multiValue"] {
          content: '';
        }
    
        [class^="-control"],
        [class*="-control"] {
          min-height: 28px;

          .facet-group & {
            min-height: 38px;
          }

          & > * {
            &:first-child {
              padding: 5px 0px 0px;

              .drag-list-card & {
                padding: 0;
              }
            }
          }
        }
    
        &.autocomplete-xs {
          [class^="-IndicatorsContainer"],
          [class*="-IndicatorsContainer"] {
            top: 0px;
          }

          [class^="-control"],
          [class*="-control"] {
            border-color: transparent !important;
            box-shadow: 0 0 0 transparent !important;

            & > * {
              &:first-child {
                padding-left: 0;
                margin-left: -2px;
              }
            }
          }

          [class^='-Input'],
          [class*='-Input'] {
            top: -3px;
            
            input {
              top: 0px;

              font-size: 11px !important;
            }
          }
        }

        [class^="-IndicatorsContainer"],
        [class*="-IndicatorsContainer"] {
          position: absolute;
          top: -18px;
          right: 0;

          display: none;
          pointer-events: none;

          .facet-group &,
          #select-product-attribute-wrapper & {
            top: 0;
          }

          .pim-sidebar & {
            right: -7px;
            top: -2px;
          }
    
          &:hover {
            color: #5d92f4;
          }
        }
    
        [class^="-indicatorContainer"],
        [class*="-indicatorContainer"] {
          padding: 3px;
          font-size: 12px;
        }
    
        &.clear-abs {
          [class^="-indicatorContainer"],
          [class*="-indicatorContainer"] {
            padding: 3px;
      
            &:hover {
              color: #5d92f4;
              cursor: pointer;
            }
    
            &:first-child {
              svg {
                display: none;
              }

              .pim-sidebar & {
                position: absolute;

                white-space: nowrap;

                top: -13px;
                right: 0px;
              }
              
              &:after {
                pointer-events: none;
                content: none;
              }
            }
          }
        }
      }
    
      &-label {
        position: absolute;
    
        pointer-events: none;
    
        color: rgba(0, 0, 0, 0.54);
        transform: translate(0, -5px) scale(1);
        transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;

        .select-product-attribute-wrapper &,
        #select-product-attribute-wrapper & {
          transform: translate(0, -11px) scale(1);
        }
    
        .has-value & {
          transform: translate(0, -24px) scale(0.75);
          transform-origin: top left;
          color: #7c7c7c;
          top: 10px;
        }

        .has-value.autocomplete-value-xs & {
          transform: translate(0, -14px) scale(0.75) !important;
        }
        
        .autocomplete-xs & {
          opacity: 0.7;
          font-size: 12px;
        }

        .has-value.autocomplete-xs & {
          transform: translate(0, -16px) scale(0.75);
        }
      }
    }
  }
}

[class^="-IndicatorsContainer"],
[class*="-IndicatorsContainer"] {
  .clear-abs & {
    .pim-sidebar & {
      position: absolute;
      right: 0px;
      top: -2px;
    }
  }
}

[class^="-indicatorSeparator"],
[class*="-indicatorSeparator"] {
  display: none;
}

[class^="-control"],
[class*="-control"] {
  & > * {
    &:first-child {
      .autocomplete-select-multiple-wrapper.autocomplete-xs {
        padding-left: 0;
      }
    }
  }

  .clear-abs & {
    border-width: 0;
    border-radius: 0;
    border-bottom: 1px solid #949494;
    box-shadow: 0 0 0 0 transparent;
  }

  .disabled & {
    background-color: transparent;
  }

  #select-product-attribute-wrapper & {
    border-bottom: 0 !important;
  }

  & > * {
    &:first-child {
      .clear-abs & {
        margin: 0 -6px;
      }
    }
  }

  &:hover {
    .clear-abs & {
      border-bottom: 2px solid rgba(0, 0, 0, 0.87);
      margin-bottom: -1px;
    }
  }
}

[class^="-indicatorSeparator"],
[class*="-indicatorSeparator"],
[class^="-indicatorContainer"]:last-child,
[class*="-indicatorContainer"]:last-child {
  .hide-arrow & {
    display: none;
  }
}

[class^="-container"],
[class*="-container"] {
  &:focus-within {
    & + .autocomplete-select-multiple-label {
      transform: translate(0, -24px) scale(0.75);
      transform-origin: top left;
      color: $primary;
      
      .autocomplete-xs &,
      .has-value.autocomplete-xs & {
        transform: translate(0, -16px) scale(0.75);
      }

      #select-product-attribute-wrapper & {
        transform: translate(0, -24px) scale(0.75);
      }

    }
  }
}

[class^="-control"],
[class*="-control"] {
  .clear-abs & {
    padding-right: 15px;
  }
}

[class^="-indicatorSeparator"],
[class*="-indicatorSeparator"],
[class^="-placeholder"],
[class*="-placeholder"] {
  .clear-abs &,
  .hide-placeholder & {
    display: none;
  }
}

[class^="-multiValue"],
[class*="-multiValue"] {
  & > * {
    &:first-child {
      .autocomplete-xs &,
      .item-xs & {
        font-size: 70%;
        padding: 1px 1px 1px 4px;
      }

      .item-xs & {
        font-size: 80%;
      }

      .disabled & {
        width: 100%;
        padding: 3px 7px;
      }
    }

    &:last-child {
      .autocomplete-xs &,
      .item-xs & {
        padding-left: 1px;
        padding-right: 1px;
      }

      .disabled & {
        display: none;
      }
    }
  }

  .autocomplete-value-xs & {
    display: flex;
    align-items: center;
    padding: 1px 0px 0px 4px !important;
    font-size: 14px;
  }
}

#select-product-attribute-wrapper {
  .MuiList-root {
    padding: 0 10px;
  }
}

.select-autocomplete-wrapper,
.select-wrapper {
  &.MuiInputBase-input {
    height: auto;
  }
}

[class^='-control'],
[class*='-control'] {
  & > div {
    &:first-child {
      .select-autocomplete-wrapper &,
      .select-wrapper &,
      .react-single-wrap.select-wrapper & {
        padding: 0;
      }
    }
  }
}

[class^='-control'],
[class*='-control'] {
  .MuiDialog-root &,
  .rct-page-content & {
    box-shadow: none !important;
  }

  input {
    .autocomplete-xs & {
      font-size: 11px !important;
    }
  }
}

.autocomplete {
  &-select {
    &-control {
      &-wrapper {
        &.focus {
          [class^="-control"],
          [class*="-control"] {
            border-color: #5D92F4 !important;

            &:hover {
              border-color: #5D92F4 !important;
            }
          }
        }

        [class^="-control"],
        [class*="-control"] {
          border-radius: 0 !important;
          border: 0 !important;
          border-bottom: 1px solid rgb(255, 249, 249) !important;
          background-color: transparent !important;
          transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          align-items: flex-end !important;
          box-sizing: border-box !important;

          .dialog-facet-create & {
            border-bottom: 1px solid #9e9e9e !important;
          }

          .facet-grouped-values-type-wrapper & {
            align-items: center !important;
          }

          .drag-list-card & {
            border-radius: 5px !important;
            transition: border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

            padding: 0 10px;

            border: 1px solid transparent !important;
          }

          .autocomplete-error & {
            border-color: $danger !important;
            box-shadow: 0 1px 0 $danger !important;
          }

          .autocomplete-xs & {
            .pim-sidebar & {
              border-bottom: 0 !important;
            }
          }

          &:hover {
            border-color: rgba(0, 0, 0, 0.87) !important;
          }

          .product-dialog & {
            border-bottom: 0 !important;
          }
        }

        &.autocomplete-error {
          [class^="-control"],
          [class*="-control"] {
            border-color: $danger !important;
            box-shadow: 0 1px 0 $danger !important;
          }
        }

        .select-wrapper,
        .select-autocomplete-wrapper {
          padding: 8px 0 7px !important;

          box-shadow: 0 0 0 0 !important;

          .dialog-content-visible & {
            padding: 0 7px !important;
          }

          .drag-list-card & {
            padding: 0 !important;
          }
        }

        [class^="-MenuList"],
        [class*="-MenuList"] {
          padding: 10px !important;

          .filter-attributes-row & {
            padding: 2px !important;
          }
          
          .autocomplete-xs & {
            .pim-sidebar & {
              padding: 2px !important;
            }
          }
        }

        [class^="-option"],
        [class*="-option"] {
          padding: 5px !important;
        }

        [class^="-Input"],
        [class*="-Input"] {
          margin-left: 0;

          .autocomplete-xs & {
            font-size: 10px;
            margin-top: 10px;

            .pim-sidebar & {
              margin-top: 8px;
              font-size: 10px;
            }
          }
        }

        [class^="css-"],
        [class*="css-"] {
          padding: 0;
        }

        .info-option {
          white-space: pre-wrap;
          border-top: 1px solid hsl(0,0%,80%);
        }
      }
    }

    &-wrapper {
      .attributes-group-items & {
        padding-top: 15px;
        align-items: center;
      }
    }

    &-multiple {
      &-wrapper {
        [class^="css-"],
        [class*="css-"] {
          margin: 0 !important;

          &[class^="-multiValue"],
          &[class*="-multiValue"] {
            margin: 1px !important;
          }
        }

        & > [class^="css-"],
        & > [class*="css-"] {
          .facet-group & {
            padding: 8px 0 7px;

            .facet-grouped-values-type-wrapper & {
              .dialog-facet-create & {
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}

.action-bar-input {
  margin-right: 3px;
}

.action-bar-text {
  margin-right: 3px;
  min-width: 180px;
  height: 22px;
  min-height: 30px;

  .input-text {
    margin-left: 0;
  }
}

.action-bar-text {
  .MuiFormHelperText-root {
    bottom: -20px !important;
  }
}

.action-bar-autocomplete {
  margin-right: 3px;
  margin-top: 0 !important;
  border: 0 !important;
  padding: 15px 0;
  min-width: 200px;
  min-height: 30px;

  .actions-bar & {
    padding: 0;
  }

  .Select-menu-outer {
    top: auto !important;
    bottom: 100% !important;
  }

  .MuiFormHelperText-root {
    position: absolute;
    bottom: -5px;
    color: $danger;
  }

  .top-oriented {
    padding: 0 !important;
  }

  .MuiInputLabel-formControl {
    transform: translate(0, 4px) scale(1);
  }

  [class^='-singleValue'],
  [class*='-singleValue'] {
    font-size: 12px;
    color: currentColor;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 7px;
  }

  .select-label-up {
    transform: translate(0, -5.5px) scale(0.75) !important;
    background-color: #eaeaea;
    z-index: 12 !important;
    color: gray !important;
    transform-origin: top left;
    padding: 0 2px;
  }
  
  .MuiInputLabel-formControl {
    font-size: 13px;
    z-index: 2;
    margin-left: 8px;
  }
  
  [class^='-control'],
  [class*='-control'] {
    &:not(.autocomplete-select-control-wrapper) {
      width: 100%;
      height: 22px;
      min-height: auto;
      border-bottom: 0 !important;
  
      &:hover {
        border-bottom: 0 !important;
      }
  
      [class^='-indicatorContainer'],
      [class*='-indicatorContainer'] {
        width: 16px;
        height: 20px;
        margin-right: 5px;
      }

      [class^='-Input'],
      [class*='-Input'] {
        padding-left: 10px;
      }
    }
  }
}

.autocomplete-select-control-wrapper {
  [class*="-control"] {
    box-shadow: none !important;
  }
}

.select-remove-div {
  color: #A9203E;
  position: absolute;
  cursor: pointer;
  z-index: 1000;
  left: -10px;
  top: 8px;
}
