.product {
  &-haeder,
  &-heading {
    padding: 10px 0;
    border-bottom: 1px solid #eaeaea;
    border-left: 1px solid #eaeaea;
  }

  &-icon-lock {
    position: absolute;

    left: 15px !important;
    bottom: 0;
    top: 0;

    margin: auto;

    align-items: center;
    display: flex;

    & + .MuiOutlinedInput-root {
      .MuiSelect-outlined.MuiSelect-outlined {
        padding-left: 32px;
      }
    }
  }

  &-header {
    position: relative;
    z-index: 1;
  }

  &-heading {
    .preview & {
      border-left: 0;
      border-bottom: 0;

      box-shadow: 0px 2px 10px 0px #dcddde;

      z-index: 2;
    }
  }

  &-item-type {
    &.parent {
      height: 120px;
      writing-mode: vertical-lr;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 128, 0, 0.7);
      width: 12px;
      min-width: 12px;
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 500;
      letter-spacing: 2px;
      color: #fff;
    }
  }
 
  &-popover {
    &-content {
      .key,
      .value {
        padding: 0 !important;
      }

      .value {
        overflow: hidden;
        min-height: 30px;

        &:after {
          // content: '';
          background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
          background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
          background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 10px;
        }
      }
    }
  }

  &-content {
    min-width: 240px;
    max-width: 300px;

    @media (min-width: 1200px) {
      max-width: 400px;
    }
  }

  &-item {
    &:hover {
      border-color: $primary !important;
    }
  }

  &-cell {
    &-inline {
      display: flex;
    }

    &-label {
      font-weight: 600;

      color: $default;

      .product-cell-inline & {
        text-align: right;
        white-space: nowrap;
        
        min-width: 60px;
        padding-right: 5px;
      }
    }
  }

  &-grid {
    &-item {
      z-index: 0;

      &:hover {
        z-index: 1;
      }

      &:after {
        content: '';
        position: absolute;
        pointer-events: none;

        bottom: 0px;
        left: 1px;
        right: 1px;

        background-color: #fff;
        height: 25px;

        background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.95) 57%, rgba(255,255,255,1) 60%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.95) 57%,rgba(255,255,255,1) 60%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.95) 57%,rgba(255,255,255,1) 60%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

      }

      .editable & {
        max-height: 400px;
      }

    }
  }

  &-header {
    padding-left: 20px;
    padding-right: 20px;

    min-height: 55px;

    &-search {
      width: 100%;
    }

    &-middle {
      display: flex;
      flex: 0 1 60%;

      align-items: center;
      justify-content: center;

      padding-left: 20px;
      padding-right: 20px;

      margin-left: auto;
      margin-right: auto;
    }
  }

  &-list {
    .product-list {
      border-right: 1px solid #eaeaea;
    }
  
    &-wrapper {
      border-left: 1px solid #eaeaea;

      .product {
        scroll-behavior: smooth;

        &-item {
          border: 1px solid #eaeaea;

          margin-bottom: -1px;
          margin-right: -1px;

          .app-container-mode-image_view & {
            margin-bottom: 0;
            margin-right: 0;
            margin-top: 0;

            border-right-width: 1px;
          }
        }
        
        &:not(.level-variant) {
          .app-content-wrapper & {
            height: calc(100% - 170px);
            overflow-y: auto;
          }
  
          .app-content-wrapper.sm & {
            height: calc(100vh - 124px);
            overflow-y: auto;
            padding-bottom: 0;

            position: relative;

            .product-list {
              display: flex;
              flex-direction: column;
              height: 100%;

              .MuiGrid-container {
                overflow-y: auto;
              }
            }
          }

          .app-content-wrapper.pim & {
            height: calc(100% - 124px);
          }

          .action-bar-on & {
            height: calc(100% - 150px);
          }
        }
      }
    }

    &-table {
      &-header {
        display: flex;
        border-bottom: 1px solid #eaeaea;
        padding-left: 113px;
        position: relative;
        align-items: flex-end;

        &:after {
          position: fixed;

          width: 40px;
          height: 60px;
          right: 15px;

          /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+87&0+0,1+87 */
          background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 87%); /* FF3.6-15 */
          background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 87%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 87%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */

          z-index: 10;

        }

        .editable & {
          padding-left: 143px;
        }

        &-pim {
          padding-left: 125px;
          padding-right: 20px;
          width: 100%;

          border-left: 1px solid #eaeaea;

          .rtl & {
            padding-right: 86px;
            padding-left: 20px;
          }

          .editable & {
            padding-left: 145px;
          }

          .editable.rtl & {
            padding-right: 155px;
            padding-left: 20px;
          }
        }

        &-preview {
          padding-left: 118px;
          padding-right: 60px;

          .editable & {
            padding-left: 172px;
          }
        }
    
        &-cell {
          font-weight: 500;
          font-size: 12px;
          color: #000;
          padding: 10px;
          min-width: 90px;

          position: relative;

          &:hover {
            .product-list-table-header-cell-sort {
              will-change: opacity, pointer-events, transition;

              pointer-events: all;
              opacity: 1;
            }
          }

          .sort-action {
            position: absolute;

            left: -12px;
            top: 0;
            bottom: 0;

            height: 100%;

            margin: auto;

            &:hover {
              will-change: cursor;

              cursor: pointer;
            }

            &.reset {
              color: $danger;

              &:hover {
                will-change: color;
  
                color: rgba($danger, 0.8);
              }
            }
          }

          .product-list-table-header-pim & {
            min-width: 90px;
            padding: 10px 3px;

            display: flex;
            align-items: center;
          }

          .product-list-table-header-preview & {
            min-width: 93px;
          }
          
          &-sort {
            width: 18px;
            height: 18px;

            display: flex;
            align-items: center;
            justify-content: center;
            pointer-events: none;

            opacity: 0;

            transition: color 0.2s ease, opacity 0.2s ease;

            &:hover {
              will-change: cursor, color;

              color: #868686;
              cursor: pointer;
            }

            .product-list-table-header-cell-sort-active & {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &-item {
    padding: 10px;
    position: relative;
    max-height: 400px;
    overflow: hidden;
    border-bottom: 1px solid #eaeaea;

    &:after {
      position: absolute;
      content: none;

      bottom: 0;
      left: 1px;
      right: 1px;
      height: 25px;

      background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0) 1%, rgba(255,255,255,1) 59%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 59%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,1) 59%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    }

    .related & {
      border-bottom: none;
    }

    .product-list-table-image-view & {
      overflow: visible;
    }
    
    .editable & {
      border-style: dashed;
      border-color: #c2c2c2;
    }

    .list & {
      border: 1px solid #eaeaea;
      border-right-width: 0;
      margin-top: -1px;
    }

    .level-merchant & {
      margin-top: 0;
      margin-bottom: 0;
    }

    &-label-more {
      .three-dots-abs & {
        font-size: 17px !important;
      }
    }

    &-card {
      &-full {
        position: absolute;

        .product-popover-content & {
          background-color: transparent;
        }
      }
      &-content {
        z-index: 13;

        .product-popover-content & {
          padding: 0 !important;
        }

        .grid-wide & {
          margin: 0 -10px;
        }
      }

      &.edit {
        max-height: 400px;

        cursor: default;
      }

      .editable & {
        border: 1px #c2c2c2 dashed;
        box-shadow: 0 0 0 0 transparent;
        cursor: default; // TODO: while drag and drop functionality is disabled
        // cursor: grab;
        // cursor: -webkit-grab;
        margin: -1px;
        border-left-width: 0;
        
        &:active {
          cursor: default; // TODO: while drag and drop functionality is disabled
          // cursor: grabbing;
          // cursor: -webkit-grabbing;
        }
      }

      button > * {
        pointer-events: none;
      }

      &-actions {
        position: absolute;
        top: 4px;
        right: 5px;
        pointer-events: none;
        opacity: 0;
        transition: .3s ease-in all;

        .editable & {
          pointer-events: all;
          opacity: 1;
        }
      }

      &-list {
        display: flex;
        height: 120px;
        width: 100%;

        .product-list-table-image-view & {
          overflow: visible;
          padding-right: 0;

          .pim & {
            padding-bottom: 0px;
            padding-left: 5px;
            margin-top: 0;
            padding: 5px 0;
          }
        }

        .width-full & {
          width: 100% !important;
        }

        .level-merchant & {
          margin-left: 15px;
        }

        .editable & {
          border: 1px $gray-600 dashed;

          &.without-dnd {
            border: 0;
            padding-right: 0;
          }
        }

        .product-item-image {
          width: 100px;
          height: 100px;
          background-size: contain;
        }

        .MuiCardContent-root {
          padding: 0;
          margin: 16px;
          overflow: hidden;

          &:last-child {
            padding: 0;

            margin: 0;
          }

          .product-list-table-image-view & {
            margin: 5px;
          }
        }
      }
    }

    &-image {
      max-height: 100%;
      height: 150px;
      width: 100%;
      position: relative;

      .product-item-card & {
        background-size: contain;
        object-fit: contain;
      }

      .editable & {
        &:hover + .product-item-card-fab {
          pointer-events: all;
          opacity: 1;
        }
      }
    }

    &-data {
      min-width: 100px;

      .product-popover-content & {
        padding: 0px 5px !important;
      }

      & + .product-item-data {
        .direction-column & {
          margin-top: 4px;
        }
      }

      
      .key {
        font-weight: 500;
        font-size: 13px;
        color: #000;
        bottom: 5px;

        .grid-wide & {
          font-size: 11.5px;
        }

        .product-item-child & {
          color: $primary !important;
          white-space: nowrap;
          font-size: 11px;
        }
      }

      .value {
        word-break: break-word;
        position: relative;
        font-size: 11.5px;
        font-weight: 300;
        color: #797979;

        [class^="app-container-mode-grid_"] & ,
        [class*="app-container-mode-grid_"] & {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &.tiny {
        max-width: 150px;
        width: 100%;
      }

      &.categories-list {
        &:hover {
          opacity: 0.7;
        }
      }
    }

    &-edit {
      position: absolute;
      top: 5px;
      right: 5px;
    }

    .dnd-icon {
      cursor: pointer;
    }

    .list-items {
      position: relative;

      .list & {
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          height: 15px;
          width: 100%;
          background: rgb(255,255,255);
          background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 100%);
        }
      }

      .product-list-table-image-view & {
        .list & {
          &:after {
            height: 5px;
          }
        }
      }
    }

    .product {
      &-card-descr-item {
        top: 20px;
        left: 0;
        width: 250px;
        z-index: 20;
        background-color: #fff;
        position: fixed;
        box-shadow: 0px 20px 40px -10px #a3a3a3 !important;
      }
    
      &-item-label {
        &-more {
          font-size: 12px;
    
          &-btn {
            padding: 0;
            width: auto;
            padding: 0 5px;
            margin-left: 5px;
            z-index: 2;

            .editable & {
              margin-left: 5px !important;
            }

            .table-header-static-pim & {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  &.list {
    padding-bottom: 45px;

    .product-item-image {
      height: auto;
      min-width: 70px;
      width: 80px;
      max-height: 120px;
      object-fit: contain;
      background-size: contain;
      padding: 5px 0;
    }

    .product-item-data {
      .value {
        white-space: normal;
      }
    }

    .edit-icon-wrapper {
      position: absolute;
      left: 2px;
      top: 4px;

      .rtl & {
        left: auto;
        right: 5px;
      }
      
      .edit-icon {
        opacity: 0;
        transition: .3s ease-in all;
        pointer-events: none;
        margin-left: -42px;
        position: relative;
        
          .editable & {
            pointer-events: all;
            opacity: 1;
            margin-left: 0;
          }
      }

      .MuiIconButton-root {
        font-size: 14px;
        padding: 10px;
      }
    }
    
  }

  &-edit {
    padding-bottom: 10px;
    
    &-left-col {
      .dialog-view-mode & {
        pointer-events: none;
      }
      
      .prefix-abs,
      .select-prefix {
        .dialog-view-mode & {
          display: none !important;
          pointer-events: none !important;
        }
      }
    }

    &-left-col,
    &-right-col {
      width: 50%;

      .product-edit-image-delete {
        bottom: -5px !important;
        right: -5px !important;
        .dialog-view-mode & {
          display: none !important;
        }
      }

      .product-edit-image-add {
        margin: 5px;
      }
    }

    &-footer {
      padding-top: 10px;

      h6 {
        font-weight: 400;
      }
    }

    &-left {
      &-col {
        padding: 0 35px 0px 0px;

        .attributes-row {
          .field-element {
            width: 100%;
          }
        }
      }
    }

    &-right {
      &-col {
        padding: 15px 0px;

        .min-height-250 {
          min-height: 250px;
        }
      
        .images-list-dnd {
          height: auto;

          position: relative;

          margin-bottom: 13px;

          .img-container {
            padding-left: 260px;
            position: relative;
          }

          .vid-container {
            position: relative;
          }

          .img-item {
            padding: 5px;

            button > * {
              pointer-events: none;
            }

            img {
              height: 105px;
              width: 105px;
              object-fit: contain;

              border: 1px solid #e6e6e6;
              border-radius: 3px;
              background-color: #e6e6e6;
            }
          }

          .vid {
            width: 100%;
            border: 0;
            display: inline-flex;
            position: relative;
            flex-direction: column;
            vertical-align: top;
            padding: 20px 47px 0 0;
            margin: 0;
          }

          .vid-label {
            transform: translate(14px, -6px) scale(0.75);
            transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
            color: rgba(0, 0, 0, 0.54);
            padding: 0;
            font-size: 1rem;
            font-family: Roboto,Helvetica,Arial,Heebo,sans-serif;
            font-weight: 400;
            line-height: 1;
          }

          .visible {
            visibility: visible !important;
            color: gray !important;
          }

          .vid-select {
            border: 1px solid rgba(0, 0, 0, 0.23);
            border-radius: 5px;
            .MuiSelect-select {
              color: gray !important;
              padding-left: 15px;
            }
            .MuiSelect-icon {
              color: gray !important;
            }
          }

          .vid-item {
            position: relative;
            left: 10px;
            margin-left: 5px;
          }

          .dnd-img {
            padding: 5px;
          }

          .main {
            position: absolute;
            left: 0;

            img {
              height: 230px;
              width: 230px;
            }
          }
        }
      }
    }

    &-image {
      &-delete {
        &.MuiButtonBase-root {
          height: 20px;
          width: 20px;
          min-height: 25px;
          min-width: 25px;
          position: absolute;
          bottom: 5px;
          right: 5px;

          svg {
            font-size: 18px;
          }
        }
      }
      &-control {
        &.MuiButtonBase-root {
          height: 20px;
          width: 20px;
          min-height: 25px;
          min-width: 25px;

          svg {
            font-size: 18px;
          }
        }
      }
    }
  }

  &-dialog {
    .MuiDialogActions-root {
      padding: 24px 24px 20px;
      margin: 0;

      button{
        margin: 0 5px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &.dialog-view-mode {
      .dialog-title-actions {
        margin-left: -10px;
      }
      
      .product-attributes-select {
        display: none !important;
      }

      button {
        &.Mui-disabled {
          display: none;
        }
      }
    }

    .MuiInput-underline:before {
      border-bottom: 0 !important;
    }

    .product-footer-divider {
      margin: 25px 0;
    }

    .MuiDialog-paperWidthXl {
      max-width: 1300px;
    }

    .MuiIconButton-root:not(.MuiCheckbox-root) {
      margin: 0 !important;
    }

    .zmdi {
      &-close {
        font-size: 16px;
      }
    }
  }

  .level {
    &-variant {
      margin-left: 50px !important;
      height: auto;
      padding-bottom: 0;
    }
  
    &-merchant {
      margin-left: 50px !important;
      height: auto;
      padding-bottom: 0;
    }
  }

  .MuiTableRow-root {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  .MuiTableCell-root {
    border-bottom: 0;
  }
}

.img-size {
  position: absolute;
  line-height: 1;
  font-size: 10px;
  text-align: center;
  color: #8a8a8a;
  background-color: #fff;
  z-index: 2;
  padding: 3px 8px;
  top: -3px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
}

.image {
  &-sm {
    height: 110px;
    width: 110px;
    object-fit: contain;
    padding: 5px;
    border: 1px solid #eaeaea;
    background-color: #f6f6f6;

    transition: border-color 0.2s ease;

    body > .sortable-image-list-item &,
    .sortable-image-list-item:hover & {
      will-change: border-color;

      border-color: $primary;
    }
  }
}

.products-list {
  &.related {
    background-color: #f8f8f8;
    margin: 0px -24px -8px;
    padding: 10px 20px;
  
    .product-item {
      width: 190px;

      padding: 0 5px;

      &-card {
        padding-bottom: 5px;

        border-radius: 0;
        border: 1px solid #fff;

        box-shadow: none;
        transition: border-color 0.15s ease;

        .product-item-data {
          margin-top: 10px;

          & + .product-item-data {
            margin-top: 0;
          }

          .key {
            font-size: 11px;
            font-weight: $bold;

            text-align: left;
            white-space: nowrap;
          }

          .value {
            width: 100%;
            text-align: right;
          }
        }

        &.active,
        &:hover {
          will-change: border-color;
          
          border-color: $default;
          box-shadow: none;
        }
      }
    }
  }
}

.sortable {
  &-helper {
    z-index: 9999;
    
    img {
      height: 200px;
      width: 200px;
    }
  }

  &-image {
    &-list {
      width: 100%;
      overflow: hidden;
      overflow-x: auto;
      display: block !important;
      white-space: nowrap;
      text-align: right;

      &-drag {
        &-disabled {
          .drag-handler {
            pointer-events: none;
          }
        }
      }

      .editable & {
        .preview & {
          user-select: none;
          pointer-events: none;
        }
      }
      
      &-item {
        position: relative;
        
        list-style: none;
        display: inline-block;
        
        margin: 0 5px;
        z-index: 10;

        pointer-events: none;

        .editable & {
          pointer-events: all;
        }
        
        .drag-handler {
          cursor: grab;

          position: absolute;

          left: 0;
          right: 0;
          bottom: 0;
          top: 0;

          z-index: 4;

          svg {
            visibility: hidden;
          }
        }

        &-actions {
          position: absolute;

          top: 3px;
          left: 3px;

          z-index: 5;

          .preview.app-container-mode-image_view & {
            display: none;
          }
          
          &.bottom {
            top: auto;
            bottom: 0;
            
            padding: 4px;

            .preview.app-container-mode-image_view & {
              display: none;
            }
          }

          &.right {
            left: auto;
            right: 0;
          }
        }
      }
    }
  }
}

.MuiInput-input {
  .select-wrapper-suffix & {
    padding-right: 40px !important;
  }
}

.img-list {
  .product-images-list-wrapper & {
    margin: 0 -10px;
  }
}

.vid-list {
  .product-images-list-wrapper & {
    margin: 0;
  }
}

.comment-actions-wrapper {
  .merchant-dialog & {
    margin-bottom: 15px;
  }

  .dialog-view-mode & {
    display: none !important;
  }
}

.MuiDialog-paperWidthXl {
  @media (max-width: 1280px) {
    margin: 0 !important;
    width: 100vw !important;
    max-width: 100% !important;
  }
}

.dialog {
  &-inputs-custom {
    .MuiInputBase-input {
      padding: 7px 6px 7px 10px;
    }

    .text-input-lg {
      &.select-wrapper-suffix {
        margin-right: 0;
      }
    }

    .select-suffix,
    .input-suffix {
      bottom: 0;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .search-wrapper {
      .MuiInputLabel-formControl {
        transform: translate(10px, 15px) scale(1);
      }
    }

    .MuiInputLabel-formControl {
      transform: translate(10px, 10px) scale(1);
      background-color: transparent;
      padding: 0;
      margin: 0 !important;
      line-height: 1;


      &.Mui-focused,
      &.MuiFormLabel-filled {
        transform: translate(10px, 7px) scale(0.75) !important;
      }

      &.select-label-up {
        transform: translate(10px, 3.5px) scale(0.75) !important;
      }
    }
  }

  &-content-visible {
    .MuiPaper-root,
    .MuiDialogContent-root {
      overflow: visible;
    }
  }

  &-title {
    padding: 24px 34px 20px;
  
    border-bottom: 1px solid #e6e6e6;
  
    h6 {
      display: flex;
    }

    &-actions {
      display: flex;

      .dialog-view-mode & {
        opacity: 0.6;
        pointer-events: none;
      }
    }

    .MuiSelect-icon {
      .dialog-view-mode & {
        display: none;
      }
    }

    .btn-link.Mui-disabled {
      .dialog-view-mode & {
        opacity: 1 !important;
      }
    }

    .dialog-view-mode & {
      pointer-events: none;
    }
  }

  &-footer {
    .merchant-dialog & {
      flex-direction: column;
      align-items: flex-end;
    }

    &-actions {
      display: flex;
      white-space: nowrap;
      
      .merchant-dialog & {
        margin: 0;
      }
    }
  }

  &-subactions {
    .merchant-dialog & {
      width: 100%;

      & > * {
        flex: 1 1 20%;
      }
    }
  }

  &-subtitle {
    .merchant-dialog & {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &-section {
    &-title {
      color: rgba(0, 0, 0, 0.54);
      padding: 0;
      font-size: 11px;
      font-family: Roboto,Helvetica,Arial,Heebo,sans-serif;
      line-height: 1;

      position: relative;

      .dialog-inner-title {
        font-size: 15px;
      }

      .icon-abs,
      .icon-wrapper {
        position: absolute;
        left: -30px;
        top: 0px;
        bottom: 0px;
        margin: auto;
        height: 20px;
        width: 20px;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        .attributes-group-items & {
          left: -30px;
          top: -20px;
        }

        .MuiFormLabel-filled &,
        .Mui-focused & {
          .attributes-group-items & {
            left: -30px;
            top: -25px;
          }
        }

        .product-attributes & {
          bottom: 6px;
          top: auto;
          left: -25px;
        }
      }
    }
  }

  &-footer {
    border-top: 1px solid #e6e6e6;
  }
}

.loading {
  padding-left: 20px;
  opacity: 0.7;

  .product-list & {
    position: absolute;
    top: -1px;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding-left: 113px;
    background-color: #ffffffd9;
    z-index: 1;
    opacity: 1;
    border-bottom: 1px solid #eaeaea;
    border-top: 1px solid #eaeaea;
  }
}

.icon-abs {
  position: absolute;

  &.left {
    left: 0px;
  }
}

.attribute-single {
  &:hover {
    background-color: #e3edff;
  }

  &-key {
    font-weight: 500;
  }

  &-value {
    font-weight: 400;
    padding-left: 5px;
    padding-top: 1px;
    padding-bottom: 1px;
    color: rgba(#000, 0.5);
  }
}

.zmdi {
  &.expanded {
    transform: rotate(180deg);
  }

  &-lock {
    &.left {
      left: -20px;

      .MuiFormLabel-focused &,
      .MuiFormLabel-filled & {
        left: -25px;
        transform: scale(1.333);
      }
    }
    
    .Mui-focused &,
    .MuiFormLabel-filled & {
      transform: scale(1.333);

      left: -25px;
      top: 0px;
    }
  }
}

.MuiCheckbox-root {
  padding: 5px !important;
}

.MuiIconButton-root {
  .editable & {
    margin-left: 0 !important;
    background-color: rgba(255, 255, 255, 0.9) !important;
    font-size: 18px;
    min-width: 20px;
    min-height: 20px;
  }

  .product-table-container & {
    padding: 5px;
  }
}

.product-table-container-no-scroll {
  padding: 5px;
  overflow: hidden !important;
}

.product-dialog {
  .dialog-title {
    .select-wrapper {
      min-width: 125px;
    }
  }

  .search-wrapper {
    display: flex;
    flex-direction: column;
  }

  .default-fields {
    padding-top: 15px;
    padding-left: 5px;
  }

  .attributes-fields {
    .attributes-group {
      padding: 15px 0 5px 0;

      &-items {
        padding-left: 5px;
        padding-top: 10px;
      }
      .attribute-title {
        font-size: 12px;
        letter-spacing: 1px;
        font-weight: 500;
        color: #ffffff;
        text-transform: uppercase;
        padding: 5px 30px;
        background-color: #515151;
        margin: 0;
      }
    }
    .attributes-group-1 {
      padding: 0 0 15px 0;

      &-items {
        padding-left: 5px;
        padding-top: 10px;
      }
      .attribute-title {
        font-size: 12px;
        letter-spacing: 1px;
        font-weight: 500;
        color: #ffffff;
        text-transform: uppercase;
        padding: 5px 30px;
        background-color: #515151;
        margin: 0;
      }
    }
  }
}

.product-dialog {
  .MuiFormHelperText-root {
    span {
      color: $red;
    }
  }
}

.preview-sidebar  {
  .MuiInput-input {
    white-space: inherit;
  }
}

.action-bar-on .product.editable {
  height: calc(100% - 150px) !important;
}

.app-content-wrapper.sm .product-list-wrapper.action-bar-on .product:not(.level-variant) {
  height: calc(100% - 140px);
}

.edit-icon-wrapper {
  & > * {
    & + * {
      margin-left: 3px;

      .rtl & {
        margin-left: 0;
        margin-right: 3px;
      }
    }
  }
}

.tr-danger {
  background-color: rgba($danger, 0.08);

  .three-dots-abs {
    background-color: rgb(254, 237, 237);
  }
}

.tr-success {
  background-color: rgba($success, 0.08);

  .three-dots-abs {
    background-color: rgb(233, 246, 240);
  }
}
.MuiPaper {
  &-rounded {
    .has-error & {
      box-shadow: 0 0 0 2px $danger;
    }
  }

  &-root {
    .preview-drawer & {
      background-color: rgba(255, 255, 255, 0.85);
      backdrop-filter: blur(5px);
    }
  
    .tr-danger & {
      background-color: rgba($danger, 0.05);
      margin-left: 0;
    }
  
    .tr-success & {
      background-color: rgba($success, 0.05);
      margin-left: 0;
    }
  
    & + .MuiButtonBase-root {
      margin-left: 10px;
    }
  
    #select-product-attribute-wrapper & {
      overflow: visible;
    }
  }
}

.show-more-popover {
  .MuiPaper-root {
    overflow: hidden;
  }

  .product-popover-content {
    min-width: 200px;
    max-width: 600px;
    padding: 15px !important;;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 10px;

    &.small {
      min-height: 50px !important;
      max-height: 600px;
    }
    
    .product-item-card-full {
      box-shadow: none;
      position: relative;

      .product-card-image {
        display: flex;
        max-width: 20%;
        flex-basis: 20%;
        flex-grow: 0;
        object-fit: contain;
        max-height: 120px;
      }

      .product-item-card-images {
        padding-bottom: 10px;
        border-bottom: 1px #c2c2c2 dashed;
        margin-bottom: 10px;
      }
    }

    .product-item-card-content {
      .MuiTypography-root {
        display: flex;
        flex-wrap: wrap;

        .product-item-data {
          flex-direction: column;
        }
      }
    }
  }
}

.product {
  &-grid-item {
    .rtl & {
      direction: ltr;
      text-align: left;
    }
  }

  &-item {
    &-child {
      &:hover {
        position: relative;
        z-index: 2;
      }
    }

    &-data {
      max-height: 120px;
      overflow: hidden;

      .level-merchant & {
        padding-right: 0 !important;
        padding-left: 10px !important;

        min-width: 115px;
        line-height: 1.25;
      }

      &:empty {
        display: none !important;
      }

      .show-more-popover & {
        position: relative;
        overflow: hidden;
        
        max-height: 130px;

        margin-bottom: 20px;
      }
      
      .key {
        .rtl & {
          white-space: nowrap;
          padding-left: 0 !important;
        }
      }
    }
  }
}

.search-wrapper {
  .attributes-group-items & {
    margin-top: 20px;
  }
}


.value {
  &-pubAopi {
    white-space: nowrap;
    word-break: unset !important;
  }
}

body {
  & > .product-grid-item {
    z-index: 20;
    position: absolute !important;
    border: 0px solid #eaeaea;
    box-shadow: 10px 30px 40px rgba(0, 0, 0, 0.15);
    max-height: 400px;
  
    .product {
      &-item {
        padding: 0;
  
        &-data {
          padding-top: 2px !important;
        }
  
        &-card {
          padding: 20px 10px;
    
          &-content {
            padding-top: 13px !important;
          }
    
          &-actions {
            opacity: 1;
          }
        }
      }
    }
  }
  & > .MuiGrid-item.MuiGrid-grid-xs-12 {
    z-index: 5;

    .MuiCardActions-root.card-collapse-btn .MuiCardActions-action {
      display: block;
    }

    .product-item-card-list {
      position: relative;
      padding-left: 25px;
    }

    .flex.edit-icon-wrapper.items-center {
      position: absolute;
      left: 5px;
      top: 5px;
    }

    .dnd-icon {
      left: 10px;
      position: absolute;
      bottom: 0;
      top: 0;
    }
  }
}

.import-error-content {
  margin-bottom: 15px !important;
  margin-top: -10px;
}

.preview,
.pim {
  .product {
    &-item {
      &-type {
        height: 120px;
        writing-mode: vertical-lr;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 128, 0, 0.7);
        width: 12px;
        min-width: 12px;
        font-size: 10px;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 500;
        letter-spacing: 2px;
        color: #fff;

        span {
          line-height: 1;
        }

        &.variation {
          background-color: rgba(0, 0, 128, 0.7);
        }

        &.merchant {
          background-color: rgba(247, 202, 24, 0.7);
        }
      }
    }
  }
}

.radio-group {
  &-item {
    margin: 0;

    .MuiIconButton-colorSecondary {
      padding: 5px;
    }

    .MuiFormControlLabel-label {
      font-size: 14px;
    }
  }
}

.filter-target-controller {
  border-bottom: 1px dashed #c7c7c7 !important;
}

.edit-icon-wrapper {
  .pim & {
    margin-left: 12px;
    margin-right: 12px;
    
  }
}

.pim {
  &-list {
    overflow-x: hidden !important;
    height: auto !important;
  }
}

.full-min-width {
  min-width: 100% !important;
}

.filter-target-wrapper {
  .filter-target-paper {
    padding: 5px;
  }

  .radio-group-label {
    line-height: 1;
    font-size: 12px;
    color: #979aa9 !important;
    font-family: inherit;

    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    opacity: 0.6;
  }

  .radio-group {
    display: flex;

    & > * {
      flex: 1 0 auto;
    }

    &-item {
      margin: 0 !important;

      .MuiIconButton-colorPrimary {
        padding: 0;
        margin: 8px 0;
        color: #5D92F4 !important;
      }

      .MuiFormControlLabel-label {
        margin-left: 8px;
        font-size: 13px;
        font-family: inherit;
      }
    }
  }
}

.product-info {
  &-loader {
    margin: auto;
  }

  &-content {
    min-height: 100px;
    min-width: 200px;

    .MuiListItem-root {
      padding: 5px 15px;

      .MuiListItemText-root {
        span {
          font-size: 12px;
          opacity: 0.8;
        }
      }
    }
  }
}

.images {
  &-item {
    & + .product {
      .product-list {
        width: calc(100vw - 300px);
      }
    }
  }
}

.MuiTableContainer-root {
  .pim,
  .preview & {
    overflow-x: unset;
  }
}

.select-all-checkbox {
  white-space: nowrap;

  .MuiIconButton-root {
    padding-left: 2px !important;
  }
}

.icons-list {
  height: 120px;
  
  .dnd-icon {
    margin: 10px !important;
  }

  .icon-chevron {
    padding: 5px 10px;
  }
}

.dnd-active {
  z-index: 5;
  background-color: #fff;
  height: 120px !important;

  .product-item-image {
    height: auto;
    min-width: 70px;
    width: 80px;
    max-height: 120px;
    object-fit: contain;
    background-size: contain;
    padding: 5px 0;
  }

  .edit-icon-wrapper {
    position: absolute;
    left: 4px;
    top: 4px;
  }
}

.custom-loader {
  .pdoduct-dialog & {
    left: 1px;
    max-width: 15px;
    max-height: 15px;
    bottom: 28px;
    right: 48px;
  }

  &.users-sync {
    position: relative;
    left: auto;
  }
}

.preview {
  .level {
    &-variant {
      .product-item-card-list {
        padding-left: 38px;
      }

      .product-item-data {
        .key {
          color: #5D92F4 !important;
          white-space: nowrap;
          font-size: 11px;
        }
      }

      .product-list {
        margin-bottom: -1px;
      }
    }
  }
}

.product-item-image {
  &.tooltip-inner {
    object-fit: contain;
    background-color: transparent;
    padding: 3px 0;
  }
}

.grid-view-container {
  height: calc(100% - 120px);
  overflow: auto;
  
  .rct-page-grid & {
    height: calc(100vh - 120px);

  }
}

.drawer-toggle {
  &-badge {
    position: absolute !important;
    right: 0;
    top: 45%;
    z-index: 100;

    transition: right 0.2s ease;

    &.badge-disabled {
      right: -50px;
    }

    .MuiBadge-badge {
      height: 50px;
      width: 55px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      .ti-arrow-left {
        position: absolute;
        left: 10px;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}

.drawer {
  &-inner {
    height: 100%;
  }
  &-scroll-container {
    overflow: auto;
    height: 100%;
    padding-bottom: 50px;
  }
}

.preview-drawer {
  .MuiDrawer-paperAnchorRight {
    overflow: hidden;
  }

  .dashed-pl-pr {
    margin: 0 20px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.12);
    background-color: transparent;
  }

  &-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 10px;

    &-title {
      font-weight: 500;
    }

    &-subtitle {
      font-size: 12px;
      opacity: .6;
    }

    .MuiButtonBase-root {
      position: absolute;
      right: 15px;

      .ti-close {
        font-size: 14px;
        font-weight: 600;
      }
    }

    .type-selector-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      .vertical-separator {
        padding: 0 8px;
        opacity: .6;
      }

      .select-wrapper {
        .MuiSelect-select {
          &.MuiSelect-selectMenu {
            font-size: 12px;
            color: #5D92F4;
          }
        }
      }
    }
  }

  &-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  &-designation {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    .form-group {
      flex: 1;
    }

    .form-label {
      font-weight: 500;

      text-rendering: geometricPrecision;
    }

    &.navigation {
      flex-direction: column;
      align-items: flex-start;

      .name-row {
        display: flex;
        width: 100%;
      }

      .search-header-result {
        padding-top: 15px;
        flex: 1;
        max-width: 100%;

        .form-label {
          margin: 0;
          min-width: 55px;
        }
      }

      .search-phrase-suffix {
        position: absolute;
        right: 8px;
        top: 8px;
      }
    }
  }

  &-filter {
    padding: 20px;

    .filter-attributes-row {
      margin-top: 25px;
    }

    .form-dashed {
      border-bottom: 0;
      padding-bottom: 0;
    }

    .form-label {
      font-weight: 500;

      text-rendering: geometricPrecision;
    }

    .filter-by-section {
      .last-group {
        .MuiFormHelperText-root {
          position: relative;
        }
      }
    }

    .autocomplete-select-control-wrapper {
      .MuiFormHelperText-root {
        left: auto !important;
        bottom: auto !important;
        margin-top: 0;
      }
    }

    .text-right {
      .MuiFormHelperText-root {
        color: rgba(0, 0, 0, 0.54);
      }
    }

    .select-autocomplete-wrapper {
      &.has-errors {
        box-shadow: none !important;
      }
    }

    .search-wrapper {
      flex-direction: column;

      .MuiFormHelperText-root {
        left: 0 !important;
        bottom: -4px !important;
      }
    }
  }

  &-sort {
    padding: 20px;

    .form-label {
      font-weight: 500;

      text-rendering: geometricPrecision;
    }

    .form-dashed {
      padding-bottom: 0;
      padding-top: 0 !important;
      border-bottom: 0;
    }
  }

  &-facet {
    padding: 20px;

    .rct-block  {
      margin-bottom: 0;
      box-shadow: none;

      &-title {
        padding: 10px 0;
      }

      .drag-list {
        &-container {
          .ti-drag {
            width: 20px;
            height: 20px;
          }
        }

        &-item {
          padding-left: 30px;
        }
      }
    }

    .loader {
      &.category-facets {
        right: 130px;
      }
    }
  }

  &-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #F0F0F0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 10;

    &.disabled {
      opacity: 1;
      pointer-events: all;

      .btn-group {
        opacity: 0.6;
        pointer-events: none;
      }
    }

    .btn-group {
      justify-content: space-around;
      padding: 0 10px;

      .MuiButtonBase-root {
        padding: 4px 10px;

        & + .MuiButtonBase-root {
          margin-left: 8px;
        }
      }
    }

    .save {
      color: #fff;
      background-color: #5D92F4;

      &:hover {
        background-color: rgb(65, 102, 170);
      }
    }

    .saveAndClose {
      background-color: #fff;
      border: 1px solid #5D92F4;
      color: #5D92F4;
    }

    .close {
      border: 1px solid #b8b8b8;
      color: #000;
      background-color: #fff;
      padding: 6px 8px;
      opacity: 1;
    }

    .custom-loader {
      left: auto;
      position: relative;
    }
  }

  &-loader {
    margin: auto;
    position: relative;
    left: auto;
    margin-bottom: 10px;

    &-wrapper {
      margin: auto;
      display: flex;
      flex-direction: column;

      span {
        font-size: 11.5px;
        font-weight: 300;
        color: #797979;
      }
    }
  }
}

.MuiDrawer-paper {
  min-width: 800px;

  .sidebar-wrapper & {
    min-width: 245px;

    max-height: calc(100% - 65px);
    padding-bottom: 20px;
  }
}

.badge {
  &-disabled {
    pointer-events: none;

    .MuiBadge-badge {
      background-color: #c4c4c4;
    }
  }
}

.app-container-mode-image_view {
  .product-list {
    .variant {
      .checkbox-wrapper {
        display: none;
      }
    }
  }
}

.errorMsg {
  color: #B02E0C;
  font-size: 12px;
  margin-bottom: 10px;
}

.enrichment-video-preview {
  float: left;
  border: 1px solid #797979;
  padding: 5px;
  width: 150px;
  margin: 0 5px 5px 0;
}
.enrichment-video-preview-image {
  width: 138px;
  cursor: pointer;
}
.enrichment-video-preview-title {
  padding-top: 5px;
  font-size: 12px;
}

.enrichment-video-preview-checkbox-over {
  position: relative;
}
.enrichment-video-preview-checkbox {
  position: absolute;
  top: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.product-edit-left {
  background-color: #e8e8e8;
  border-radius: 20px 0 0 20px;
  position: relative;
  overflow-y: auto;
  padding: 10px;
  width: 100%;
  margin: 0;
}
.product-edit-dialog {
  background-color: #7c7c7b;
}
.product-edit-dialog-wrapper {
  background-color: #ffffff;
  border-radius: 20px;
}
.product-edit-dialog-top {
  margin-top: 10px;
  position: fixed;
  background-color: #efefef;
  border: 1px solid #dadada;
  z-index: 100;
  margin-left: 10px;
  flex: 0 0 auto;
  padding: 10px;
  align-items: center;
  justify-content: flex-end;
  border-radius: 20px;
}
.product-edit-dialog-top-scroll {
  overflow-x: auto;
  overflow-y: hidden;
  height: 55px;
}
.product-edit-dialog-top-blank {
  width: 97%;
  height: 80px;
  background-color: #ffffff;
  position: absolute;
  z-index: 90;
}
.product-edit-dialog-top-wrapper {
  white-space: nowrap;
  height: 40px;
}
.product-edit-dialog-top-scroll::-webkit-scrollbar {
  height: 10px;
}
.product-edit-dialog-top-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}
.product-edit-dialog-top-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

.product-edit-dialog-content {
  padding: 80px 25px 80px 25px;
}
.product-edit-dialog-content-full {
  padding: 20px 25px 80px 25px;
}
.product-edit-dialog-bottom {
  padding: 20px 15px 10px 15px;
  position: fixed;
  background-color: #fff;
  z-index: 100;
  width: 80%;
  top: 796px;
  border-radius: 20px;
}
.product-edit-dialog-bottom.merchant {
  padding: 20px 15px 0 15px !important;
}
.product-edit-dialog-top-buttons {
  width: 100%;
  border: 2px solid #b6b6b6;
  background-color: #ffffff;
  border-radius: 20px;
  font-weight: bolder;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  .merchant {
    max-width: 240px;
  }
}
.product-edit-dialog-top-buttons:hover {
  border: 2px solid #3473b8;
}
.product-edit-dialog-top-buttons.button {
  float: left;
  padding: 5px;
  width: 200px !important;
  font-weight: normal !important;
  margin-right: 20px;
}
.product-edit-dialog-top-buttons.active {
  border: 2px solid #3473b8 !important;
  background-color: #e1f4ff !important;
  font-weight: bolder !important;
  cursor: default;
}
.product-edit-dialog-top-buttons.variant {
  width: 100% !important;
  padding-bottom: 5px;
  margin-bottom: 5px;
  cursor: default;
}
.product-edit-dialog-top-buttons.variant-active {
  border: 2px solid #3473b8 !important;
  background-color: #ffffff !important;
  cursor: default;
}
.product-edit-dialog-top-buttons.merchant {
  font-weight: 500 !important;
  border: 2px solid #b6b6b6 !important;
  background-color: #efefef !important;
  padding: 5px 10px;
  margin: 10px;
  display: block;
}
.product-edit-dialog-top-buttons.merchant:hover {
  border: 2px solid #3473b8 !important;
  background-color: #efefef !important;
}
.product-edit-dialog-top-buttons.merchant-active {
  border: 2px solid #3473b8 !important;
  background-color: #e1f4ff !important;
  cursor: default;
}

.product-edit-parent-product-left {
  float: left;
  width: 35%;
  padding: 10px;
}
.product-edit-parent-product-middle {
  padding: 0 10px 20px 10px;
  float: left;
  width: 50%;
  position: relative;
  top: 10px;
  text-align: left;
}
.product-edit-qty {
  padding-top: 10px;
}
.product-edit-qty-normal {
  padding-top: 10px;
  font-weight: normal;
}
.product-edit-parent-product-right {
  padding: 0 10px 20px 0;
  float: right;
  width: 15%;
  position: relative;
  top: 10px;
}
.product-edit-variant-edit-link {
  width: 30px;
  height: 30px;
  background-color: #6286c6;
  color: #ffffff;
  border-radius: 30px;
  cursor: pointer;
}
.product-edit-variant-edit-link.active {
  background-color: #d3233e !important;
}
.product-edit-variant-edit-arrow {
  position: relative;
  top: -14px;
  font-size: 35px;
}

.product-edit-left-scroll {
  overflow-y: auto;
  border-radius: 20px 0 0 20px;
}
.product-edit-left-scroll::-webkit-scrollbar {
  width: 10px;
}
.product-edit-left-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}
.product-edit-left-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

.product-edit-right-scroll {
  padding-left: 20px;
  overflow-y: auto;
}
.product-edit-right-scroll::-webkit-scrollbar {
  width: 10px;
}
.product-edit-right-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}
.product-edit-right-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
.MuiFab-primary {
  background-color: #6286c6 !important;
}

.product-dialog .attributes-fields .attributes-group .attribute-title {
  color: #797979 !important;
  background-color: #dadada !important;
  border-radius: 10px;
  font-weight: bolder;
}
.product-dialog .attributes-fields .attributes-group-1 .attribute-title {
  color: #797979 !important;
  background-color: #dadada !important;
  border-radius: 10px;
  font-weight: bolder;
}

.merchant-edit-container-wrapper {
  border: 1px solid #b6b6b6;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px 0 20px 20px;
  margin-top: 20px;
  margin-bottom: 40px;
}
.merchant-top-buttons {
  margin: 30px 20px 10px 0;
  border-bottom: 1px solid #b6b6b6;
  padding-bottom: 10px;
}
.merchant-dialog .comment-actions-wrapper {
  margin-bottom: 0;
}

.prices-table {
  margin-top: 10px;
}
.prices-table th {
  background-color: #efefef;
  font-weight: bolder;
  border: 1px solid #ffffff;
  white-space: nowrap;
}

.prices-table-scroll {
  padding-right: 20px;
}

.price-was-became {
  float: left;
  width: 70px;
}

.prices-log-table-scroll {
  padding-top: 60px;
  padding-right: 20px;
}

.price-red-percent {
  color: #d3233e;
  font-size: 20px;
  float: left;
  padding-left: 20px;
}

.price-green-percent {
  color: rgba(0, 128, 0, 0.7);
  font-size: 20px;
  float: left;
  padding-left: 20px;
}

.price-null-percent {
  font-size: 20px;
  float: left;
  padding-left: 20px;
}

.price-arrow {
  font-size: 30px;
  font-weight: bolder;
  float: left;
  position: relative;
  top: -12px;
}

.price-vertical-arrow {
  font-size: 30px;
  font-weight: bolder;
  float: left;
  position: relative;
  top: -8px;
}

.prices-table-scroll div::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.prices-table-scroll div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}
.prices-table-scroll div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

.no-comments {
  text-align: center;
  padding-top: 80px;
}

.user-no-image {
  width: 70px;
  height: 70px;
  background-color: #b6b6b6;
  border-radius: 100%;
  padding-right: 20px;
}

.verified-buyer {
  color: #b6b6b6;
}

.reviews-score {
  color: #ffc638;
  font-size: 25px;
  float: left;
  padding-right: 10px;
  position: relative;
  top: -9px;
}

.reviews-score-number {
  color: #000;
  font-size: 18px;
  font-weight: bolder;
  position: relative;
  top: -1px;
  left: 3px;
}

.reviews-created {
  color: #b6b6b6;
  float: left;
}

.reviews-content {
  position: relative;
  top: -10px;
}

.reviews-container {
  padding-top: 40px;
  padding-left: 0;
}

.votes {
  color: #b6b6b6;
  font-size: 15px;
  position: absolute;
  text-align: center;
}

.votes-up {
  color: #b6b6b6;
  font-size: 15px;
  text-align: center;
  position: relative;
  left: 80px;
}

.votes-down {
  color: #b6b6b6;
  font-size: 15px;
  text-align: center;
  position: relative;
  left: 50px;
}

.votes-emoji {
  color: transparent;
  text-shadow: 0 0 0 gray;
}

.reviews-summary {
  text-align: center;
  border-left: 1px solid #e6e6e6;
  position: fixed;
  top: 130px;
  padding-left: 10px;
}

.summary-score-title {
  font-size: 25px;
  font-weight: bolder;
}

.summary-score {
  font-size: 45px;
  font-weight: bolder;
}

.summary-count {
  color: #b6b6b6;
}

.summary-count-all {
  font-size: 18px;
  font-weight: bolder;
}

.summary-scores {
  color: #ffc638;
  font-size: 35px;
}

.stars-container {
  padding-top: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
}

.star-title {
  float: left;
  position: relative;
  height: 25px;
}

.star-content {
  float: left;
  position: relative;
  top: 5px;
  left: -5px;
}

.star-result {
  float: left;
  position: relative;
  color: #0084e9;
}

.star-content-filled {
  float: left;
  background-color: #0084e9;
  height: 10px;
}

.star-content-blank {
  float: left;
  background-color: #ededed;
  height: 10px;
}

.reviews-image {
  width: -webkit-fill-available;
}

.reviews-content {
  min-width: 500px;
}

.reviews-circle-checkmark {
  cursor: auto;
  display: inline-block;
  color: #fff;
  background-color: #1cc286;
  width: 19px;
  height: 19px;
  border-radius: 19px;
  border: 1px solid #fff;
  font-size: 13px;
  position: relative;
  top: -20px;
  left: 5px;
}

.repricer-link {
  color: #5D92F2;
}

.repricer-link:after {
  font-family: 'Material Icons';
  font-style: normal;
  font-size: 16px;
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: "liga";
  font-feature-settings: "liga";
  speak: none;
  text-decoration: none;
  -webkit-user-select: none;
  content: "open_in_new";
  margin-left: 1px;
  margin-bottom: 1px;
  vertical-align: middle;
}

.repricer-image {
  padding: 10px;
}

.product-header-right {
  padding-top: 5px;
}

.product-header-right-outliner {
  position: relative;
  top: -23px;
}

.product-header-right-page-items {
  position: relative;
  top: 28px;
}

.product-options {
  cursor: pointer;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #b6b6b6;
  color: #5D92F2;
  width: 45px;
  height: 38px;
}
.product-options:hover {
  background-color: #ededed;
  border-color: #5D92F2;
  color: #5D92F2;
  position: relative;
  z-index: 1000;
}

.product-options-svg {
  min-width: 16px;
  width: 16px;
  height: 16px;
  filter: none;
  color: inherit;
  opacity: 1;
}

.product-actions-picker {
  position: absolute;
  top: 25px;
  width: 205px;
  border-radius: 5px;
  border: 1px solid #b6b6b6;
  background-color: #ffffff;
  z-index: 1000;
  left: 15px;
}

.product-actions-picker-option {
  height: 30px;
  padding: 5px;
  font-weight: 400;
  border-radius: 5px;
  cursor: pointer;
}

.product-actions-picker-option:hover {
  background-color: #dcebfe;
}

.product-arrow {
  position: relative;
  left: 20px;
  top: 40px;
  z-index: 900;
}

.product-checkbox {
  position: absolute;
  top: 70px;
  left: 26px;
  z-index: 900;
}

.product-arrow-variation {
  position: relative;
  left: 8px;
  top: -5px;
  z-index: 900;
}

.product-checkbox-variation {
  position: absolute;
  top: 70px;
  left: 22px;
  z-index: 900;
}

.parent-level-image {
  position: relative;
  left: -25px;
}