.transfer {
  &-wrapper {
    margin: 0 -20px;
    padding-top: 20px;

    .select-categories-mod & {
      height: 100vh;
      max-height: 400px;

      display: flex;
      justify-content: space-between;
    }
  }

  &-actions {
    margin: 0 15px;
    width: auto !important;
  }

  &-source,
  &-target {
    &-list {
      padding: 5px 15px;
      border-radius: 6px;
      border: 1px solid #e7e7e7;
      background-color: #f6f6f6;

      height: 100%;
    }
  }

  &-list {
    margin-bottom: 0;
    overflow-y: auto;
    list-style: none;
    
    &-item {
      display: flex;
      align-items: center;
      
      padding: 3px 5px;
      margin: 0px -5px;

      transition: all 0.2s ease;

      &:hover {
        background-color: #e7e7e7;
      }

      .wrapper {
        width: 100%;
        padding-right: 30px;
        position: relative;

        .MuiIconButton-root {
          position: absolute;
          right: 0;
          top: 2px;
        }
      }

      &-disabled {
        cursor: not-allowed;
      }

      &-sublabel {
        font-size: 11px;
        color: #a2a2a2;
        display: inline-block;
      }

      &-label {
        .transfer-list-item-disabled & {
          opacity: 0.3;
          text-decoration: line-through;
        }

        &-container {
          margin-right: 8px;
        }
      }
    }
  }

  .product-item-card-full {
    min-height: 400px;
    min-width: 450px;
  }
}

.target-list,
.source-list {
  &-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 20px;
  }

  &-title {
    margin-bottom: 12px;
    font-size: 12px;

    .MuiButton-root {
      margin: -7px 0px;   
    }
  }
}

.transfer-remove-btn {
  display: flex;
  padding: 0 20px;
  position: absolute;
  right: 0;
  bottom: 15px;

  .btn-link {
    margin: auto;
    color: $red;
    border-color: $red;

    &:hover {
      border-color: $red;
    }
  }
}