.drag {
  &-list {
    &-container {
      list-style: none;
      margin: 0;
    }

    &-card {
      .facet-group-secondary & {
        margin: 0 -10px;
        width: auto !important;
      }
    }

    &-actions {
      white-space: nowrap;
    }

    &-item {
      position: relative;
      display: flex;
      align-items: center;
      background: none !important;
      padding: 6px 5px;
      padding-left: 50px;
      border-top: 1px solid rgb(235, 235, 235);
      border-bottom: 1px solid rgb(235, 235, 235);
      background-color: #fff !important;
      box-shadow: 0 3px 20px -10px rgba(0, 0, 0, 0.5);
      transition: none;

      .preview-drawer & {
        border-color: rgba(0, 0, 0, 0.12);
      }

      .drag-list-card & {
        background-color: transparent !important;
      }

      &:last-child {
        border-top: 0;

        .drag-list-card & {
          border-top: 1px solid #ebebeb;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        .facet-group-wrapper & {
          border-top: 1px solid #ebebeb;
        }

        .preview-drawer & {
          border-color: rgba(0, 0, 0, 0.12);
        }
      }

      &:first-child {
        border-top: 0;

        .facet-group-wrapper & {
          border-top-width: 0;
        }

        .drag-list-card & {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
      }

      .drag-list-dragger & {
        padding-left: 50px;
        padding-right: 10px;
        border-bottom: 0;
        box-shadow: 0 0 0 0 transparent;
        min-height: 50px;

        .preview-drawer & {
          background-color: transparent !important;
        }
      }

      &-reverse {
        .drag-list-dragger & {
          padding-right: 0;
          padding-left: 0;

          align-items: baseline;
        }

        .react-single-wrap {
          margin-bottom: 5px;
        }
      }

      .td {
        &-remove {
          margin-left: auto;
        }
      }
    }
  }

  &-over {
    box-shadow: inset 0 0 0 2px #316ac5;
    
    & > .draggable {
      background-color: transparent !important;
      border: 1px transparent dashed !important;
      opacity: 0.8;
      display: block;
    }
  }
}

.chevron-vertical {
  transform: rotate(90deg);
}

.ti-drag {
  .drag-list-item & {
    position: absolute;
    left: 0;
    right: 0;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: all 0.2s ease;

    &:hover {
      color: $primary;
      cursor: ns-resize;
    }
  }

  .drag-list-item-reverse & {
    left: auto;
    right: 0;

    height: 40px;
    width: 35px;

    position: relative;
    margin-left: auto;
  }
}

.border {
  &-no {
    border-width: 0 !important;
  }
}

body {
  & > .drag-list-item-reverse {
    padding-left: 10px;
    min-height: 60px;
    padding-top: 30px;
  }
}

.td {
  &-name {
    .drag-list-dragger & {
      margin-left: -30px;
    }
  }
}

.ti-drag {
  & + .td-name {
    .drag-list-dragger & {
      margin-left: 0px;
    }
  }
}

body {
  & > .drag-list-item {
    z-index: 1300;
    // padding-bottom: 30px;

    &-wrapper {
      z-index: 9999;
      padding-bottom: 0px;
    }
  }
}