.MuiDialogContent-root {
  .scope-dialog & {
    overflow: hidden;
  }
}

.MuiDialog {
  &-paperWidthXl {
    max-height: 850px !important;
    height: 100vh !important;
  }
}

.scope-dialog  {
  .rct-scroll {
    & > div {
      overflow: auto !important;
      height: calc(100vh - 190px);
    }
  }
}

.MuiDialogActions {
  &-root {
    .select-products & {
      margin: 0;
      padding: 0 20px 20px;
    }
  }
}

.dialog {
  &-actions {
    &-secondary {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
  }

  &-content {
    &-bordered {
      border-width: 1px 0px 1px 0px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.12);
    }

    &-md {
      min-height: 300px;
    }
  }

  &-height {
    &-md {
      min-height: 70vh;
    }
  }
}