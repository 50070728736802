.empty {
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &-header {
    [class^='ti-'],
    [class*='ti-'] {
      font-size: 30px;
    }
  }

  &-body {
    opacity: 0.7;
  }
}