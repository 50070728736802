.errors-tooltip-styles {
  position: absolute;
  bottom: 60% !important;
  background-color: #fff;
  left: 0;
  right: 0;
  box-shadow: 0px 3px 7px 0px #00000057;
  border-radius: 3px;
  z-index: 1;
  padding: 2px 5px;
  font-size: 10px;
  margin: 0px;

  pointer-events: none;
  opacity: 0;

  display: flex;
  flex-direction: column;

  min-width: 150px;
  transition: opacity 0.2s ease, bottom 0.2s ease;
  
  span {
    color: $danger !important;
  }
}

.errors-tooltip-hover-styles {
  will-change: opacity, bottom, transition;

  opacity: 1;
  bottom: 100% !important;
}

.MuiFormLabel-root,
.MuiFormHelperText-root {
  .has-errors & {
    color: $danger;
  }

  .category-filter-wrapper & {
    white-space: normal;
  }
  
  .text-right & {
    text-align: right;
  }
}

.text {
  &-error {
    font-size: 0.75rem;
  }
}

.MuiFormHelperText {
  &-root {
    .errors-tooltip & {
      @extend .errors-tooltip-styles;
    }

    .errors-tooltip:hover & {
      @extend .errors-tooltip-hover-styles;
    }

    .tooltip-right & {
      left: auto;
      right: 0;
    }

    .text-danger {
      display: flex;
      flex-direction: column;
    }
  }
}

.errors-tooltip {
  & + .MuiFormHelperText-root {
    @extend .errors-tooltip-styles;

    .text-danger {
      display: flex;
      flex-direction: column;
    }
  }

  &:hover {
    & + .MuiFormHelperText-root {
      @extend .errors-tooltip-hover-styles;
    }
  }

  &.tooltip-right {
    & + .MuiFormHelperText-root {
      left: auto;
      right: 0;
    }
  }
}

