.pagination {
  position: relative;

  margin: 0;
  display: flex;
  @include list-unstyled();
  @include border-radius();

  .pagination-xs & {
    line-height: 1;
    min-height: 0;
  }

  &-halper {
    position: absolute;
    top: 100%;
    
    right: 0;

    color: $danger;
    font-size: 10px;
    text-align: center;

    padding-top: 4px;

    white-space: nowrap;
  }

  &-xs {
    line-height: 1;
    min-height: 0;

    .MuiCardHeader-action & {
      margin-right: 8px;
      margin-top: 8px;
    }
  }

  li {
    padding: 0 1px;
    font-size: 12px;
  }

  .page-btn {
    border: 1px solid #c4c8d8;
    border-radius: 5px;
    margin: 0 2px;

    &:hover {
      background-color: #e3e5ec;
    }

    a {
      padding: 0.1rem 0.4rem;
    }

    &.active {
      background-color: #5D92F4;
      border-color: #5D92F4;

      a {
        color: #fff;
      }
    }
  }

  .previous,
  .next {
    display: flex;
    align-items: center;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;

      &:hover {
        a {
          color: #5D92F4;
        }
      }
    }
  }

  .previous {
    margin-right: 5px;
  }

  .next {
    margin-left: 5px;
  }

  .break-me {
    display: flex;
    align-items: flex-end;
  }
}

.page-link {
  position: relative;
  display: block;
  padding: $pagination-padding-y $pagination-padding-x;
  margin-left: -$pagination-border-width;
  line-height: $pagination-line-height;
  color: $pagination-color;
  background-color: $pagination-bg;
  border: $pagination-border-width solid $pagination-border-color;

  &:hover {
    color: $pagination-hover-color;
    text-decoration: none;
    background-color: $pagination-hover-bg;
    border-color: $pagination-hover-border-color;
  }

  &:focus {
    z-index: 2;
    outline: 0;
    box-shadow: $pagination-focus-box-shadow;
  }

  // Opinionated: add "hand" cursor to non-disabled .page-link elements
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
}


.page {
  &-btn {
    .pagination-xs & {
      align-items: center;
      display: flex;
      
      padding: 3px 2.5px;
      font-size: 12px;
      line-height: 1;
      min-height: 0;

      border-radius: 4px;
    }

    a {
      .pagination-xs & {
        padding: 0 3px;
      }
    }
  }

  &-item {
    &:first-child {
      .page-link {
        margin-left: 0;
        @include border-left-radius($border-radius);
      }
    }
    &:last-child {
      .page-link {
        @include border-right-radius($border-radius);
      }
    }
  
    &.active .page-link {
      z-index: 1;
      color: $pagination-active-color;
      background-color: $pagination-active-bg;
      border-color: $pagination-active-border-color;
    }
  
    &.disabled .page-link {
      color: $pagination-disabled-color;
      pointer-events: none;
      // Opinionated: remove the "hand" cursor set previously for .page-link
      cursor: auto;
      background-color: $pagination-disabled-bg;
      border-color: $pagination-disabled-border-color;
    }
  }
}


//
// Sizing
//

.pagination-lg {
  @include pagination-size($pagination-padding-y-lg, $pagination-padding-x-lg, $font-size-lg, $line-height-lg, $border-radius-lg);
}

.pagination-sm {
  @include pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm, $line-height-sm, $border-radius-sm);
}
