.influencers {
  &-row {
    &-divider {
      margin: 20px 0 !important;
      width: 100%;
    }
  }
  &-content {
    &-wrapper {
      overflow: auto;
      height: auto;
      max-height: calc(100% - 75px);
      padding-bottom: 20px;

      .section {
        &-subtitle {
          color: #6f7380;
        }
      }

      .MuiFormHelperText-root {
        color: #dc3545;
        position: absolute;
        bottom: -20px;
      }
    }

    .products-list {
      background-color: $white;
      margin: 0;
      padding: 0;

      .MuiGrid-container {
        background-color: #f8f8f8;
        padding: 5px;
      }

      &-item {
        .product-item {
          padding: 5px;
          width: 200px;

          &-card {
            height: 310px;
          }
        }
      }
    }

    &-paper {
      padding: 20px;
      margin: 35px 40px 0 40px;
    }

    .topten-title {
      font-size: 14px;
      font-weight: 600;
      margin-left: 30px;
      font-family: 'Heebo,sans-serif';
      text-transform: uppercase;
      letter-spacing: 2px;
      color: #6f7380;
    }

    .tracking-info {
      &-container {
        padding-bottom: 25px;
        margin-left: auto;
        align-items: center;

        > span {
          font-size: 13px;
          color: #6c757d;
        }
      }

      .MuiChip-root {
        margin-left: 15px;

        .MuiChip-label {
          padding: 0 12px;
        }
      }
    }

    .avatar {
      width: 60px;
      height: 60px;

      &-container {
        &:hover {
          .avatar-upload {
            height: 100%;
            opacity: 1;
            cursor: pointer;
          }
        }
      }

      &-upload {
        transition: opacity 0.5s ease;
        opacity: 0;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        margin-bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 100%;

        &:after {
          content: 'Upload';

          color: white;
          position: absolute;
          bottom: 11px;
          left: 10px;
          display: flex;
          align-self: center;
          font-size: 12px;
        }
      }
    }

    .inputs-row {
      margin: 0 -20px;

      .MuiTextField-root {
        margin: 0 20px;
        width: 250px;
      }

      .input-link {
        margin: 0 20px;
        min-width: 250px;
      }
    }

    .filters-item {
      &-wrapper {
        min-width: 500px;
        padding: 10px 0;

        .autocomplete-select-control-wrapper {
          [class*="-control"] {
            box-shadow: none !important;
          }
        }

        .select-autocomplete-wrapper {
          padding-bottom: 0 !important;
        }
      }
    }

    .info-row {
      &-input {
        padding-right: 20px;
        padding-left: 45px;

        .MuiTextField-root {
          width: 250px;
        }
      }
    }

    .categories-info-message {
      font-size: 12px;
      font-weight: 300;
      font-style: italic;
      color: #677080;
    }
  }

  &-banner {
    &-empty {
      width: 100%;
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(0deg, rgba(110,110,110,0.2) 0%, rgba(233,233,233,1) 100%);
      font-size: 22px;
      color: #727891;
      transition: background-color 0.5s ease;
    }

    &-main {
      max-height: 300px;
      display: flex;
      width: 100%;
      justify-content: center;
      min-height: 250px;

      &-img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }

      &.banner {
        &:hover {
          .influencers-banner-empty {
            background: linear-gradient(0deg, rgba(0,0,0,0.2) 0%, rgba(233,233,233,1) 100%);
          }

          .banner-upload {
            opacity: 1;
          }
        }
        .banner-upload {
          opacity: 0;
          position: absolute;
          bottom: 0;
          transition: opacity 0.3s ease;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          margin: 0;
          height: 100px;
          
          svg {
            color: inherit;
            background-color: rgba(0,0,0,0.2);
            box-shadow: 0 0 12px 7px rgba(0,0,0,0.2);
          }
        }
      }

      &.deal {
        width: 50%;
        max-height: 200px;
        min-height: 200px;

        .influencers-banner-empty {
          height: 200px;
        }
      }
    }
  }

  &-confirm {
    margin: auto;
    margin-top: 20px !important;;
  }
}

.influencer {
  &-no-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    p,h2 {
      text-transform: initial;
    }

    .card {
      padding: 10px;
    }

    .card-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .ti-face-sad {
        padding-bottom: 10px;
      }
    }

    .card-actions {
      display: flex;
      justify-content: center;

      .MuiButtonBase-root {
        text-transform: initial;
      }
    }
  }
}

.link-with-label {
  padding-bottom: 5px;
  position: relative;

  &:hover {
    &:before {
      border-bottom: 2px solid rgba(0, 0, 0, 0.87);
    }
  }

  &:before {
    left: 0;
    right: 0;
    top: 24px;
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    pointer-events: none;
  }

  .MuiTypography-caption {
    color: #6c757d;
    margin-bottom: 6px;
  }
}