/*======= All App Files Include Here ======*/

//Default Bootstrap File
@import "./bootstrap";

//Slick Slider
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/*==== Core Mixins ====*/
@import "./custom/mixins/mixin";

/*==== Core Utilities =====*/
@import "./custom/utilities/utilities";

// Custom Variables Files
@import "./custom";

// Custom Layout Files
@import "./custom/layouts/layout";

// Custom themes Files
@import "./custom/themes/themes";
