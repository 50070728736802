.preview {
  &-sidebar {
    min-height: 100%;
    
    .facet {
      &-wrapper:not(:first-child) {
        border-top: 1px dashed #dadada;
        padding: 10px 0 15px;
      }

      &-title {
        font-size: 12px;
        margin-bottom: 0px;
        margin-top: 0;
        letter-spacing: 1px;
        font-weight: 500;
        color: #ffffff !important;
        text-transform: uppercase;
        padding: 5px 30px;
        background-color: #9297a7;
      }
    }

    .quick-filter {
      margin-left: 5px;
      margin-right: 5px;
    }

    .combined-facet {
      &-group {
        margin-left: 15px;
        
        &:not(:first-of-type) {
          border-top: 1px dashed #dadada;
        }

        &-title {
          span {
            font-weight: 600;
            font-size: 13px;
            margin: 0;
          }
        }

        &-list {
          display: flex;
          flex-wrap: wrap;
          margin-left: -5px;
          margin-right: -5px;
        }

        &-item {
          width: 70px;
          height: 50px;
          border: 1px solid #dadada;
          margin: 5px;
          position: relative;
          padding: 6px;

          &-label {
            text-transform: lowercase;
            &:first-letter {
              text-transform: capitalize;
            }
          }

          &.checked {
            border-color: #000;
            border-width: 2px;
          }

          .MuiListItemText-root {
            text-align: center;

            span {
              margin: 0;
            }
          }
          
          .MuiListItemIcon-root {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .MuiButtonBase-root {
              visibility: hidden;
            }
          }
        }
      }
    }

    .default-facet {
      &-group {
        .slider-wrapper {
          padding: 20px 16px 35px 16px;
          margin: 0;
        }
      }
    }
  }
}

.product-list {
  .product-list-table-image-view & {
    padding-left: 65px;
  }
}

.product-item {
  & + .product-list {
    .product-item {      
      .preview & {
        padding-left: 0px !important;
        overflow-x: hidden;
      }
    }
  }
}

.system-attributes {
  z-index: 5;

  .MuiFormControlLabel-root {
    display: inline-flex;
  }
}

.label {
  &-more,
  &-less {
    letter-spacing: 1px;
    color: #5D92F4 !important;
  }
}