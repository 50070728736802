.content {
  &-search {
    justify-content: space-between;
  }
}

.context {
  &-menu {
    &-wrapper {
      font-size: 14px;

      min-width: 180px;
      min-height: 40px;
      
      background: #fff;
      box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
      
      z-index: 2;

      .MuiTypography-body1 {
        font-size: 12px;
      }

      .MuiListItemIcon-root {
        margin-right: 0;
      }

      .MuiListItem-button {
        padding: 10px !important;
      }
    }
  }
}

.MuiListItemIcon-root {
  .list-item-secondary & {
    min-width: 20px;
  }

  .context-backdrop & {
    min-width: 25px;
  }
}
