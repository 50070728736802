.search-header-input {
  width: 100%;
}

.container {
  &-search {
    .form-errors {
      position: absolute;
    }

    .btn-group-gray {
      margin-bottom: 10px;
    }
  }
}

.suggestions {
  &-wrapper {
    position: absolute;
    width: 100%;
    background: #ffffff;
    margin-top: 10px;

    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #464D69;
  }
}

.search {
  &-wrapper {
    position: relative;

    .MuiFormHelperText-root {
      position: absolute;
      bottom: -14px;

      .product-dialog & {
        position: relative;
        bottom: 0;

        font-size: 10px;
      }

      .filter-by-section & {
        left: 25px;
        bottom: -16px;
      }
    }
  }

  &-prefix {
    border: 1px solid #c4c4c4;
    background-color: #ffffff;
    padding: 6px;
    border-radius: 5px 0px 0px 5px;
    margin-right: -4px;
    z-index: 1;

    height: 37px;
  }
}

.filter-by-section {
  .last-group {
    .MuiFormHelperText-root {
      position: absolute;
      left: 40px;
      bottom: -11px;
      margin: 0;

      &.autocomplete-helper-text {
        position: relative;
        bottom: 0;
      }
    }
  }
}