.MuiExpansionPanelSummary {
  &-root {
    .expansion-secondary & {
      min-height: 10px;
      padding: 0;
    }

    &.Mui-expanded {
      position: relative;

      .expansion-secondary & {
          min-height: 10px;
      }

      &:after {
        .expansion-secondary & {
          content: '';
          pointer-events: none;
          height: 10px;
          width: 100%;
          position: absolute;
          left: 0;
          right: 0;
          z-index: 9;

          .sidebar-preview {
            content: none;
          }
        }
      }

      &:after {
        .expansion-secondary & {
          bottom: -10px;
          /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f9f9f9+0,f9f9f9+100&1+0,0+100 */
          background: -moz-linear-gradient(top,  rgba(249,249,249,1) 0%, rgba(249,249,249,0) 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top,  rgba(249,249,249,1) 0%,rgba(249,249,249,0) 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom,  rgba(249,249,249,1) 0%,rgba(249,249,249,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9', endColorstr='#00f9f9f9',GradientType=0 ); /* IE6-9 */
        }
      }
    }

    .MuiIconButton-edgeEnd {
      .expansion-secondary & {
        padding: 5px;
        margin-right: 0;

        opacity: 0.4;
      }
    }
  }

  &-content {
    .expansion-secondary & {
      margin: 0 !important;
    }


    .sidebar-title {
      margin: 0;
      background-color: transparent;
    }
  }
}

.MuiExpansionPanel {
  &-root {
    .expansion-secondary & {
      box-shadow: 0 0 0 0 transparent;
      background-color: transparent;
      margin-bottom: 0;
    }

    &:hover {
      .MuiExpansionPanelSummary-root {
        .expansion-secondary & {
          background-color: rgba(231, 231, 231, 0.7);
        }
      }
    }

    &:before {
      .expansion-secondary & {
        display: block !important;
      }
    }

    &:first-child {
      &:before {
        .expansion-secondary & {
          display: none !important;
        }
      }
    }

    &.Mui-expanded {
      .expansion-secondary & {
        margin-top: 0;
        margin-bottom: 0;
      }

      &:before {
        .expansion-secondary & {
          opacity: 1;
        }
      }

      .MuiExpansionPanelSummary-root {
        .expansion-secondary & {
          background-color: rgba(231, 231, 231, 0);
        }

        .sidebar-title {
          span {
            .expansion-secondary & {
              color: $primary !important;
            }
          }
        }
      }
    }
  }

}

.MuiExpansionPanelDetails {
  &-root {
    .expansion-secondary & {
      padding: 0;
    }

    .tree-wrapper.enable-list {
      .expansion-secondary & {
        width: 100%;        
      }
    }

    .list {
      padding-top: 0;
      padding-bottom: 0;

      &-wrapper {
        width: 100%;
      }
    }
  }
}

.list-inline-item {
  .rct-sidebar-wrap & {
    width: 100%;
  }
}

.MuiCollapse-wrapper {
  .rct-scroll-expansion & {
    max-height: calc(100vh - 230px);
    overflow: hidden;
    overflow-y: auto;
  }

  .rct-scroll-expansion.search-expansion & {
    max-height: calc(100vh - 250px);
  }

  .expansion-scoll-lg & {
    max-height: calc(100vh - 200px);
    overflow: hidden;
    overflow-y: auto;

    .sidebar-inner-wrapper-sm & {
      max-height: calc(100vh - 230px);
    }

    .sidebar-inner-wrapper-md & {
      max-height: calc(100vh - 280px);
    }
  }
}

.rct-sidebar-wrap {
  .rct-scroll {
    &.rct-scroll-expansion {
      &.search-expansion {
        &:after {
          bottom: 10px;
        }
      }
    }
  }
}
