.facet {
  &-wrapper-secondary {
    padding: 10px;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
  }

  &-wrapper {
    &:empty {
      display: none;
    }
  }

  &-actions {
    padding-top: 1.25rem;
  }

  &-options {
    &-list {
      .label {
        &-less,
        &-more {
          color: $default !important;
          font-size: 11px;
          font-weight: $semi;
  
          letter-spacing: 0;
          text-decoration: underline;
  
          &:hover {
            text-decoration: none;
          }
        }
      }
    
      &.MuiList-root {
        padding: 0px 0 8px 10px;
      }

      .MuiListItemIcon-root {
        min-width: 20px;

        &:empty {
          display: none;
        }
      }

      .MuiButtonBase-root {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  &-title {
    font-size: 12px;

    margin: 5px 0px 10px;
  }

  &-content {
    &-wrapper {
      display: flex;
      flex-direction: column;

      padding-bottom: 40px;

      width: 100%;
    }
  }

  &-group {
    position: relative;
    z-index: 1;

    &:active,
    &:focus-within {
      z-index: 2;
    }

    &-dnd {
      &-wrapper {
        padding: 0 20px;
      }
    }

    &-sm {
      & + .facet-group-sm {
        margin-top: 10px !important;
      }
    }
  
    &-actions {
      position: absolute;

      top: 5px;
      right: 5px;

      z-index: 2;
    }

    &-secondary {
      position: relative;

      padding: 12px 0;
      margin: 0;

      border-radius: 5px;
      background-color: #f5f5f5;

      .facet-group-inline,
      &.facet-group-column {
        margin: 0;
      }
    }
  
    &-inline {
      display: flex;

      margin: 0 -10px;
    }
    
    &-column {
      display: flex;
      flex-direction: column;

      margin: 0 -10px;
    }
    
    &-inner {
      padding: 0 10px !important;
      
      width: 100%;

      &-xs {
        width: 100%;
        max-width: 100px;
      }

      &-sm {
        width: 100%;
        max-width: 200px;
      }
      
      & > * {
        width: 100%;
      }
    }

    &-trim {
      width: auto;
    }

    &-sub-head {
      display: flex;
      align-items: center;
    }

    &-controls {
      width: auto;
      padding-top: 20px;
      min-width: 100px;
    }

    & + .facet-group {
      margin-top: 25px;
    }
  }

  &-sortable-item {
    padding: 0 !important;
    border-top: 0 !important;
    margin-top: 10px;

    > i {
      z-index: 3;
      top: 5px;
    }

    > div {
      width: 100%;
      padding-left: 40px;
    }
  }
}