.MuiToggleButton-root {
  &.Mui-selected {
    color: $default;

    &:hover {
      .toogle-primary & {
        will-change: background-color;
  
        background-color: rgba($primary, 0.8);
      }
    }

    .toogle-primary & {
      color: #fff;

      background-color: $primary;

      transition: background-color 0.2s ease;
    }
  }
}

.toogle-paper-wrapper {
  position: relative;
}