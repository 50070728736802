// Base class
.tooltip {
  position: absolute;
  z-index: $zindex-tooltip;
  display: block;
  margin: $tooltip-margin;
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  @include reset-text();
  font-size: $tooltip-font-size;
  // Allow breaking very long words so they don't overflow the tooltip's bounds
  word-wrap: break-word;
  opacity: 0;

  &-inner-container {
    position: absolute;
    bottom: 0;
    padding: 1px 10px 6px 10px;
    border-radius: 3px;
    margin-bottom: 20px;
    left: 0px;
    opacity: 0;
    transition: all 0.2s ease;

    &:after {
      content: '';
      position: absolute;
      left: 5px;
      bottom: -5px;
      border-width: 10px 10px 0px 10px;
      border-style: solid;
      border-color: #fee8e7 transparent transparent transparent;
    }

    .MuiDialogActions-action:hover & {
      opacity: 1;
      bottom: 100%;
    }
    
    &.danger {
      background-color: #ffe8e8;
    }
  }

  &.show { opacity: $tooltip-opacity; }

  .arrow {
    position: absolute;
    display: block;
    width: $tooltip-arrow-width;
    height: $tooltip-arrow-height;

    &::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }

  &-scroll {
    max-height: 260px;
    overflow-y: auto;
  }
}

.bs-tooltip-top {
  padding: $tooltip-arrow-height 0;

  .arrow {
    bottom: 0;

    &::before {
      top: 0;
      border-width: $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
      border-top-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-right {
  padding: 0 $tooltip-arrow-height;

  .arrow {
    left: 0;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      right: 0;
      border-width: ($tooltip-arrow-width / 2) $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
      border-right-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-bottom {
  padding: $tooltip-arrow-height 0;

  .arrow {
    top: 0;

    &::before {
      bottom: 0;
      border-width: 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
      border-bottom-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-left {
  padding: 0 $tooltip-arrow-height;

  .arrow {
    right: 0;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      left: 0;
      border-width: ($tooltip-arrow-width / 2) 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
      border-left-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-auto {
  &[x-placement^="top"] {
    @extend .bs-tooltip-top;
  }
  &[x-placement^="right"] {
    @extend .bs-tooltip-right;
  }
  &[x-placement^="bottom"] {
    @extend .bs-tooltip-bottom;
  }
  &[x-placement^="left"] {
    @extend .bs-tooltip-left;
  }
}

// Wrapper for the tooltip content
.tooltip-inner {
  max-width: $tooltip-max-width;
  padding: $tooltip-padding-y $tooltip-padding-x;
  color: $tooltip-color;
  text-align: center;
  background-color: $tooltip-bg;
  @include border-radius($tooltip-border-radius);
}


.rc-slider-tooltip {
  &-placement-top {
    z-index: 11;
  }
}

.title {
  &-tooltip {
    position: absolute;
    color: #fff;
    padding: 8px;
    font-size: 0.625rem;
    max-width: 450px;
    word-wrap: break-word;
    font-weight: 500;
    line-height: 1.4em;
    border-radius: 4px;
    background-color: #616161;

    bottom: 100%;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0;
    pointer-events: none;

    transition: opacity 0.2s ease;

    z-index: 20;

    .rc-tree-node-content-wrapper:hover & {
      opacity: 1;
      pointer-events: all;
    }

    &-scroll {
      white-space: normal;
      max-height: 100px;
      overflow-y: auto;
   }
  }
}