/*========= All form Elements Style =========*/
.input-group-prepend {
  @include border(1px solid, $input-border-color, top bottom left);
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  button {
    color: inherit;
  }
}
.input-lg {
  height: $input-lg-height;
  padding: 0.375rem 0.7rem; // 6px 30px;
}
.input-lg-icon {
  height: $input-lg-height;
  padding: 0.375rem 2.7rem; // 6px 30px;
}
.app-selectbox {
  border: 1px solid $block-border;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  height: $input-lg-height;
  box-shadow: $block-shadow;
  &:before {
    content: "";
    position: absolute;
    pointer-events: none;
    top: 50%;
    margin-top: $caret-width * -0.3;
    right: 10px;
    width: 0;
    height: 0;
    border: $caret-width solid transparent;
    border-top-color: $gray-900;
  }
  select {
    padding: 0.3125rem 0.5rem; //5px 8px;
    box-shadow: none;
    background-color: $block-bg;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-transform: capitalize;
    height: $input-lg-height !important;
    &:focus {
      outline: none;
    }
  }
}
.app-selectbox-sm {
  @extend .app-selectbox;
  height: $input-height;
  select {
    height: $input-height !important;
  }
}

.text-white {
  span {
    color: #fff !important;
  }
}

.input-padding-clear {
  .select-suffix,
  & + .select-suffix {
    font-size: 12px;
    bottom: 8px;
    top: auto;
    left: 100%;
    right: auto;
    width: 12px;
    margin-left: 5px;
  }

  .select-suffix {
    margin-left: 0px;
  }
}

.MuiInputLabel {
  &-root {
    .input-padding-clear & {
      padding-right: 0;
    }
  }

  &-outlined {
    .input-sm & {
      transform: translate(14px, 11px) scale(1);
    }
  }
}

.block {
  width: 100% !important;
  max-width: none;

  &-60 {
    width: 60%;
  }

  &-40 {
    width: 40%;
  }
}

.d-block {
  display: block;
}

.MuiInputBase {
  &-root {
    .input-padding-clear & {
      padding: 0 !important;
    }
  }

  &-input {
    .text-cap & {
      text-transform: capitalize;
    }
  }
}

.form {
  &-group {
    .items-baseline & {
      align-items: baseline;
    }

    .form-group-inline & {
      margin-bottom: 0;

      margin-left: 5px;
      margin-right: 5px;

      align-items: stretch;
      justify-content: flex-end;
      display: flex;
      flex-direction: column;
    }

    &-inline {
      margin-left: -5px;
      margin-right: -5px;
    }
  }

  &-control {
    .preview-drawer & {
      background-color: transparent;
    }

    &-clean {
      margin: 0;

      &.MuiFormControl-root {
        margin: 0;

        .MuiOutlinedInput-notchedOutline {
          border-color: transparent;
        }

        .MuiInputBase-input {
          padding: 5px;
        }
      }
    }
  }
}

.MuiOutlinedInput {
  &-notchedOutline {
    .dialog-footer & {
      border-color: transparent !important;

    }
  }
  &-input {
    .input-sm & {
      padding: 10px 14px;
    }
  }
}