.text-input-lg {
  .actions-bar & {
    min-height: 30px;
    width: auto;
  }
  
  .MuiInputLabel-root {
    .actions-bar & {
      margin-left: 0;
    }
  }

  &.select-wrapper-suffix {
    .actions-bar & {
      margin-right: 0;

      min-height: 30px;
    }
  }
}
