.content-direction-switcher {
  &-icon {
    &-wrapper {
      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: center;

      font-size: 15px;

      .direction-arrow {
        margin: -5px 0px;
        font-size: 18px;
        
        transform: scale(1);
        transition: transform 0.2s ease;
        
        &.rtl {
          transform: scale(-1);
        }
      }
    }
  }
}

.paragraph-active {
  color: $primary;
}