.list {
  margin-bottom: 0;
  list-style: none;

  &-item-message {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(#fff, $alpha: 0.7);
    z-index: 1;
    .list-item-disabled & {
      background-color: rgba(#fff, $alpha: 0);
    }

    span {
      background-color: #fff;
      padding: 10px 20px;
      border-radius: 3px;
    }
    &-danger {
      
      span {
        color: white;
        background-color: #fcb6b9;

        .list-item-disabled & {
          background-color: red;
        }
      }
    }
  }
  &-left {
    max-width: 50%;

    @media (min-width: 1400px) {
      max-width: 60%;
    }

    .MuiListItemIcon-root {
      .checkbox-sm {
        max-width: 26px;
      }
    }
  }

  &-horizontal {
    display: flex;

    margin: 0 -10px;
  }

  &-checkbox {
    padding: 12px !important;

    &-title {
      padding-left: 0 !important;

      .sidebar-scroll-secondary & {
        margin: 0;
      }

      & > * {
        .sidebar-scroll-secondary & {
          margin: 0;
  
          font-size: $fz-13;
        }
      }
    }

    &-wrapper {
      padding: 0 !important;
    }

    &-item {
      .list-checkbox-sm & {
        padding: 0;
      }

      .sidebar-category-mer & {
        
      }

      .sidebar-scroll-secondary & {
        padding: 4px 0px 4px 5px !important;
      }
    }
  }

  &-wrapper {
    .rct-subheader & {
      width: 100%;
    }

    .MuiButtonBase-root {
      .sidebar-scroll-secondary.sidebar-ranking-mer & {
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 5px;
      }
    }

    .MuiListItemText-root {
      .sidebar-scroll-secondary & {
        margin: 0;
      }
    }

    .MuiTypography-root {
      .sidebar-scroll-secondary & {
        padding: 0px;
        font-size: 12px;
        line-height: 1;
      }
    }
  }

  &-secondary {
    box-shadow: 0 0 0 1px #f5f5f5 !important;
    border-radius: 0 !important;
  }

  &-shadow {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      height: 16px;

      left: 0;
      right: 15px;

      opacity: 0.1;

      pointer-events: none;
    }

    &.bottom {
      &:after {
        bottom: 0;

        background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */

      }
    }
  }

  &-item {
    &-productId,
    &-price {
      .list-right & {
        .select-products & {
          display: none;
        }
      }
    }
  
    &-sku,
    &-minPrice,
    &-pubAopi,
    &-productId,
    &-id,
    &-price {
      .flex-grid-calc-90 & {
        flex: 0 1 90px !important;
      }
      .flex-grid-calc-160 & {
        flex: 0 1 160px !important;
        padding-right: 0;
      }
    }

    &-title {
      display: block;
      margin-bottom: -2px;
    }

    &-subtitle {
      display: flex;

      &:after {
        padding-right: 3px;
        content: ',';
      }

      &:last-child {
        &:after {
          content: none !important;
        }
      }
      
      &-list {
        display: flex;
        flex-wrap: wrap;

        padding-left: 3px;

        &-label {
          white-space: nowrap;
        }
      }

      &-wrapper {
        font-size: 10px;
        display: flex;
        color: #afafaf;
        line-height: 1;
      }
    }

    &-badge {
      &-wrapper {
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &-disabled {
      opacity: 0.3;
      pointer-events: none;

      & * {
        pointer-events: none;
      }
    }

    &-wrapper {
      .rct-subheader & {
        width: 100%;
        max-width: 200px;
      }
    }

    &-link {
      color: $default;
      font-size: 12px;
      padding: 5px 10px;
      transition: all 0.2s ease;
      background-color: #fff;

      .list-item-link-active & {
        pointer-events: none;
        color: $primary;
      }

      @media (min-width: 1281px) {
        font-size: 14px;
      }

      @media (max-width: 1280px) {
        font-size: 12px;
      }

      .d-flex & {
        display: flex;
        align-items: center;

        height: 100%;
      }

      .list-sm & {
        padding: 15px;
      }

      .list-tab & {
        text-align: center;
        justify-content: center;
      }

      &-active {
        pointer-events: none;
        color: $primary;

        &:focus {
          pointer-events: none;
          color: $primary;
        }

        .list-tab & {
          background-color: #f3f3f3;
        }
      }

      &:hover {
        color: $primary;

        .list-tab & {
          color: $default;
          background-color: #f7f7f7;
        }
      }
    }

    &.dropdown {
      &:after {
        position: absolute;
        content: "\F2FB";
        font-family: Material-Design-Iconic-Font;
        right: 1.5rem;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        transition: all 0.2s ease-in-out;
        transform: rotate(0deg);
      }
    }

    &.item-active {
      &:after {
        transform: rotate(90deg);
      }
    }
  }

  &-inline {
    & + .list-wrapper {
      margin-left: 20px;
    }
  }
}

.MuiTypography-root {
  .list-item-title.crop-secondary &,
  .list-item-description.crop-secondary & {
    line-height: 1.3;
  }

  .transfer-list-card-header & {
    color: #9c9c9d;
  }

  &.MuiCardHeader-title {
    .transfer-list-card-header & {
      font-size: 15px;
      font-weight: 600;

      color: #464d69;
    }
  }
}

.MuiListItemText-root {
  .list-item-secondary & {
    padding-bottom: 6px !important;
  }
}

.MuiListItemText-secondary {
  .crop-secondary & {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 400px;
  }

  .list-item-title.crop-secondary &,
  .list-item-description.crop-secondary & {
      position: relative;

      color: rgba(72, 72, 74, 0.54);
      // font-size: 14px;

  
      &:after {
        .list-item-secondary & {
          content: '';
          position: absolute;
  
          right: 0;
          bottom: 0;
  
          height: 20px;
          width: 100px;
  
          background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.97) 70%, rgba(255,255,255,1) 72%); /* FF3.6-15 */
          background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.97) 70%,rgba(255,255,255,1) 72%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.97) 70%,rgba(255,255,255,1) 72%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
        }

        .list-item-secondary:hover & {
          background: -moz-linear-gradient(left,  rgba(247,249,250,0) 0%, rgba(247,249,250,0.97) 70%, rgba(247,249,250,1) 72%); /* FF3.6-15 */
          background: -webkit-linear-gradient(left,  rgba(247,249,250,0) 0%,rgba(247,249,250,0.97) 70%,rgba(247,249,250,1) 72%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to right,  rgba(247,249,250,0) 0%,rgba(247,249,250,0.97) 70%,rgba(247,249,250,1) 72%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f7f9fa', endColorstr='#f7f9fa',GradientType=1 ); /* IE6-9 */

        }
      }
  
      .MuiTypography-root {
        position: relative;
      }
  
      .list-item-secondary & {
        max-height: 35px;
       }
    }
}


.MuiTypography-colorTextSecondary {
  .list-item-secondary & {
    color: rgba(72, 72, 74, 0.54);
    font-size: 13.5px !important;
  }
}

[class^='ti-'],
[class*='ti-'],
[class^='icon-'],
[class*='icon-'] {
  .list-wrapper & {
    margin-right: 8px;
  }
}

.lists {
  &-group {
    padding: 0 24px;
  }
}

.language {
  &-icon {
    width: 4rem;
  
    img {
      .list-item-clear & {
        margin-right: 5px !important;
      }
    }
  
    .list-item-clear & {
      font-size: 12px;
  
      display: flex;
      align-items: center;
      justify-content: center;
  
      border: 0;
      box-shadow: 0 0 0 0 transparent;
    }
  
    &:hover {
      .list-item-clear & {
        background-color: #f7f7f7;
      }
    }
  }

  &-dropdown {
    .dropdown-menu {
      width: 290px;
    }
  }
}

.uppercase {
  text-transform: uppercase !important;
}

.MuiListItem-gutters {
  .transfer-list-container & {
    padding-left: 8px;
    padding-right: 8px;

    @media (max-width: 1280px) {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  .facet-options-list & {
    padding-left: 0;
    padding-right: 0;
  }

  .MuiListItemText-root {
    .sidebar-group & {
      padding-right: 0;
    }
  }

  .list-2 & {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .list-8 & {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.item-dotted {
  .MuiListItem-button {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.12);
  }
}

.MuiAvatar-root {
  .list-item-secondary & {
    width: 105px;
    height: 105px;

    box-shadow: 0 0 0 1px #b1b1b2;
    border-radius: 0;
  }

  & + .flex-wrap {
    width: 100%;

    .list-left & {
      max-width: 80%;
    }

    .list-right & {
      .select-products & {
        flex-direction: column;
      }
    }
  }
}

.facet-options-list {
  &.MuiList-root {
    padding-left: 16px;
  }
}