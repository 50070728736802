.date-picker {
  .MuiInputBase-root {
    pointer-events: none;
  }

  .MuiTouchRipple-root {
    opacity: 0;
  }

  .MuiInputAdornment-root {
    pointer-events: all;
  }

  &-mask {
    position: absolute;
    bottom: 0px;

    pointer-events: none;
    z-index: 1;
    
    .autocomplete-xs & {
      font-size: 11px;
      line-height: 1;
      padding: 1px 1px;
    }
    
    .pim-sidebar & {
      background-color: #f6f6f6;
    }
  }

  &-xs {
    .MuiInputAdornment-root {
      .MuiButtonBase-root {
        padding: 3px;

        width: 100%;
        left: 0;
        position: absolute;
        border-radius: 0;
        display: flex;
        justify-content: flex-end;

        background-color: transparent !important;

        .MuiSvgIcon-root {
          background-color: transparent;
          transition: background-color 0.2s ease;

          &:hover {
            border-radius: 2px;
            background-color: #e5e5e5;
          }
        }

        .MuiSvgIcon-root {
          font-size: 22px;
          padding: 2px;
        }
      }
    }
  }
}

.MuiInputAdornment-root {
  &:empty {
    display: none;
  }
}