.label {
  &-info {
    font-size: 12px;
    font-weight: 400;
    color: #5c92f4;
  }

  &-clear {
    margin: 0 10px 0 !important;
  }
}