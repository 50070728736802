/*======= All Common Style Mention Here ======*/
html {
  font-size: ($html-font-size - 1);
}
@media(max-width:1700px) {
  html {
    font-size: ($html-font-size - 1);
  }
}
@media(max-width:1366px) {
  html {
    font-size: ($html-font-size - 1);
  }
}
@media(max-width:1280px) {
  html {
    font-size: ($html-font-size - 1);
  }
}
@media(max-width:991px) {
  html {
    font-size: ($html-font-size - 2);
  }
}
body, html {
  height: 100%;
}
html{
  font-family: $font-family-base;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0,0,0,0)
}
body{
  font-family: $font-family-base;
  font-weight: $font-weight-normal;
  color:$body-color;
  background-color: #f8f9fa;
  position: relative;
  overflow:hidden;
  width: 100%;
}
[tabindex='-1']:focus {
  outline: none;
}
ul, ol {
  padding: 0;
}
select,
button,
textarea,
input {
  vertical-align: baseline;
}
html,
body {
  &[dir=rtl],
  &[dir=ltr] {
    unicode-bidi: embed
  }
}
bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}
bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: $fw-semi-bold;
}
p {
  font-size: 14px;
}
a {
  transition: all 0.5s ease 0s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
	&:hover, &:focus, &.active {
		color: inherit;
		text-decoration: none;
    outline: 0;
	}
}
*::-moz-selection {
  background-color: $primary;
  color: $light;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $input-placeholder-color !important;
}
* {
	outline: none;
  &:hover, &:focus, &.active {
    outline: none;
  }
}
.pull-left{
  float:left;
}
.pull-right{
  float:right;
}
sup {
  top: -0.9em;
}
disabled {
  cursor: none;
}
.vr-super {
  vertical-align: super;
}
.pos-static {
  position: static !important;
}
.overlay-wrap {
  position: relative;
}
.overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.overflow-hidden {
  overflow: hidden;
}
.border-rad-sm {
  border-radius: 5px;
}
.border-rad-md {
   border-radius: 8px;
}
address {
  span {
    display: block;
  }
}
.pos-center {
  &:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}
.center-holder {
  display: inline-block;
  height: auto;
  padding: 1.25rem;
  vertical-align: middle;
  width: 90%;
}
.strike p,.strike h5 {
  text-decoration: line-through;
}
.row.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
}
.switch {
  &.on {
    background-color: $success;
  }
}
.modal-open {
  overflow: visible !important;
}
.aqua-ripple {
  	>div {
		transition: all 0.2s ease-in-out;
      &:hover {
        background-color: $aqua !important;
      }
  	}
}
[class*="col-"] {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
}

/*======== Breadcrumb =========*/
.breadcrumb {
  background: none;
  .breadcrumb-item{
     span{
        text-transform: lowercase;
     }
  }
}
/*======== Loader Scss ========*/
.rct-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100% !important;
  .spring-spinner-rotator {
    border-top-color: $blue !important;
    border-right-color: $blue !important;
  }
}
.loader-overlay {
	position: absolute;
	background: rgba(255,255,255, 0.8);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0px;
	z-index: 99;
	align-items: center;
	overflow: hidden;
  margin: 0 auto;
}
.preloader-sidebar{
  background-color:rgba(0,0,0,0.2);
}
.preload-widget{
  background-color:$white;
  box-shadow:$block-shadow;
}
.preload-header{
  background-color:$white;
  height: 64px;
  padding-top: 14px;
  padding-bottom: 15px;
  box-shadow: 0px 1px 1px 0px #dcddde;
}
.fixed-plugin{
  .agency-bg{
    display:none;
  }
}

/*======== Responsive =========*/
@media (max-width: 1199px) and (min-width: 768px) {
	.d-sm-full {
		max-width: 100% !important;
		flex-basis: 100% !important;
	}
	.d-sm-half-block {
		max-width: 50% !important;
		flex-basis: 50% !important;
	}
}
@media (max-width: 991px) and (min-width: 576px) {
	.d-xs-full {
		max-width: 100% !important;
		flex-basis: 100% !important;
	}
	.d-xs-half-block {
		max-width: 50% !important;
		flex-basis: 50% !important;
	}
}
@media (max-width: 575px) and (min-width: 450px) {
  .d-xxs-full {
		max-width: 100% !important;
		flex-basis: 100% !important;
	}
	.d-xxs-half-block {
		max-width: 50% !important;
		flex-basis: 50% !important;
	}
}
@media (max-width: 450px) {
  .d-xs-none {
    display: none;
  }
}
@media (max-width: 575px) and (min-width: 320px) {
  .display-n {
    display: none !important;
  }
}

.custom-loader {
  animation-duration: 550ms !important;
  position: absolute;
  left: 11px;
  z-index: 1;

  .relative & {
    right: 0;
    left: auto;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .dialog-footer-actions & {
    width: 15px !important;
    height: 15px !important;
    left: -1px;
    bottom: 33px;

    .merchant-dialog & {
      left: 25px;
      bottom: 23px;
    }
  }

  &.influencer {
    position: relative;
  }
}

.loader {
  position: absolute;
  top: 40%;
  left: 45%;
  z-index: 3;
  animation-duration: 550ms;

  &-main {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-cover {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 11;

    &.body {
      position: fixed;
    }
  }

  &-abs {
    position: absolute;
    
    &.right {
      right: 0;
      background-color: #fff;
      padding: 0px 7px;

      &:before {
        content: '';
        position: absolute;

        width: 40px;
        height: 100%;

        right: 100%;

        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
        background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
      }
    }
  }


  &-subtitle,
  &-title {
    font-size: 12px;
    color: #848484;
    margin-top: 20px;
  }

  &-subtitle {
    margin-top: 0px;
  }

  &-inner {
    display: flex;
    flex-direction: column;
    align-items: center;

    .MuiCircularProgress-root {
      margin: 0 !important;
    }
  }

  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.3);
    z-index: 2;
    pointer-events: none;

    &-inverse {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      pointer-events: all;

      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.9);

      z-index: 8;

      &.select-loader {
        position: absolute !important;

        &.background-gray {
          .loader-inner {
            background-color: #eaeaea;
          }
        }
      }

    }
  }

  &.bottom {
    animation-duration: 550ms;
  }

  &.btn-group {
    top: auto;
  }

  &.category {
    left: 70px;
    top: 55%;

    &-facets {
      right: auto;
      top: auto;
      bottom: 28px;
      left: 140px;
    }
  }

  &.btn-group-loader {
    right: 20px;
    left: auto;
  }

  &.import-loader {
    position: inherit;
  }

  &.search-filter-loader {
    left: 100px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  &.attribute {
    left: -25px;
  }

  &.influencer-categories {
    top: auto;
    left: -25px;
  }

  &.influencer-filter-footer {
    left: -25px;
    top: auto;
    bottom: auto;
    margin: auto;
  }

  &.dialog-actions-loader {
    margin: 0 10px;
    height: 20px;
    width: 20px;
  }
}

.dialog-actions {
  background-color: #ffffff;
}

.product-dialog {
  & + #menu-attributeName {
    .MuiListItem-root {
      .loader {
        position: relative;
      }
    }
  }
}


.attr-loader {
  margin-right: 10px;
}

.item {
  &-loader {
    position: absolute !important;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100% !important;
    padding: 0 !important;
    opacity: 1 !important;
    pointer-events: none !important;
    background-color: transparent !important;
  }
}

.value-loader {
  background-color: transparent;
  justify-content: flex-end;
  padding: 0px 2px;
  border-radius: 20px;

  .form-group-filter & {
    padding-right: 5px;
  }

  .loader-inner {
    background-color: white;
    border-radius: 100%;

    .form-group-filter & {
      background-color: #f6f6f6;
    }

    .form-group-card-has-error & {
      background-color: #ffebeb;
    }
  }
}

.MuiButton-root {
  &.preview-import-btn {
    min-width: 35px;
  }
}

.category-controllers {
  border-bottom: 1px dashed #e2e2e2;
}

.with-errors {
  .form-control {
    border-color: $red;
  }
}