.button {
  &-default {
    padding: 6px 8px;
    
    &.MuiButton-root {
      padding: 6px 8px;
    }
  }

  &-success {
    color: $success;

    &:hover {
      background-color: rgba($success, 0.04);
    }
    
    &.MuiButton-root {
      color: $success;

      &:hover {
        background-color: rgba($success, 0.04);
      }
    }

    .MuiButton {
      &-label {
        color: $success;
      } 
    }
  }

  &-primary {
    color: #fff;
    background-color: $primary;
    
    &:hover {
      background-color: rgba($primary, 0.8);
    }
    
    &.MuiButton-root {
      color: #fff;
      background-color: $primary;

      &:hover {
        background-color: rgba($primary, 0.8);
      }
    }

    .MuiButton {
      &-label {
        color: #fff;
      } 
    }
  }

  &-danger {
    color: $danger;

    &:hover {
      background-color: rgba($danger, 0.04);
    }
    
    &.MuiButton-root {
      color: $danger;

      &:hover {
        background-color: rgba($danger, 0.04);
      }
    }

    .MuiButton {
      &-label {
        color: $danger;
      } 
    }
  }
}

.MuiButton {
  &-outlinedSecondary {
    &.MuiButton-outlined {
      color: $danger;

      border-color: $danger;

      will-change: color, border-color;

      &:hover {
        border-color: $danger;

        background-color: rgba($danger, 0.04);
      }
    }
  }
}