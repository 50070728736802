$gray: #d2d2d2;
$green: $success;

.mer-switch {
  margin-bottom: 0;
  cursor: pointer;

  input {
    display: none;
    & + span {
      width: 48px;
      height: 28px;
      border-radius: 14px;
      transition: all 0.2s ease;
      display: block;
      position: relative;
      background: #fff;
      box-shadow: inset 0px 0px 0px 2px $gray;

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        transition: all 0.2s ease;
      }
      &:before {
        top: 5px;
        left: 5px;
        width: 18px;
        height: 18px;
        border-radius: 9px;
        border: 5px solid $green;
      }
      &:after {
        top: 5px;
        left: 32px;
        width: 6px;
        height: 18px;
        border-radius: 40%;
        transform-origin: 50% 50%;
        background: $green;
        opacity: 0;
      }
      &:active {
        transform: scale(.92);
      }
    }
    &:checked {
      & + span {
        background: #ffffff;
        box-shadow: inset 0px 0px 0px 2px $green;

        &:before {
          width: 0px;
          border-radius: 3px;
          margin-left: 27px;
          border-width: 3px;
          background: $green;

          .sm & {
            width: 6px;
            height: 6px;
            margin-left: 20px;
          }
        }
        &:after {
          animation: blobChecked 0.25s linear forwards .2s;
        }
      }
    }
    &:not(:checked) {
      & + span {
        &:before {
          border-color: $gray;
          animation: blob 0.65s linear forwards .2s;
        }
      }
    }
  }
}

@keyframes blob {
    0%, 100% {
        transform: scale(1);
    }
    30% {
        transform: scale(1.12, .94);
    }
    60% {
        transform: scale(.96, 1.06);
    }
}

@keyframes blobChecked {
    0% {
        opacity: 1;
        transform: scaleX(1);
    }
    30% {
        transform: scaleX(1.44);
    }
    70% {
        transform: scaleX(1.18);
    }
    50%,
    99% {
        transform: scaleX(1);
        opacity: 1;
    }
    100% {
        transform: scaleX(1);
        opacity: 0;
    }
}

.mer-switch.sm input + span {
  width: 35px;
  height: 20px;
}

.mer-switch.sm input + span:after {
  display: none;
}

.mer-switch.sm input + span:before {
  width: 8px;
  height: 8px;
}

.mer-switch.sm input:checked + span:before {
  width: 10px !important;
  height: 10px !important;
  margin-left: 15px;
  border-width: 0px !important;
  top: 5px;
  border-radius: 100%;
}
