.default {
  position: absolute;

  left: 0;
}

.helper {
  &-text {
    &-top {
      @extend .default;

      top: -90%;
    }

    &-bottom {
      @extend .default;

      top: 90%;
    }
  }

  &.errors {
    display: flex;
    flex-direction: column;

    margin: 0 -3px;
    
    & > * {
      padding: 0 3px;
    }
  }
}