.rc-slider {
  .slider-progress & {
    height: 30px;
    padding: 0;
  }
  
  &-step,
  &-track,
  &-rail {
    .slider-progress & {
      height: 100%;
    }
  }

  &-mark {
    pointer-events: none;

    &-text {
      &:last-child {
        transform: translateX(-90%) !important;
      }
    }
  }
  
  &-handle {
    &:active {
      &:after,
      &:before {
        .slider-progress & {
          opacity: 0;
          transform: scale(0);
        }
      }
    }
    
    &:before {
      .slider-progress & {
        opacity: 1;
        content: attr(aria-valuenow);
        color: #777;
        font-weight: 600;
        position: absolute;
        top: -2px;
        left: -24px;
        font-size: 11px;
        transition: all 0.2s cubic-bezier(0.68, -0.55, 0.27, 1.55);
        transform: scale(1);
      }
    }

    &:after {
      .slider-progress & {
        content: '%';
        position: absolute;
        top: -2px;
        left: -10px;
        font-size: 11px;
        transition: all 0.2s cubic-bezier(0.68, -0.55, 0.27, 1.55);
        transform: scale(1);
      }
    }
  
    .slider-progress & {
      top: 0;
      bottom: 0;
      margin: auto auto auto -8px;
    }

    &[aria-valuenow="0"] {
      .slider-progress & {
        margin: auto auto auto -6px;
      }
    }

    &[aria-valuenow="14"],
    &[aria-valuenow="13"],
    &[aria-valuenow="12"],
    &[aria-valuenow="11"],
    &[aria-valuenow="10"],
    &[aria-valuenow="9"],
    &[aria-valuenow="8"],
    &[aria-valuenow="7"],
    &[aria-valuenow="6"],
    &[aria-valuenow="5"],
    &[aria-valuenow="4"],
    &[aria-valuenow="3"],
    &[aria-valuenow="2"],
    &[aria-valuenow="1"],
    &[aria-valuenow="0"] {
      &:before {
        .slider-progress & {
          content: attr(aria-valuenow);
          left: 15px;
        }
      }
      &:after {
        .slider-progress & {
          left: 30px;
        }
      }
    }

    &[aria-valuenow="100"] {
      &:before {
        .slider-progress & {
          content: attr(aria-valuenow);
          left: -30px;
        }
      }
    }
  }
}

.slider-range {
  padding: 0 7px;
}

.MuiGrid-container {
  &.horizontal-slider {
    flex-wrap: nowrap;
    overflow-x: auto;
    width: 100%;
  }
}
